import { SystemInfos } from '@/api/common/type'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import CheckPrompt from '@/utils/CheckPrompt'
import type { FormRuleItem } from '@/plugins/ant-design-vue/tsx-support'
type BaseValue = () => BaseResult
export const createRangeStr = (start: number, end: number) =>
  start === end ? start : `${start}-${end}`
export default class Rules {
  static create(ruleItem: FormRuleItem = {}): FormRuleItem {
    return {
      ...ruleItem
    }
  }
  /**
   * 检测必填项
   */
  static required(
    params: { requiredItem?: BaseValue; customPrompt?: BaseValue } = {},
    ruleItem: FormRuleItem = {}
  ): FormRuleItem {
    const { t } = useI18n()
    return Rules.create({
      message: () => {
        const { requiredItem, customPrompt } = params
        const val = requiredItem?.() ?? ''
        const result = `${val}${t(
          'lobby.common.formRules.requiredWithoutItem'
        )}`
        return (customPrompt || result) as string
      },
      ...ruleItem,
      required: true
    })
  }
  /**
   * 检测会员账号
   */
  static username(
    params: { start: number; end: number } = {
      start: 4,
      end: 16
    },
    ruleItem: FormRuleItem = {}
  ): FormRuleItem {
    const { start, end } = params
    const { t } = useI18n()
    return Rules.create({
      pattern: new RegExp(`^[A-Z0-9]{${start},${end}}$`, 'i'),
      message: () => {
        const result = t('lobby.common.formRules.username', {
          range: createRangeStr(start, end)
        })
        return result as string
      },
      ...ruleItem
    })
  }
  /**
   * 检测会员密码
   */
  static userpass(
    params: { start: number; end: number } = {
      start: 6,
      end: 16
    },
    ruleItem: FormRuleItem = {}
  ): FormRuleItem {
    const { start, end } = params
    const { t } = useI18n()
    const symbol = `!@#$%^&*_=\\+\\\\,\\-.\\/?\\(\\)`
    return Rules.create({
      pattern: new RegExp(
        `^(?!^(\\d+|[a-zA-Z]+|[${symbol}]+)$)^[\\w${symbol}]{${start},${end}}$`,
        'i'
      ),
      message: () => {
        const result = t('lobby.common.formRules.userpass', {
          range: createRangeStr(start, end)
        })
        return result as string
      },
      ...ruleItem
    })
  }

  /**
   * 动态检测会员密码
   */
  static dynamicUserpass(
    params: { start?: number; end?: number; isReadConfig?: boolean } = {
      start: 6,
      end: 16,
      isReadConfig: false
    },
    ruleItem: FormRuleItem = {}
  ): FormRuleItem {
    const { start } = params
    const {
      loginPasswdStrengthDetectSwitch,
      strongPasswdRequireUppercase,
      strongPasswdRequireLowercase,
      strongPasswdRequireNum,
      strongPasswdRequireSpecial,
      strongPasswdLength
    } = useMainStore()?.systemInfos as SystemInfos
    let pattern = null
    // 后台是否开启复杂校验 大小写字母 数字 字符 根据配置添加规则
    // 且是当前表单是否需要读取配置
    if (loginPasswdStrengthDetectSwitch && params.isReadConfig) {
      let regexString = ''
      if (strongPasswdRequireUppercase) {
        regexString += `^(?=.*[A-Z])`
      }
      if (strongPasswdRequireLowercase) {
        regexString += `(?=.*[a-z])`
      }
      if (strongPasswdRequireNum) {
        regexString += `(?=.*[0-9])`
      }
      if (strongPasswdRequireSpecial) {
        regexString += `(?=.*[!@#$%^&*_=+\\\\,\\-.\\/?\\(\\)])`
      }
      // 长度规则
      regexString += `.{${strongPasswdLength ?? 6},16}$`
      pattern = new RegExp(regexString)
    } else {
      return this.userpass(undefined, { required: true })
    }

    const { t } = useI18n()
    return Rules.create({
      pattern,
      message: () => {
        // 根据规则生成对应提示
        const result =
          loginPasswdStrengthDetectSwitch && params.isReadConfig
            ? CheckPrompt.getPrompt(
                strongPasswdLength,
                strongPasswdRequireLowercase,
                strongPasswdRequireUppercase,
                strongPasswdRequireNum,
                strongPasswdRequireSpecial
              )
            : t('lobby.common.formRules.userpass', {
                range: createRangeStr(start ?? 6, 16)
              })
        return result as string
      },
      ...ruleItem
    })
  }
  /**
   * 检测邮箱
   */
  static useremail(_params?: undefined, ruleItem: FormRuleItem = {}) {
    const { t } = useI18n()
    return Rules.create({
      pattern:
        /^(?=\w+([-+.'][-.\w+]*)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$).{0,50}$/,
      message: () => {
        const result = t('lobby.common.components.auth.email.formatError')
        return result as string
      },
      ...ruleItem
    })
  }

  static usercpf(
    params: { start: number; end: number } = {
      start: 1,
      end: 11
    },
    ruleItem: FormRuleItem = {}
  ) {
    const { start, end } = params

    const { t } = useI18n()
    return Rules.create({
      pattern: new RegExp(`^\\d{${start},${end}}$`, 'i'),
      message: () => {
        const result = t('lobby.common.formRules.usercpf')
        return result as string
      },
      ...ruleItem
    })
  }

  /**
   * 提现密码
   */
  static paypass(
    params: { length: number } = {
      length: 6
    },
    ruleItem: FormRuleItem = {}
  ) {
    const { t } = useI18n()
    const { length } = params
    return Rules.create({
      pattern: new RegExp(`^\\d{${length},${length}}$`, 'i'),
      message: () => {
        const result = t('lobby.common.formRules.paypass', {
          length
        })
        return result as string
      },
      ...ruleItem
    })
  }

  /**
   * 检测纯数字[金额/银行卡号]
   */
  static numberCheck(
    params: { type?: string; customPrompt?: string },
    ruleItem: FormRuleItem = {}
  ) {
    const { t } = useI18n()
    return Rules.create({
      pattern: /\d+\.*\d{0,2}/,
      message: () =>
        params.customPrompt ??
        t('lobby.common.formRules.numberRequired', {
          type: params.type
        }).toString(),
      ...ruleItem
    })
  }

  /**
   * 检测登录密码是否输入允许范围外的字符
   */
  static loginPwdSymbolCheck(ruleItem: FormRuleItem = {}) {
    const { t } = useI18n()
    return Rules.create({
      validator: (_rule, _value, callback) => {
        // 过滤可输入的大小写数字符号等
        const filteredValue = _value.replace(
          /[a-zA-Z0-9!@#$%^&*_=\+\\,\-.\/?\(\)]/g,
          ''
        )
        // 如果还有值就为不允许的符号
        if (filteredValue) {
          callback(t('lobby.common.formRules.userpassSpecial') as string)
          return false // 验证失败
        } else {
          return true
        }
        // 验证通过
      },
      ...ruleItem
    })
  }

  /**
   * 检测是否是支付宝账号（手机或邮箱）
   */
  static alipayRequired(ruleItem: FormRuleItem = {}) {
    const { t } = useI18n()
    return Rules.create({
      validator: (_rule, _value, callback) => {
        const phoneRegex = /^1\d{10}$/
        const emailRegex =
          /^[a-zA-Z\d._-]*@[a-zA-Z\d.-]{1,10}\.[a-zA-Z\d]{1,20}$/

        // 验证是手机号或者邮箱
        if (phoneRegex.test(_value) || emailRegex.test(_value)) {
          return true // 验证成功
        } else {
          callback(t('lobby.finance.withdraw.add.alipayNoRemark').toString())
          return false // 验证失败
        }
      },
      ...ruleItem
    })
  }
}
