import { BossDesignThemeType, NormalHoliday } from '@/store/index/type'
import { HolidayTheme } from '@/api/common/type'
import { ValueOf } from 'type-fest'
import { nodeThemeConfig } from '@/context/const'
import { useMainStore } from '@/store/index'

export const ThemeSkinType = nodeThemeConfig.ThemeSkinType

export type ThemeSkinType = ValueOf<typeof ThemeSkinType>

export default class Skin {
  // 基于欧美版的主题类型
  public static europeanAmericanSet: Set<ThemeSkinType> = new Set([
    ThemeSkinType.DQ,
    ThemeSkinType.EUROPEAN_AMERICAN,
    ThemeSkinType.THEME_27
  ])

  /**
   * 获取皮肤类型
   * @returns {ThemeSkinType}
   */
  public static get skinType(): ThemeSkinType {
    return (useMainStore().siteInfos?.type ??
      ThemeSkinType.NORMAL) as ThemeSkinType
  }

  /**
   * 获取皮肤名称
   */
  public static get skinTypeValue() {
    return useMainStore().siteInfos?.skinTypeValue
  }

  /**
   * 获取当前节日主题类型
   * @returns {Holiday}
   */
  public static get holidayThemeType(): number {
    const { userInfos, currentTryCurrency, hasLogined, siteInfos } =
      useMainStore()
    const currencyCode = hasLogined
      ? userInfos?.currency || ''
      : currentTryCurrency!.currencyCode
    const list = siteInfos?.holidayThemes || []
    return list.find((item: HolidayTheme) => {
      if (item.currencyCodes.includes(currencyCode) && item.status === 1) {
        return true
      }
    })?.themeType as number
  }

  /**
   * 普通版节日主题统一设计尺寸
   */
  public static get isNormalHolidayTheme(): boolean {
    return Object.values(NormalHoliday).includes(
      Number(this.holidayThemeType) as NormalHoliday
    )
  }

  /**
   * 5月份节日新版，需要兼容header通用版尺寸（老板设计）
   */
  public static get isBossDesignThemeType(): boolean {
    return (
      this.isHolidayThemeVisible &&
      Object.values(BossDesignThemeType).includes(
        Number(this.holidayThemeType) as BossDesignThemeType
      )
    )
  }

  /**
   * 是否显示节日主题(目前只有亚太、欧美、欧美简约需要展示节日)
   */
  public static get isHolidayThemeVisible(): boolean {
    const theme = this.holidayThemeType
    const { isGalaxyGold, isCrownGold, isCyanBlue, is305Site } =
      this.currentTheme()
    return (
      !isGalaxyGold &&
      !isCrownGold &&
      !isCyanBlue &&
      !is305Site &&
      // !isTheme20 &&   //六月又需要兼容961了，先把判断放开
      Boolean(theme)
    )
  }
  /**
   * 是否是节日主题通用版(目前只有亚太、欧美、欧美简约)
   * 是否是节日主题通用版(目前只有亚太、欧美、欧美简约,678通用版)
   */
  public static get isNormalThemeType(): boolean {
    const { isStake, isClassicStyle, isTheme28 } = this.currentTheme()
    return (
      isStake ||
      isClassicStyle ||
      isTheme28 ||
      this.skinType === ThemeSkinType.EUROPEAN_AMERICAN
    )
  }
  /**
   * 是否是通用版式(目前只有亚太、欧美、欧美简约)
   * 需要隐藏任务时，仅针对通用版隐藏tab栏任务入口
   */
  public static get isUniversalThemeType(): boolean {
    const { isStake, isClassicStyle } = this.currentTheme()
    return (
      isStake ||
      isClassicStyle ||
      this.skinType === ThemeSkinType.EUROPEAN_AMERICAN
    )
  }
  /**
  /*
   * 判断当前是有底还是无底
   */
  public static get isNoBg(): boolean {
    const { siteInfos } = useMainStore()
    return siteInfos?.backgroundColor === nodeThemeConfig.ThemeSkinBg.NO_BG
  }

  /**
   * 获取当前所有主题定义
   */
  public static currentTheme(): Record<string, boolean> {
    const type = this.skinType
    const skinTypeValue = this.skinTypeValue
    return {
      /**
       * 经典版（使用/** 注释有类型提示）
       */
      isClassicStyle: type === ThemeSkinType.NORMAL,
      /**
       * DQ定制版
       */
      isDQ: type === ThemeSkinType.DQ,
      /**
       * 欧美版
       */
      isEuropeanAmerican: this.europeanAmericanSet.has(type),
      /**
       * 欧美简约(Stake)
       */
      isStake: type === ThemeSkinType.STAKE,
      /**
       * 青蓝版（789定制）
       */
      isCyanBlue: type === ThemeSkinType.CYAN_BLUE,
      /*
       * 305青蓝版定制
       */
      is305Site: type === ThemeSkinType.CYAN_BLUE && skinTypeValue === 2,
      /**
       * 126定制版(银河金galaxy_gold)
       */
      isGalaxyGold: type === ThemeSkinType.GALAXY_GOLD,
      /**
       * 128定制版(皇冠金crown_gold)
       */
      isCrownGold: type === ThemeSkinType.CROWN_GOLD,
      /**
       * HK定制版
       */
      isHK: type === ThemeSkinType.HK,
      /** 296定制版 */
      isTheme12: type === ThemeSkinType.THEME_12,

      /*
       * 5159定制版(首页方形卡片和竖版卡片混合，其他游戏卡片是竖版卡片)
       */
      is5159: type === ThemeSkinType.THEME_17,
      /*
       * 230定制版
       */
      is230: type === ThemeSkinType.THEME_13,
      /** 230-2定制版 */
      isTheme14: type === ThemeSkinType.THEME_14,
      /** 230-3定制版 */
      isTheme35: type === ThemeSkinType.THEME_35,
      /** 5233定制版 */
      isTheme15: type === ThemeSkinType.THEME_15,
      /** 5021定制版 */
      isTheme16: type === ThemeSkinType.THEME_16,
      /** 5021-2定制版 */
      isTheme26: type === ThemeSkinType.THEME_26,
      /** 500定制版 */
      isTheme27: type === ThemeSkinType.THEME_27,
      /** 5371定制版 */
      isTheme21: type === ThemeSkinType.THEME_21,
      /**961定制版 */
      isTheme20: type === ThemeSkinType.THEME_20,
      /** 5198定制版2 */
      isTheme18: type === ThemeSkinType.THEME_18,
      /** 5613 定制版 */
      isTheme19: type === ThemeSkinType.THEME_19,
      /** 969定制版 */
      isTheme24: type === ThemeSkinType.THEME_24,
      /** 5955定制版 */
      isTheme34: type === ThemeSkinType.THEME_34,
      /**  5060定制版 */
      isTheme25: type === ThemeSkinType.THEME_25,
      /** 5235定制版 */
      isTheme33: type === ThemeSkinType.THEME_33,
      /** 10010定制版 */
      isTheme31: type === ThemeSkinType.THEME_31,
      /** 678定制版 */
      isTheme28: type === ThemeSkinType.THEME_28,
      /** 230-4定制版 */
      isTheme36: type === ThemeSkinType.THEME_36,
      /** 6008定制版 */
      isTheme39: type === ThemeSkinType.THEME_39,
      /** 10010定制版 */
      isTheme41: type === ThemeSkinType.THEME_41,
      /** 6261定制版 */
      isTheme37: type === ThemeSkinType.THEME_37,
      /** 678体育定制版 */
      isTheme29: type === ThemeSkinType.THEME_29
    }
  }
}
