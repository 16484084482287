/**
 * axios 扩展类型
 */
declare module 'axios' {
  export interface AxiosRequestConfig {
    _startTime?: number
    _forceRetry?: boolean
    _withStaticMode?: boolean
    _cryptoKey?: string
    _retryCount?: number
    _lastAxiosResponse?: AxiosResponse<Response> | AxiosError
    _origin_?: AxiosRequestConfig
    customParams?: {
      /**
       * 强制使用静态token的加解密方式
       */
      forceStaticAesKey?: boolean
      /**
       * 是否直接使用缓存的token
       */
      tryCacheToken?: boolean
      /**
       * oss 请求是否开启协商缓存模式
       */
      ossNegotiatedCaching?: boolean
      /**
       * 强制所有流程都走静态化模式
       */
      onlyStaticMode?: boolean
      /** 控制请求频率(节流延迟时间) */
      throttleDelay?: number
      /** 请求json时默认需要排除的key */
      staticKeyOmit?: string[]
      /** 控制正常请求需要排除的key,如果传true就同步staticKeyOmit的值 */
      keyOmit?: string[] | boolean
      /** 控制没有参数时是否拼接default结尾 */
      appendDefault?: boolean
      /** 是否尝试使用静态json请求模式 */
      tryStaticMode?: boolean
      /** 控制当次请求是否合并*/
      noMerge?: boolean
      /** 控制是否缓存，传数字控制请求的缓存时间 */
      cache?: number | boolean
      /** 控制能用缓存的情况下，当次请求是否使用缓存，默认为true ，如果为false，则当次不使用缓存，根据cache属性控制是否存入缓存**/
      useCache?: boolean
      /** 控制缓存依赖 */
      cacheDep?:
        | Array<'uid' | 'language' | 'currency'>
        | ((id: string) => string)
      /** 控制出错时是否缓存，默认非200时尝试取缓存 */
      useCacheInError?: (
        res: AxiosResponse<Response> | AxiosError,
        errorType: false | 'axiosError' | 'dataError'
      ) => boolean
      /** 控制下次刷新时是否将缓存设过期 */
      cacheExpiredOnReload?: (() => boolean) | boolean

      /** 是否自动给get请求添加公共的query参数 */
      autoQueryParams?: boolean
      /** 是否自动切换baseUrl */
      autoBaseUrl?: boolean
      /** 控制最大的重试次数，传0就不会重试 */
      maxRetryCount?: number
      uuid?: string
      /** 无响应消息code检查 */
      noResponseDataCodeChecked?: boolean
      /** 无日志 */
      noLog?: boolean
      /** 不响应公共层统一错误处理，直接抛给业务 */
      silentOnError?: boolean
      /** 无错误弹窗，响应公共层的错误处理，只是不弹错误消息 */
      noErrorMessage?: boolean
      /** 返回true时代表需要过滤公共层的错误提示 */
      errorCatchFilter?: (res: AxiosResponse<Response> | AxiosError) => boolean
      /** 不加密 */
      noEncrypt?: boolean
      /** 是否自动处理表单 */
      autoFormData?: boolean
      errorFilter?: (response?: Response) => boolean
      setDefaultRequestConfig?: (
        config: Omit<AxiosRequestConfig, 'customParams'>
      ) => Omit<AxiosRequestConfig, 'customParams'>
      /**
       * 控制是否用App的一些内置header数据
       * platformType: 6
       */
      useAppEnv?: boolean
      tipsWord?: Partial<typeof LobbyService['TIPS_WORD']>
    }
  }
}

import { cloneDeep, get, merge, omit, pick } from 'lodash'
import { v4 } from 'uuid'
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosResponseHeaders
} from 'axios'
import jsonBigint from 'json-bigint'

import { Net } from '@/core/network/net'

import GlobalCrypto from './crypto'
import GlobalEvents from '@/context/events'
import moment from 'moment'

import { GlobalManager } from '.'
// import Site from '@/controller/Site'

import { GeeTest } from '@/controller/GeeTest'
import { LobbyCache } from './netcache'
import { OsType, UserInfos } from '@/api/common/type'
import { getCurrentDevice } from '@/utils/Device'
import { isUrl } from '@/utils/Tool'
import { isWgPackage, windowConfig } from '@/utils/window'
import { traceLog } from '@/context/log'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import { useNetWorkStore } from '@/store/network'
import { useTokenStore } from '@/store/token'
import CryptoJS from 'crypto-js'
import DateUtils from '@/utils/DateUtils'
import GlobalConst, { defaultUrlQueryParams } from '@/context/const'
import Logger from '@/utils/Logger'
import siteConfig from '@/config/site.config'
import type { MonitorEventType } from '@/controller/MonitoringAnalysis'

const jsonBigintInstance = jsonBigint({ storeAsString: true })

export type Response<T = unknown> = {
  time?: number
  msg: string
  code: number
  errorCode?: number
  data?: T
}

export type ResponseInstance<R = Response> = Promise<AxiosResponse<R>>

/**
 * 依赖token的请求的拦截管理
 */
const tokenDependManage: Record<TokenMode, AxiosRequestConfig[]> = {
  static: [],
  logined: []
}

const throttleDelayManage: Record<string, AxiosRequestConfig[]> = {}

type TokenMode = 'static' | 'logined'

type LoginState = 'wait' | 'fakeLogined' | 'logined'

export function createErrorMessage(content: string) {
  GlobalEvents.dispatch({
    type: 'RESPONSE_ERROR',
    payload: { message: content }
  })
}

export class LobbyService extends Net {
  //连续错误的次数
  protected requestErrorCountConsequent = 0
  protected maxGoGateWayBack2SourceErrorCount = 1
  protected domainRequestRecord: Record<
    'api' | 'oss',
    Record<
      string,
      {
        successCount: number
        failCount: number
        lastSuccessTime: number
        lastFailTime: number
      }
    >
  > = {
    api: {},
    oss: {}
  }

  /**
   * 是否为api网关的请求
   */
  private isApi2Source(config: AxiosRequestConfig) {
    const api_domain = this.apiDomain
    return [...api_domain]
      .map((it) => `${it}${it.endsWith('/') ? '' : '/'}hall`)
      .some((baseURL) => config.baseURL?.startsWith(baseURL))
  }

  protected afterResponse(res: AxiosResponse): void {
    this.afterSuccess(res)
  }

  private afterSuccess(res: AxiosResponse) {
    const config = res.config
    if (this.isApi2Source(config)) {
      this.requestErrorCountConsequent = 0
      this.updatedomainRequestRecord(config, 'success', 'api')
      this.updateToken(res.headers)
    } else {
      if (config._withStaticMode) {
        this.updatedomainRequestRecord(config, 'success', 'oss')
      }
    }
  }

  private afterFail(config: AxiosRequestConfig) {
    if (this.isApi2Source(config)) {
      this.updatedomainRequestRecord(config, 'fail', 'api')
    } else {
      if (config._withStaticMode) {
        this.updatedomainRequestRecord(config, 'fail', 'oss')
      }
    }
  }

  public pickTokenInfos(infos: { token: string; newjwt: string }) {
    const { token, newjwt } = infos || {}
    if (token || newjwt) {
      const oldTokenInfos = useTokenStore().tokenInfos
      /**
       * 最新的token信息以内存中的值为准，传哪个字段就更新哪个字段
       */
      const tokenInfos = Object.assign({}, oldTokenInfos || {}, {
        ...(token ? { session_key: token } : {}),
        ...(newjwt ? { jwt_token: newjwt } : {})
      })

      return tokenInfos
    }
  }

  private updateToken(headers: AxiosResponseHeaders = {}) {
    const tokenInfos = this.pickTokenInfos({
      token: headers.token,
      newjwt: headers.newjwt
    })
    if (tokenInfos) {
      useMainStore().updateUserInfos(
        {
          ...useMainStore().updateUserInfos,
          ...tokenInfos
        } as UserInfos,
        undefined,
        { updateToken: true }
      )
    }
  }

  public cache: LobbyCache = new LobbyCache(() => useNetWorkStore(), {
    expired: true,
    setup: false
  })
  protected paused(config: AxiosRequestConfig, id: string): false | string {
    const { tryStaticMode, onlyStaticMode, tryCacheToken } =
      config?.customParams || {}
    //如果强行开启了只用静态模式则不需要暂停
    if ((!!tryStaticMode && !!onlyStaticMode) || !!tryCacheToken) {
      return false
    }
    const needToken = !service.notNeedTokenFilter.includes(config?.url || '')
    const noToken = !service.getToken(config)
    if (needToken && noToken) {
      const mode = service.getTokenMode(config)
      LobbyService.log(
        `【paused-resume】${config.url}:等待依赖的Token：${mode}`
      )
      tokenDependManage[mode].push(config)
      return 'logined'
    }

    if (config.customParams?.throttleDelay) {
      if (!throttleDelayManage[id]) {
        setTimeout(() => {
          this.resume(id)
        }, config.customParams?.throttleDelay)
      }
      LobbyService.log(`${config.url}:等待throttleDelay数值后发出`)
      throttleDelayManage[id] = throttleDelayManage[id] || []
      throttleDelayManage[id].push(config)
      return id
    }

    return false
  }

  /**
   * 记录域名请求成功次数
   */
  private updatedomainRequestRecord(
    config: AxiosRequestConfig,
    mode: 'success' | 'fail' = 'success',
    type: 'oss' | 'api' = 'api'
  ) {
    try {
      const baseUrl =
        config.baseURL === '/hall'
          ? `${window.location.origin}/hall`
          : config.baseURL || ''

      const location = new URL(baseUrl)
      const domain = location.origin
      // 确保给定的类型存在于domainRequestRecord对象中
      if (!this.domainRequestRecord[type]) {
        console.warn(`Unknown type: ${type}`)
        return
      }

      if (!this.domainRequestRecord[type][domain]) {
        this.domainRequestRecord[type][domain] = {
          failCount: 0,
          successCount: 0,
          lastSuccessTime: 0,
          lastFailTime: 0
        }
      }
      if (mode === 'success') {
        this.domainRequestRecord[type][domain].successCount++
        this.domainRequestRecord[type][domain].lastSuccessTime = Date.now()
      } else {
        this.domainRequestRecord[type][domain].failCount++
        this.domainRequestRecord[type][domain].lastFailTime = Date.now()
      }
      // console.log(
      //   'updatedomainRequestRecord',
      //   JSON.parse(
      //     JSON.stringify({
      //       domainRequestRecord: this.domainRequestRecord,
      //       apiDomain: this.apiDomain
      //     })
      //   )
      // )
    } catch (error) {}
  }

  /**
   * 决定每一个请求首次用哪个域名
   */
  private getDefaultBaseURL(_config: AxiosRequestConfig) {
    const { bestBaseURL } = useMainStore()
    const needAutoBaseUrl = true

    /**
     * 允许换api域名是大前提
     */
    if (needAutoBaseUrl) {
      /**
       * 优先线路测出最好的域名
       */

      if (bestBaseURL) {
        return bestBaseURL + '/hall'
      }
    }

    /**
     * 兜底
     */
    const domain = this.apiDomain[0] || ''
    return `${domain}/hall`
  }

  /**
   * 获取下一次重试请求的基础URL。
   * 如果上次请求是静态化请求或域名列表为空，将返回默认的基础URL。
   * 否则，将按顺序选择下一个域名作为基础URL。
   *
   * @param {AxiosRequestConfig} [lastConfig] 上次请求的配置
   * @return {string} 下一次请求的基础URL
   */
  private getRetryBaseURL(
    lastConfig?: AxiosRequestConfig,
    config: AxiosRequestConfig = {}
  ): string {
    // 检查是否是静态化请求或域名列表是否为空
    const isLastWithStaticMode = get(lastConfig, '_withStaticMode') === true
    if (isLastWithStaticMode || this.apiDomain.length === 0) {
      return this.getDefaultBaseURL(config)
    }

    // 获取上一次的基础URL，并尝试找到其在列表中的位置
    const lastBaseUrl = lastConfig?.baseURL || ''
    const lastBaseUrlIndex = this.apiDomain.findIndex((it) =>
      lastBaseUrl.includes(it)
    )

    // 如果上一次的URL不在列表中，从列表的开始重新尝试
    const nextIndex =
      lastBaseUrlIndex >= 0 ? (lastBaseUrlIndex + 1) % this.apiDomain.length : 0

    // 构建并返回下一次请求的基础URL
    return `${this.apiDomain[nextIndex] || ''}/hall`
  }

  /**
   * 还原请求
   */
  protected resume(event: string): void {
    if (['logined'].includes(event)) {
      tokenDependManage[event as TokenMode].splice(0).forEach((conf) => {
        LobbyService.log(`【paused-resume】${conf.url}:恢复请求`)
        conf && this.resumeHandler(conf, event)
      })
    } else {
      ;(throttleDelayManage[event] || []).forEach((conf) => {
        conf && this.resumeHandler(conf, event)
      })
      delete throttleDelayManage[event]
    }
  }
  //装载
  protected setup(config: AxiosRequestConfig): void {
    const { method, url } = config
    /**
     * 赋值自定义参数
     */
    config.customParams = merge(
      {
        maxRetryCount: config.customParams?.tryStaticMode
          ? LobbyService.MAX_RETRY_COUNT * 2
          : LobbyService.MAX_RETRY_COUNT,
        uuid: v4(), //生成唯一ID
        ossNegotiatedCaching: false, //控制oss请求是否走304协商模式
        noMerge: false, // 默认开启请求合并
        noEncrypt: false, //默认开启加密
        silentOnError: false, //默认响应公共层错误处理
        noErrorMessage: false, //默认使用公共错误弹窗
        noLog: false, //默认开启日志
        noResponseDataCodeChecked: false, //默认进行消息code检测
        autoBaseUrl: true,
        autoQueryParams: !/\.json(\?(\S)*)?$/.test(url || ''), //默认.json 或者 .json带query参数的判断为静态文件，设为false
        errorFilter: (data: Response) =>
          data?.code !== GlobalConst.ServiceCode.FORCE_GEETEST,

        errorCatchFilter: () => {
          return false
        },
        useCache: true,
        /**
         * 默认的出错后触发缓存的逻辑为http非200
         */
        useCacheInError: (
          _: AxiosResponse<Response> | AxiosError,
          errorType: false | 'axiosError' | 'dataError'
        ) => {
          //http非200
          return errorType === 'axiosError'
          //http非200 或 （http200但业务code不为success（1））
          // return !!errorType
        },
        /**
         * 默认刷新后将缓存过期
         */
        cacheExpiredOnReload: true
      },
      config.customParams
    )
    /**
     * 标记当前是否需要走静态化模式
     */
    config._withStaticMode = this.withStaticMode(config)
    const setDefaultRequestConfig = config.customParams?.setDefaultRequestConfig
    const xTimestamp = GlobalManager.Site.getServerTime()
    config._startTime = Date.now()
    const targetConfig = merge(
      {},
      setDefaultRequestConfig
        ? setDefaultRequestConfig?.(
            this.getDefaultRequestConfig(config, xTimestamp)
          )
        : this.getDefaultRequestConfig(config, xTimestamp),
      config
    )
    merge(config, targetConfig)
    /**
     * 添加默认参数
     */
    this.requestPayloadHandler(
      config,
      () => {
        // config.params.time = config.params.time ?? xTimestamp
      },
      () => {
        config.data.time = config.data.time ?? xTimestamp
      }
    )

    const { uuid } = config.customParams
    LobbyService.log(
      `[C2S HTTP ${uuid}]:${method} >>>>>> ${url}\n${JSON.stringify(
        pick(config, ['params', 'data', 'customParams'])
      )}`,
      config
    )
  }
  //加密
  protected encode(config: AxiosRequestConfig): void {
    const noEncrypt = this.isNoEncrypt(config)
    const autoFormData = config.customParams?.autoFormData
    config._cryptoKey = this.calcCryptoKey(config)

    /**
     * 加密处理
     */
    this.requestPayloadHandler(
      config,
      () => {
        if (!noEncrypt) {
          if (config._withStaticMode) {
            return
          }
          config.params = {
            encryptString: GlobalCrypto.encrypt(
              config.params,
              this.getKey(config)
            )
          }
        }
      },
      () => {
        if (autoFormData) {
          config.data = this.createFormData(config.data, config)
        } else if (!noEncrypt) {
          config.data = GlobalCrypto.encrypt(config.data, this.getKey(config))
        }
      }
    )
  }
  //请求发送前
  protected beforeSend(config: AxiosRequestConfig): void {
    /**
     * api 反代标识为'/hall'
     */
    const isApiURL = config.baseURL === '/hall'
    const autoQueryParams = config.customParams?.autoQueryParams
    const useAppEnv = config?.customParams?.useAppEnv
    const lastAxiosResponse = config._lastAxiosResponse
    /**
     * GET请求默认参数不进行加密处理,并且所有get请求无论有参数都需要带默认传参
     */
    if (autoQueryParams && config.method === 'get') {
      const { language, siteCode } = useMainStore()
      const defaultParams = {
        /**
         * oss 时 不传
         */
        siteCode,
        /**
         * oss 时 不传
         */
        token: this.getToken(config) || null,
        currency: this.getCurrency(config) || '',
        language: GlobalConst.ServiceLanguageMap[language],
        platformType: useAppEnv ? 6 : 5
      }
      config.params = {
        ...defaultParams,
        ...config.params
      }
    }
    /**
     * 如果用/hall默认标识请求的分两种部署模式取当前域名或者api域名
     * api域名分为静态化模式和非静态化模式
     */
    if (isApiURL) {
      if (!config._withStaticMode) {
        config.baseURL = this.getDefaultBaseURL(config)

        const keyOmit = (() => {
          const keyOmit = config.customParams?.keyOmit || []
          const staticKeyOmit = config.customParams?.staticKeyOmit || []
          if (typeof keyOmit === 'boolean') {
            return keyOmit ? staticKeyOmit : []
          } else {
            return keyOmit
          }
        })()

        config.params = omit(config.params, [...keyOmit]) as Record<
          string,
          string
        >

        /**
         * 重试模式
         */
        if (config._retryCount) {
          config.baseURL = this.getRetryBaseURL(
            config._lastAxiosResponse?.config,
            config
          )
        }
      } else {
        const isLast304 = get(lastAxiosResponse, 'response.status') === 304
        const params = omit(config.params, [
          'siteCode',
          'token',
          ...(config.customParams?.staticKeyOmit || [])
        ]) as Record<string, string>

        const { ossDomain } = useMainStore()

        let index = config._retryCount || 0
        if (isLast304) {
          index--
        }
        const ossHost = ossDomain[index] || ossDomain[ossDomain.length - 1]
        const ossUrl = `${Object.keys(params)
          .sort()
          .reduce((pre, key) => {
            const value = params[key]
            if (typeof value !== 'undefined') {
              pre += `/${key}/${encodeURIComponent(
                typeof value === 'string' ? value : JSON.stringify(value)
              )}`
            }
            return pre
          }, '')}${config.customParams?.appendDefault ? '/default' : ''}.json`

        config.url = config.url + ossUrl

        const lastResponseHeaders =
          useMainStore().responseHeaders[config.url] || {}
        const cacheHeadersConfig = Object.entries({
          'last-modified': 'If-Modified-Since',
          etag: 'If-None-Match'
        }).reduce((pre, [resKey, reqKey]) => {
          if (lastResponseHeaders[resKey]) {
            pre[reqKey] = lastResponseHeaders[resKey]
          }
          return pre
        }, {} as Record<string, string>)
        /**
         * oss请求可先清空自定义得一些headers
         */
        config.headers = {}
        /**
         * 开启了协商缓存的情况下
         * 如果上次请求是304 Not Modified header中就需要去掉 i-m-s和i-n-m 参数
         * 其它情况加上
         */
        if (
          config.customParams?.ossNegotiatedCaching &&
          config.headers &&
          !isLast304
        ) {
          config.headers = merge({}, config.headers, cacheHeadersConfig)
        }

        config.baseURL = ossHost + `${ossHost.endsWith('/') ? '' : '/'}hall`
        delete config.params
      }
    }

    if (config.url?.includes('/home/prize')) {
      const headers = config.headers || {}
      localStorage.setItem(
        'prizelock',
        `${Date.now()}:${headers['x-request-id']}`
      )
    }
  }

  //解密
  protected decode(axiosResponse: AxiosResponse<Response>): void {
    try {
      if (axiosResponse.config._withStaticMode) {
        const { responseHeaders } = useMainStore()
        responseHeaders[axiosResponse.config.url || ''] = cloneDeep(
          axiosResponse.headers
        )

        /**
         * 需要解密
         */
        if (typeof axiosResponse.data === 'string') {
          try {
            axiosResponse.data = JSON.parse(
              GlobalCrypto.decrypt(axiosResponse.data, 'thanks,pig4cloud', {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
              })
            )
            axiosResponse.data = {
              code: LobbyService.SERVICE_CODE.SUCCESS,
              data: axiosResponse.data.data
            } as Response<unknown>
          } catch (error) {
            /**
             * 解密失败后进行重试标识，默认axiosError才会走重试，这里静态化json解密失败也重试
             */
            axiosResponse.config._forceRetry = true
            // console.error(error)
          }
          /**
           * json 明文
           */
        } else {
          axiosResponse.data = {
            code: LobbyService.SERVICE_CODE.SUCCESS,
            data: axiosResponse.data.data
          } as Response<unknown>
        }
      } else if (
        /**
         * 若content-type 为 text/plain 且 开启了加密 需 添加解密逻辑
         * 否则responseType 当成json 直接解析
         */
        !this.isNoEncrypt(axiosResponse.config) &&
        axiosResponse?.headers['content-type']?.includes('text/plain')
      ) {
        const responseText = axiosResponse.data as unknown as string
        const decryptText = GlobalCrypto.decrypt(
          responseText,
          this.getKey(axiosResponse.config),
          {
            padding: CryptoJS.pad.Pkcs7
          }
        )
        axiosResponse.data = transformResponseEnsureBigint(decryptText)
      }
    } catch (error) {
      console.error(error)
      /**
       * 解密失败后不处理，在后面catch中处理
       */
    }
    const { config, data } = axiosResponse as AxiosResponse<Response>

    if (data.time && config.method === 'post') {
      const mainStore = useMainStore()
      mainStore.updateTimeDiff(data.time)
    }

    const start = config._startTime || 0
    const end = Date.now()
    const diff = end - start
    if (start && !config.url?.includes('/logger') && diff >= 2000) {
      // 请求时间过长，需要上报
      /** 尚未厘清占用时间-暂停使用 */
      // GlobalEvents.dispatch({
      //   type: 'MONITOR_EVENT',
      //   eventName: 'ResponseSlow',
      //   payload: {
      //     start,
      //     diff: `${diff}`,
      //     end,
      //     traceid: axiosResponse.headers['x-trace-id'],
      //     requestid: headers['x-request-id'],
      //     url: config.url
      //   }
      // })
    }

    LobbyService.log(
      `[S2C HTTP ${config.customParams?.uuid}]:${config.method} <<<<<< ${
        config.url
      }\n${JSON.stringify(data)}\nx-request-id:${get(
        config.headers,
        'x-request-id'
      )}\nx-trace-id:${
        axiosResponse.headers['x-trace-id']
      }（可用此id找后端同事协助排查该次请求）\nDiff: ${diff} ms`,
      config
    )
  }

  /**
   * 检测是否出错
   */
  protected checkErrorType(res: AxiosResponse<Response> | AxiosError) {
    const isAxiosError = res instanceof AxiosError
    const config = res?.config
    if (isAxiosError) {
      return 'axiosError'
    }

    if (
      !config?.customParams?.noResponseDataCodeChecked &&
      res?.data?.code !== LobbyService.SERVICE_CODE.SUCCESS
    ) {
      return 'dataError'
    }

    return false
  }

  protected async catch(res: AxiosResponse<Response> | AxiosError) {
    const config = res.config
    const errorType = this.checkErrorType(res)
    const originConfig = config._origin_ || config
    const id = this.identify(originConfig)
    const cacheDep =
      originConfig.customParams?.cacheDep || this.cache.dep(originConfig)
    const needUseCacheInError = config.customParams?.useCacheInError?.(
      res,
      errorType
    )
    const useCache =
      this.cache.has(id, { cacheDep, axiosRequestConfig: config }) &&
      needUseCacheInError

    /** 极验 */
    const geeTest = await GeeTest.geeTest(res as AxiosResponse)
    // 正常极验，重试接口
    if (geeTest.retry) {
      config._forceRetry = geeTest.retry
    }

    /**
     * 内置的公共静默处理逻辑
     */
    const innerErrorCatchFilter = (
      res: AxiosResponse<Response> | AxiosError
    ) => {
      if (errorType === 'axiosError') return false
      const error = res as AxiosResponse<Response>
      const serviceCode = GlobalConst.ServiceCode
      /** 登录注册错误返回的行为验证code不提示message，直接打开行为验证 */
      const errorCodelist = [
        serviceCode.FORCE_GEETEST,
        serviceCode.SMS_CODE_GEETEST,
        serviceCode.SWIPER_PUZZLE_GEETEST,
        serviceCode.NINE_SQUARE_GEETEST,
        serviceCode.GRAPHIC_CLICK_GEETEST,
        serviceCode.SMS_CODE_GAME,
        serviceCode.BIND_WITHDRAW,
        serviceCode.CENTER_WALLET_NOT_BALANCE
      ]
      const code = error.data?.code
      const errorCode = error.data?.errorCode
      return (
        errorCodelist.includes(code) ||
        (typeof errorCode === 'number' && errorCodelist.includes(errorCode))
      )
    }

    const handlerResponseError = (
      config: AxiosRequestConfig,
      message: string
    ) => {
      if (
        config.customParams?.silentOnError ||
        innerErrorCatchFilter(res) ||
        config.customParams?.errorCatchFilter?.(res)
      ) {
        return Promise.reject(res)
      }

      message = message || config.data.msg

      switch ((res as AxiosResponse<Response>)?.data?.code) {
        case LobbyService.SERVICE_CODE.TOKEN_EXPIRED:
          GlobalEvents.dispatch({
            type: 'TOKEN_EXPIRED',
            payload: { message }
          })
          break
        case LobbyService.SERVICE_CODE.OTHER_CLIENT_LOGIN: {
          const { token, deviceModel } =
            ((res as AxiosResponse<Response>)?.data?.data as {
              token: string
              deviceModel: string
            }) || {}
          GlobalEvents.dispatch({
            type: 'OTHER_CLIENT_LOGIN',
            payload: {
              token,
              deviceModel
            }
          })
          break
        }
        case LobbyService.SERVICE_CODE.VERSION_ERROR:
          GlobalEvents.dispatch({
            type: 'VERSION_CHECK',
            payload: {
              version: 0,
              force: true
            }
          })
          break
        //不停服的维护
        case LobbyService.SERVICE_CODE.SITE_MAINTAIN_ERROR:
        //不停服的冻结
        case LobbyService.SERVICE_CODE.SITE_FREEZE_ERROR:
        //不停服的冻结+维护
        case LobbyService.SERVICE_CODE.SITE_FREEZE_AND_MAINTAIN_ERROR:
          GlobalEvents.dispatch({
            type: 'SITE_MAINTAIN',
            payload: {
              res: res as AxiosResponse<Response>,
              requestErrorCountConsequent: this.requestErrorCountConsequent,
              isSiteFreeze:
                LobbyService.SERVICE_CODE.SITE_FREEZE_ERROR ===
                (res as AxiosResponse<Response>)?.data?.code,
              isSiteFreezeAndMaintain:
                LobbyService.SERVICE_CODE.SITE_FREEZE_AND_MAINTAIN_ERROR ===
                (res as AxiosResponse<Response>)?.data?.code,
              onError: () => {
                return createErrorMessage(message)
              }
            }
          })
          break

        default:
          {
            /**
             * 一些错误码代表静态化配置不及时，需要重新请求
             */
            if (
              [
                LobbyService.SERVICE_CODE.CONFIG_CHANGE_ERR,
                LobbyService.SERVICE_CODE.CONFIG_CHANGE_ERR_1
              ].includes((res as AxiosResponse<Response>)?.data?.code)
            ) {
              GlobalEvents.dispatch({
                type: 'CONFIG_CHANGE_ERR'
              })
            }

            /**
             * 可以用缓存的时候不给错误提示
             */
            if (useCache || config.customParams?.noErrorMessage) {
              return
            }

            createErrorMessage(message)
          }

          break
      }
    }

    /**
     * 是否进行上报,列表为不需要上报
     */
    const needTraceReport = [
      'maintain-time.json',
      '/listExtLinkV2',
      '/netstat/player/logger'
    ].every((it) => !config.url?.includes(it))

    const isTimeOut = this.isTimeoutError(res as AxiosError)

    const retryCount = config._retryCount || 0
    const maxRetryCount =
      config.customParams?.maxRetryCount ?? LobbyService.MAX_RETRY_COUNT
    const needRetry =
      ((errorType === 'axiosError' && !isTimeOut) || config._forceRetry) &&
      retryCount < maxRetryCount
    /**
     * 如果需要重试就进行重试
     * 否则响应错误
     */
    const tipsWord = merge(
      {},
      LobbyService.TIPS_WORD,
      config.customParams?.tipsWord
    )

    const doReport = (
      eventName: MonitorEventType,
      addon?: Record<string, unknown>
    ) => {
      if (!needTraceReport) return

      const start = config._startTime || 0
      // 获取网络连接信息
      const connection =
        (navigator.connection as unknown as {
          downlink: number
          effectiveType: string
          rtt: number
          saveData: boolean
        }) || {}

      // 构建要上报的数据对象
      const connectionInfo = JSON.stringify({
        downlink: connection.downlink, // 下行速度(Mbps)
        effectiveType: connection.effectiveType, // 连接类型 ('slow-2g', '2g', '3g', or '4g')
        rtt: connection.rtt, // 往返时延(ms)
        saveData: connection.saveData // 数据节省模式是否开启
      })

      let ver = 0
      try {
        ver = new Date(
          document.querySelector('html')?.getAttribute('data-version') as string
        ).getTime()
      } catch (e) {}

      const { siteURLInfos } = useMainStore()
      const headers =
        (res as AxiosResponse).headers ||
        (res as AxiosError).response?.headers ||
        {}
      const data =
        (res as AxiosResponse).data || (res as AxiosError).response?.data
      const payload = {
        ...addon,
        ip: useMainStore().ipCheck.ip,
        urltime: useMainStore().urltime,
        start,
        connectionInfo,
        requestId: requesyId,
        headers,
        url: `${isUrl(config.url || '') ? '' : config.baseURL}${config.url}`,
        axc: (res as AxiosError).code,
        duration: Date.now() - start,
        om: useMainStore().isOssMode,
        conn: config._cryptoKey
          ?.split?.('')
          ?.map?.((v, i) => (i > 5 ? '*' : v))
          ?.join?.(''),
        status: res.status,
        event: eventName,
        retry: retryCount,
        matry: maxRetryCount,
        ver,
        cotype: headers['content-type'] || 'empty',
        message: (res as AxiosError).message,
        api1: (siteURLInfos?.api_domain || [])[0],
        web1: (siteURLInfos?.web_domain || [])[0],
        text:
          headers['content-type']?.includes?.('text/plain') ||
          headers['content-type']?.includes?.('json')
            ? data
            : data?.substring?.(0, 500)
      }

      if (eventName === 'ResponseSystemError') {
        GlobalEvents.dispatch({
          type: 'TRACE_REPORT',
          payload
        })
      }
      GlobalEvents.dispatch({
        type: 'MONITOR_EVENT',
        eventName,
        payload
      })
    }
    const requesyId = res.config.customParams?.uuid
    /**
     * 记录失败的请求行为，timeout或者xhr错误的均标记失败
     */
    if (errorType && errorType !== 'dataError') {
      this.afterFail(config)
    }
    if (!needRetry) {
      switch (errorType) {
        case 'axiosError':
          if (!config.customParams?.silentOnError) {
            /**
             * 可以用缓存的时候不计数
             */
            if (this.isApi2Source(config) && !useCache) {
              this.requestErrorCountConsequent++
              if (
                this.requestErrorCountConsequent >=
                this.maxGoGateWayBack2SourceErrorCount
              ) {
                GlobalEvents.dispatch({
                  type: 'SITE_MAINTAIN',
                  payload: {
                    res: res as AxiosResponse<Response>,
                    requestErrorCountConsequent:
                      this.requestErrorCountConsequent
                  }
                })
              }
            }
          }

          /**
           * 网络超时
           */
          if (isTimeOut) {
            handlerResponseError(
              config,
              `${tipsWord.NETWORK_TIMEOUT}(Request-Id:${requesyId},url:${
                isUrl(config.url || '') ? '' : config.baseURL
              }${config.url}).${res.status || ''}`
            )
            doReport('ResponseTimeout', { by: 'timeout' })
          } else {
            handlerResponseError(
              config,
              `${tipsWord.SYSTEM_ERROR}(Request-Id:${requesyId},url:${
                isUrl(config.url || '') ? '' : config.baseURL
              }${config.url})。${res.status || ''} ${
                (res as AxiosError).message?.substring?.(0, 50) || ''
              }`
            )
            doReport('ResponseSystemError', { by: 'xhr' })
          }

          break
        case 'dataError':
          const responseMsg = (res as AxiosResponse<Response>).data.msg
          if (!responseMsg) {
            LobbyService.log(
              `${tipsWord.RSP_DATA_NOT_JSON}:${JSON.stringify(config)}`
            )
            doReport('ResponseSystemError', { by: 'format' })
          }

          handlerResponseError(
            config,
            responseMsg ||
              `${tipsWord.RSP_DATA_NOT_JSON}${requesyId}。${(
                res as AxiosError
              ).message?.substring?.(0, 50)}`
          )
          break
        default:
          break
      }
      // 触发刷新一下站点URL信息（需要额外排除业务返回即有数据且有code的情况）
      if (needTraceReport && (res as AxiosError).isAxiosError) {
        GlobalEvents.dispatch({
          type: 'REFRESH_URL',
          payload: {
            url: config.url,
            base: config.baseURL,
            status: res.status,
            start: config._startTime || 0,
            message: (res as AxiosError).message,
            headers: (res as AxiosResponse).headers,
            req: requesyId
          }
        })
      }
      return Promise.reject(res)
    } else {
      config._lastAxiosResponse = res
      return this.request(config, retryCount + 1)
    }
  }

  get apiDomain() {
    const { siteURLInfos } = useMainStore()
    const api_domain = siteURLInfos?.api_domain || []
    const web_domain = siteURLInfos?.web_domain || []
    const apidomainRequestRecord = this.domainRequestRecord['api']
    // 格式化并去重所有域名
    const domains = [...api_domain, ...web_domain]
      .map((domain) => domain.replace(/\/+$/, '')) // 对每个域名去除尾部的“/”
      .filter((value, index, context) => context.indexOf(value) === index) // 去重

    return domains.sort((a, b) => {
      const aLastSuccessTime = apidomainRequestRecord[a]?.lastSuccessTime || 0
      const bLastSuccessTime = apidomainRequestRecord[b]?.lastSuccessTime || 0
      const aLastFailTime = apidomainRequestRecord[a]?.lastFailTime || 0
      const bLastFailTime = apidomainRequestRecord[b]?.lastFailTime || 0

      // 判断最新活动是否为成功
      const aIsSuccessNewest = aLastSuccessTime > aLastFailTime
      const bIsSuccessNewest = bLastSuccessTime > bLastFailTime

      // 最新成功的在前，最新失败的在后
      if (aIsSuccessNewest && !bIsSuccessNewest) {
        return -1 // A 成功且 B 失败，A 应排在 B 前面
      } else if (!aIsSuccessNewest && bIsSuccessNewest) {
        return 1 // A 失败且 B 成功，B 应排在 A 前面
      } else {
        // 如果都是最新成功或都是最新失败，比较时间戳
        if (aIsSuccessNewest) {
          // 如果都是最新成功，按成功时间降序
          return bLastSuccessTime - aLastSuccessTime
        } else {
          // 如果都是最新失败，按失败时间升序
          return aLastFailTime - bLastFailTime
        }
      }
    })
  }

  public static log(log: string, config?: AxiosRequestConfig) {
    const content = moment().format() + ' new-service' + ' ' + log
    if (!config?.customParams?.noLog) {
      Logger.primary('network', content)
    }
    Logger.myDebug('network', content)
  }
  public static SERVICE_CODE = GlobalConst.ServiceCode
  public static MAX_RETRY_COUNT = 4
  public static get TIPS_WORD() {
    const locale = useI18n()
    return {
      SYSTEM_ERROR: locale.t('lobby.common.errorMessages.NETWORK_ERROR'),
      RSP_DATA_NOT_JSON: locale.t(
        'lobby.common.errorMessages.RSP_DATA_NOT_JSON'
      ),
      NETWORK_TIMEOUT: locale.t('lobby.error.networkTimeout')
    }
  }
  /**
   *  未确定时为wait,登录成功为logined,无token或者fastLogin失败为static
   */
  private static loginState: LoginState = 'wait'

  public setLoginState(loginState: LoginState) {
    LobbyService.loginState = loginState
    if (['fakeLogined', 'logined'].includes(loginState)) {
      this.resume('logined')
    }
  }

  public notNeedTokenFilter = [
    '/ipCheck',
    '/cocos/config_data.json',
    '/cocos/maintain-time.json',
    '/cocos/maintain-time-default.json',
    '/hall/api/third/game/platformExchange/findListAll.json'
  ]

  /**
   * 登陆前需要加密的接口
   */
  public beforeLoginedEncryptFilter = [
    '/api/lobby/config/getForcedUpdateAndroidUrl',
    '/api/protected/user/smslogin',
    '/home/check/username',
    '/home/fastlogin',
    '/home/login',
    '/home/onekeyquicklogin',
    '/home/onekeyquickregister',
    '/home/onekeyregisterlogin',
    '/home/register',
    '/home/check/register',
    '/home/testregister',
    // '/home/updateGuestCurrency',
    '/home/thirdpartylogin',
    '/home/thirdpartyregister',
    '/shortmsg/create',
    '/shortmsg/validate',
    '/user/email/createmail',
    '/user/notlogin/verifyEmail',
    '/user/notlogin/getaccountquestion',
    '/user/notlogin/getquestion',
    '/user/notlogin/resetpasswd',
    '/user/notlogin/verificquestion',
    '/user/notlogin/verificquestionandwithdrawpasswd',
    '/user/notLogin/resetPassVerify',
    '/user/notlogin/resetpasswd',
    '/user/sms/resetpasswd',
    '/user/sms/verifyaccount',
    '/user/sms/verifycode',
    '/user/sms/verifyphone',
    '/user/captcha/verifyPhone',
    '/user/notlogin/getAccountVerifyList',
    '/user/captcha/getUserInfo',
    '/geetest/validate/v4',
    '/activetask/newcomer_benefit_reward',
    '/promote/linksetting'
  ] as const

  /**
   * 无论什么情况均需要取静态staticToken的接口
   */
  public get systemTokenUrl() {
    return [
      ...this.beforeLoginedEncryptFilter,
      /**WEB上报剪切板数据 */
      '/v1/netstat/player/adInfo',
      '/api/lobby/channel/go/getChannelInfoById',
      '/promote/hall/makeMoneyPage',
      '/customer/staffallv3',
      '/user/vipConfig',
      '/home/captcha',
      '/shortmsg/create',
      '/shortmsg/validate',
      '/promote/getIpBindInfo',
      '/promote/binding/reportview',
      '/risk/geetest/tokenQuery',
      '/gameapi/hot-list/v2',
      '/home/musiclist',
      '/home/smsCountry',
      '/club/entry',
      '/download/getDownloadUrl',
      '/download/template/listDownloadTemplate',
      '/message/banner/index',
      '/gameapi/game-list/v2',
      '/home/getsysinfo',
      '/api/lobby/aboutUs/index/getInfo',
      '/api/lobby/brandLogo/getBrandLogoUse',
      '/api/lobby/currencyInfo/getAllCurrency',
      '/api/lobby/footerConfig/getInfo',
      '/api/lobby/h5/config/getAccessRestrictedInfo',
      '/api/lobby/site/getSiteInfo',
      '/api/lobby/webapi/h5/config/getInfo',
      '/api/lobby/userAgreement/index/getInfo',
      '/api/lobby/webPush/getInfo',
      '/activetask/pop_newcomerBenefit', //新人福利弹窗静态数据
      '/activetask/pop_taskDay', //每日任务弹窗静态数据
      '/activetask/pop_taskWeek', //每周任务弹窗静态数据
      '/activetask/pop_taskThreeDay', //三日任务弹窗静态数据
      '/active/active_popRecharge', //首充弹窗静态数据
      // '/activetask/newcomer_benefit_pop', //(老接口)登录后新人福利奖励
      '/activetask/newcomer_benefit_reward', //(老接口)登录前新人福利奖励
      '/customer/getWebTrans',
      '/home/opt_type_v2',
      '/api/lobby/webapi/forceUpdate/getForceUpdate', // 获取APP是否强更
      '/api/lobby/layoutDesign/getLayout',
      '/api/lobby/loadingBackdrop/getInfo', //业务中无
      '/api/game/hall/listVirtualBonusPoolV2', // 获取虚拟彩金池配置数据
      '/api/game/hall/listPlatformCateLoadV2', //游戏菜单大类
      '/api/game/hall/listPlatformGameV2', //平台子游戏
      '/api/game/hall/hotListV2', //热门游戏
      '/api/game/hall/listTryGameV2', //试玩游戏
      '/api/game/hall/listAnchorHotLink', //主播热门游戏
      '/api/game/hall/listSearchGameV2', //搜索游戏
      '/api/game/hall/listSpecialGameV2', //特色子游戏
      '/api/game/hall/listExtLinkV2', // 游戏类型管理自定义外部链接
      '/system/status', //go合并的大接口
      '/api/lobby/webapi/optimization/site/config', //java合并的大接口
      '/active/quickList', //获取快捷活动列表
      '/active/customPageList', // 获取定制首页活动列表 比如青蓝版
      '/active/isShowV2', // VIP/返水/利息宝 开关配置
      '/active/getByTemplate', // 获取红包活动列表
      '/active/rechargeFund/setting/get', // 公积金充值活动 - 活动配置
      '/active/popSignActive', // 签到活动
      '/active/pop_chop_one_knife', // 砍一刀活动 抽奖助力
      '/active/pop_chop_one_knife_new', // 新砍一刀活动
      '/promote/getCreateAccountConfig', // 直属开户配置设置获取
      '/promote/config/introduce', // 代理返佣配置
      '/promote/config/tutorial', // 代理模式教程配置：
      '/promote/config/index', // 推广首页配置数据：
      '/promote/config/sensitiveData', // 获取敏感数据列表
      '/promote/config/sensitiveLowDisplay', // 敏感数据下级展示
      '/api/lobby/currencyInfo/getAllCurrency', // 获取全量币种信息
      '/certify/bankInfoV2', // 获取绑定银行卡选择列表 旧：/certify/bankInfo
      '/certify/cryptoListV2', // 获取提现数字币列表 旧：/certify/cryptoList
      '/api/lobby/finance/getSiteCurrencyExchangeRateForOss', // 获取提现汇率 旧：/finance/getSiteCurrencyExchangeRate
      '/payPopup/settingAndSlogans', // 获取智能弹窗公共及标题配置 /payPopup/slogan/random，/payPopup/setting接口合并
      '/certify/withdrawSettingV2' // 获取用户提现信息
    ] as const
  }

  public getKey(config?: AxiosRequestConfig): string {
    return config?._cryptoKey || ''
  }

  /**
   * 1.依赖登陆后token的接口 保持现有逻辑不变（取用户信息的currency，无论是试玩用户还是正式用户）
   * 2.依赖静态登录token的那些接口就分情况，如果登录过了，就会用上次登录的用户信息的货币，否则用站点默认货币
   * @param config
   * @returns
   */
  private getCurrency(config?: AxiosRequestConfig) {
    const { useFastLogin, userInfos, currentTryCurrency } = useMainStore()
    /**
     * 依赖登录后token的接口，取用户信息的currency
     */
    if (this.getTokenMode(config) === 'logined') {
      return userInfos?.currency
      /**
       * 依赖静态token的接口
       */
    } else {
      return useFastLogin
        ? userInfos?.currency || currentTryCurrency?.currencyCode
        : currentTryCurrency?.currencyCode
    }
  }

  /* 强制请求参数和返回结果不加密 */
  get forceNoEncrypt() {
    return !!defaultUrlQueryParams['mock.noEncrypt']
  }

  private isNoEncrypt(config?: AxiosRequestConfig) {
    return this.forceNoEncrypt || config?.customParams?.noEncrypt
  }

  private requestPayloadHandler(
    config: AxiosRequestConfig,
    queryHandler?: () => void,
    bodyHandler?: () => void
  ) {
    config.params && queryHandler?.()
    config.data && bodyHandler?.()
  }

  private createFormData(
    data: Record<string, string | Blob>,
    config?: AxiosRequestConfig
  ) {
    const noEncrypt = this.isNoEncrypt(config)
    if (data instanceof FormData) return data
    const create = (obj: Record<string, string | Blob>) => {
      const formData = new FormData()
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          formData.append(key, obj[key])
        }
      }
      return formData
    }
    if (noEncrypt) {
      return create(data)
    } else {
      const groups = Object.keys(data).reduce(
        (pre, cur) => {
          if (data[cur] instanceof Blob) {
            pre.files[cur] = data[cur] as Blob
          } else {
            pre.needEncryptObj[cur] = String(data[cur] as string)
          }
          return pre
        },
        { files: {}, needEncryptObj: {} } as {
          files: Record<string, Blob>
          needEncryptObj: Record<string, string>
        }
      )
      return create({
        ...groups.files,
        encryptString: GlobalCrypto.encrypt(
          groups.needEncryptObj,
          this.getKey(config)
        )
      })
    }
  }

  public getDefaultRequestConfig(
    config?: AxiosRequestConfig,
    timestamp: number = GlobalManager.Site.getServerTime()
  ): AxiosRequestConfig {
    const {
      language,
      siteCode,
      auth,
      userInfos,
      getFingerId,
      uuid,
      isOssMode
    } = useMainStore()
    const noEncrypt = this.isNoEncrypt(config)
    const lanCode = GlobalConst.ServiceLanguageMap[language]
    const nonce = config?.customParams?.uuid || v4()
    const useAppEnv = config?.customParams?.useAppEnv
    /**
     * 1659958715
     * c42757b0-8d92-4f33-9761-8730035c98ae
     * Ngtwv/ZQDGFgio52saoobfzOe6h5iv4wsHQVlbpCu+TB0e3oqD93A+ku9adyFWVB
     */
    const sign = `${timestamp}${nonce}`
    return {
      responseType: noEncrypt ? 'json' : 'text',
      headers: {
        'x-object-id': JSON.stringify({
          uid: userInfos?.username || '',
          browserLanguage: navigator.language,
          om: isOssMode,
          init: (() => {
            const result = traceLog.find((it) => it.event === 'init')?.data
            return pick(result, ['created', 'version'])
          })()
        }),
        /**
         * 极速包和马甲包均会传true
         */
        isWgPackage,
        /**
         * 马甲包
         */
        isVest: windowConfig.package.isVest ?? false,
        /**
         * 极速包
         */
        isSpeedPackae: windowConfig.package.isLite ?? false,
        /** 0 saas包网 1 自营游戏 2 俱乐部 3 下载页 4 H5大厅 5 PC大厅 6 APP大厅 */
        platformType: useAppEnv ? 6 : 5,
        // 1:iOS ,2:Android ,3:H5  4: Web
        devicetype: useMainStore().isWeb ? 4 : 3,
        'content-type': noEncrypt ? 'application/json' : 'text/plain',
        'accept-language': lanCode,
        'x-request-id': nonce,
        device: uuid,
        currency: this.getCurrency(config) || '',
        debug: this.forceNoEncrypt ? 123456 : '',
        timestamp,
        nonce,
        sign: GlobalCrypto.encrypt(sign, GlobalCrypto['c2lnbktleQ==']),
        siteCode: siteCode,
        language: lanCode,
        token: this.getToken(config) || '',
        newJwt: this.getToken(config, true) || '',
        auth,
        deviceModel: this.getDeviceModel(),
        domain: window.location.host,
        // 客户端时区，以计算对应的时间
        clienttimezone: `UTC${DateUtils.utcOffsetOfHour}`,
        'x-custom-referer': window.location.href,

        'x-version': this.getVersion(),
        browserfingerid: getFingerId
      }
    }
  }

  /**
   * 获取当前设备环境真实的系统类型
   * 马甲包就靠ua判断
   * 极速包靠原生注入
   */
  public getDeviceOsType() {
    const { osType } = useMainStore()
    if (windowConfig.package.isVest) {
      if (getCurrentDevice().ios()) {
        return OsType.IOS_APP
      } else {
        return OsType.ANDROID_APP
      }
    } else if (windowConfig.package.isLite) {
      return windowConfig.speedPackageConfig?.osType
    }

    return osType
  }

  public getDeviceModel() {
    const { browser } = getCurrentDevice()
    const deviceModel =
      /**
       * 原生环境注入的
       */
      windowConfig.wgPackage?.deviceModel ||
      /**
       * 取浏览器版本信息
       */
      `${browser().type}${browser().versions}`

    /**
     * 如果是马甲包环境，地址栏传了fixed.deviceModel参数的就以它为准
     */
    if (windowConfig.package.isVest) {
      return (
        (defaultUrlQueryParams['fixed.deviceModel'] as string) || deviceModel
      )
    }
    return deviceModel
  }

  /**
   * 传给后端客户端的版本号，后端根据此版本号判断是否返回错误码,纯数字类型说明是内网环境变量未注入，则是时间戳，时间戳时就不传
   */
  public getVersion() {
    const appVersion = defaultUrlQueryParams['mock.appVersion']
      ? String(defaultUrlQueryParams['mock.appVersion'])
      : process.env.APP_VERSION

    let xVersion = ''
    // 检查APP_VERSION是否定义且非纯数字
    if (appVersion && !/^\d+$/.test(appVersion)) {
      // 移除开头的v或V（如果存在），使用replace函数
      xVersion = appVersion.replace(/^v/i, '')
    }
    return xVersion
  }

  /**
   * 获取token类型
   */
  public getTokenMode(config?: AxiosRequestConfig): TokenMode {
    return this.systemTokenUrl.find((it) => config?.url === it)
      ? 'static'
      : 'logined'
  }

  public getToken(config?: AxiosRequestConfig, isJwtToken = false): string {
    if (service.notNeedTokenFilter.includes(config?.url || '')) {
      return ''
    }
    const { tokenInfos } = useTokenStore()
    // 测试，生产，体验，开发环境使用不同的静态token对应后端接口做cdn缓存，各环境token由运维维护在shell脚本中

    if (isJwtToken) {
      return tokenInfos?.jwt_token || ''
    }

    const loginState = LobbyService.loginState
    const tryCacheToken = !!config?.customParams?.tryCacheToken

    if (
      this.getTokenMode(config) === 'static' ||
      loginState === 'fakeLogined'
    ) {
      const staticToken =
        process.env.SITE_STATIC_TOKEN ??
        siteConfig?.INJECT_DATA?.apiGetSystemStatus?.data?.data?.homeGetSysInfo
          ?.token ??
        'd856858f-fcf9-427b-a2b2-222dd1be1168'
      return staticToken
    }

    if (loginState === 'logined' || tryCacheToken) {
      return tokenInfos?.session_key as string
    }

    return ''
  }

  private calcCryptoKey(config?: AxiosRequestConfig, username?: string) {
    const tokenMode = this.getTokenMode(config)
    const token = String(config?.headers?.token) || this.getToken(config)
    const { isTryMode, userInfos } = useMainStore()
    const uid = username || userInfos?.username
    const forceStaticAesKey = !!config?.customParams?.forceStaticAesKey

    /**
     * 未登录统一用不依赖uid的计算key模式
     */
    return (tokenMode === 'logined' || username) &&
      !isTryMode &&
      !forceStaticAesKey
      ? GlobalCrypto.md5(token + uid).slice(0, 16)
      : GlobalCrypto.md5(token + GlobalCrypto.md5(token)).slice(2, 18)
  }

  /**
   * 检测当次请求是否用静态请求json模式
   * @param config
   * @returns
   */
  private withStaticMode(config: AxiosRequestConfig) {
    const { tryStaticMode, onlyStaticMode } = config?.customParams || {}
    //如果强行开启了只用静态模式则一直用静态模式
    if (!!tryStaticMode && !!onlyStaticMode) {
      return true
    }
    const token = this.getToken(config) || ''
    const forceUseApiMode = token && token !== process.env.SITE_STATIC_TOKEN
    const retryCount = config._retryCount || 0
    /**
     * 1.开启了尝试静态模式
     * 2.非传真实token的场景
     * 3.最多重试2次oss
     */
    return (
      !!tryStaticMode &&
      !forceUseApiMode &&
      retryCount <= LobbyService.MAX_RETRY_COUNT
    )
  }

  public reset() {
    LobbyService.loginState = 'wait'
    tokenDependManage.static = []
    tokenDependManage.logined = []
  }

  /**
   * 增
   */
  public async post<T, AxiosResponseData = Response>(
    config: Omit<AxiosRequestConfig, 'method'>
  ): ResponseInstance<
    AxiosResponseData extends Response ? Response<T> : AxiosResponseData
  > {
    return this.request(merge(config, { method: 'post' }))
  }

  /**
   * 增
   */
  public async get<T, AxiosResponseData = Response>(
    config: Omit<AxiosRequestConfig, 'method'>
  ): ResponseInstance<
    AxiosResponseData extends Response ? Response<T> : AxiosResponseData
  > {
    return this.request(merge(config, { method: 'get' }))
  }

  /**
   * 上传
   */
  public async upload<T, AxiosResponseData = Response>(
    config: AxiosRequestConfig
  ): ResponseInstance<
    AxiosResponseData extends Response ? Response<T> : AxiosResponseData
  > {
    return this.request(
      merge(
        {
          method: 'post',
          headers: {
            'Content-type': 'multipart/form-data'
          }
        },
        config
      )
    )
  }

  /**
   * @description 获取当前请求地址
   * @param conf
   * @returns
   */
  public getUri(conf?: AxiosRequestConfig) {
    return this.instance.getUri(conf)
  }

  /**
   * @description 获取当前是否是超时错误
   * @param res
   * @returns
   */
  isTimeoutError(res: AxiosError): boolean {
    return this.getErrorType(res) === 'timeoutError'
  }
  /**
   * @description 获取当前是否是网络错误 包含超时
   * @param res
   * @returns
   */
  isNetworkError(res: AxiosError): boolean {
    const errorType = this.getErrorType(res)
    return errorType === 'timeoutError' || errorType === 'netError'
  }

  /**
   * @description 业务层获取分辨错误类型
   * netError：http 非200 网络层错误
   * dataError: http 200 响应数据解析错误
   * timeout：网络超时错误
   * @returns
   */
  getErrorType(res: AxiosError): 'netError' | 'dataError' | 'timeoutError' {
    const errorType = this.checkErrorType(res)
    if (errorType === 'axiosError') {
      const isTimeoutError =
        res && res.code === 'ECONNABORTED' && res.message?.includes('timeout')
      if (isTimeoutError) {
        return 'timeoutError'
      } else {
        return 'netError'
      }
    } else {
      return 'dataError'
    }
  }
}

function transformResponseEnsureBigint(data: string): Response {
  // Replacing the default transformResponse in axios because this uses JSON.parse and causes problems
  // with precision of big numbers.
  // https://github.com/axios/axios/blob/6642ca9aa1efae47b1a9d3ce3adc98416318661c/lib/defaults.js#L57
  if (typeof data === 'string') {
    try {
      //jsonBigintInstance.parse会删除对象原型，需要重新clone时行恢复
      data = cloneDeep(jsonBigintInstance.parse(data))
    } catch (e) {
      /* Ignore */
    }
  }
  return data as unknown as Response
}

const service = new LobbyService(
  axios.create({
    baseURL: '/hall',
    timeout: 1 * 60 * 1000,
    headers: {
      'Content-type': 'application/json'
    },
    transformResponse: [transformResponseEnsureBigint]
  })
)

export default service
