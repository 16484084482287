import { ThemeComponentType } from './type'
export default {
  [ThemeComponentType.HomePage]: {
    com: () =>
      import(
        /* webpackChunkName: "home@theme=29" */ '@/views/game/themes/theme-29/home'
      )
  },
  [ThemeComponentType.SearchPage]: {
    com: () =>
      import(
        /* webpackChunkName: "search@theme=29" */ '@/views/game/themes/theme-29/search'
      )
  },
  [ThemeComponentType.SubGamePage]: {
    com: () =>
      import(
        /* webpackChunkName: "subGame@theme=29" */ '@/views/game/themes/theme-29/subGame'
      )
  },
  [ThemeComponentType.EmbeddedPage]: {
    com: () =>
      import(
        /* webpackChunkName: "embedded@theme=29" */ '@/views/game/themes/theme-28/embedded'
      )
  },
  [ThemeComponentType.Footer]: {
    com: () =>
      import(
        /* webpackChunkName: "footer@theme=29" */ '@/layouts/components/footer/themes/theme-28'
      )
  },
  [ThemeComponentType.FloatBar]: {
    com: () =>
      import(
        /* webpackChunkName: "floatBar@theme=29" */ '@/layouts/components/floatBar/themes/theme-28'
      )
  },
  [ThemeComponentType.Styles]: {
    com: () =>
      import(
        /* webpackChunkName: "style@theme=29" */ '@/views/global-styles/themes/theme-29'
      )
  },
  [ThemeComponentType.Layouts]: {
    com: () =>
      import(
        /* webpackChunkName: "layout@theme=29" */ '@/layouts/themes/theme-29'
      )
  }
}
