import { AutoModal } from '.'
import { AutoVisitorStatus, LinkType } from '@/api/common/type'
import { GlobalManager } from '@/context'
import { QueryType } from '@/router/guard'
import { TaskCategoryApiCodeEnum as TCACE } from '@/views/task/api/type'
import { judgeIsForbidPopByPageAddress } from '@/views/global-modal/modal/common/util/judgeIsForbidPopByPageAddress'
import { pick } from 'lodash'
import { useDownloadAppStore } from '@/layouts/components/downloadApp/store'
import { useEventStore } from '@/views/event/store'
import { useFirstChargeConstantStore } from '@/views/global-modal/modal/first-charge-constant/store'
import { useGameStore } from '@/views/game/store'
import { useMainStore } from '@/store/index'
import { useNewCutOnceStore } from '@/views/event/store/cutOnce'
import { useSignStore } from '@/views/event/item/component/sign/store'
import { useTaskModalConstant } from '@/views/global-modal/modal/tasks-modal-dialog/store/task-modal-constant'
import { useWaitingGetStore } from '@/views/global-modal/modal/waiting-get/store'
import Modal from '@/controller/Modal'

const modalQueue: AutoModal[] = [
  {
    name: 'loginOrRegister',
    loadNextTiming: 'afterClosed',
    show: () => {
      const { hasLogined, systemInfos, linkType } = useMainStore()
      const agentPayload = pick(GlobalManager.App.$route.query, [
        'cid',
        'id',
        'type'
      ])

      /**
       * 直达首页、试玩活动页不展示登陆注册弹框
       */
      // 如果存在 cid 则重新调整优先级，优先判断 agentPayload 的 type
      if (agentPayload.cid && agentPayload.type) {
        if (
          [QueryType.TEST_REGISTER, QueryType.EVENT, QueryType.HOME].includes(
            agentPayload.type as QueryType
          )
        ) {
          return false
        }
      } else {
        // 如果不存在 cid 或者 agentPayload 的 type 不符合条件，然后才判断 linkType
        if (
          [LinkType.TEST_REGISTER, LinkType.HOME].includes(linkType as LinkType)
        ) {
          return false
        }
      }

      // 如果后台开启跳过注册页或渠道推广链接配置显示注册页则不用显示登录注册弹框（如果是推广链接，选择了注册页，则不受影响）
      // 不再区分PC和H5 都用H5的字段
      const auto_visitor_login =
        systemInfos?.auto_visitor_login_h5 === AutoVisitorStatus.OPEN

      const showLoginOrRegister =
        !hasLogined &&
        (auto_visitor_login ||
          agentPayload?.type === QueryType.REGISTER ||
          linkType === LinkType.REGISTER)

      if (showLoginOrRegister) {
        Modal.openLoginRegisterModal()
      }

      return showLoginOrRegister
    }
  },
  {
    // 强制修改登录密码
    name: 'forceChangePwd',
    show: () => {
      const { isNeedForceChangePwd } = useMainStore()
      if (isNeedForceChangePwd) {
        Modal.open('forceChangePwd')
      }
      return isNeedForceChangePwd
    }
  },
  {
    // 强制绑定安全项 (手机>邮箱>ga>提现密码) (可跳过的也弹)
    name: 'forceBindSecurity',
    show: () => {
      const { handleBindModalForName, needForceBindSecurity } = useMainStore()
      const isShowForceBind =
        needForceBindSecurity() && !!handleBindModalForName(true)

      if (isShowForceBind) {
        handleBindModalForName()
      }

      return isShowForceBind
    }
  },
  {
    name: 'downloadAppBySite',
    load: async () => {
      const { getNewComerBenefit } = useDownloadAppStore()
      await getNewComerBenefit()
    },
    show: () => {
      const { showDownloadAppFromSite } = useDownloadAppStore()
      if (showDownloadAppFromSite) {
        Modal.open('downloadAppBySite')
      }
      return showDownloadAppFromSite
    }
  },
  {
    // 消息弹窗
    name: 'forcePopup',
    reshowOnResume: true,
    load: async () => {
      /** 获取或更新活动信息 */
      await useEventStore().freshEventCategoryAndActivity()
      /** 获取或更新弹窗展示信息 */
      await GlobalManager.Site.getFrameBoxInfos()
    },
    show: () => {
      const { doLoginShowForcePopup, frameBoxInfos } = useMainStore()
      const popupList = frameBoxInfos.filter((item) => {
        if (item.not_remind_again || item.not_remind_login) {
          return false
        }
        return true
      })
      if (popupList.length && !judgeIsForbidPopByPageAddress()) {
        doLoginShowForcePopup()
      }
      return !!popupList.length && !judgeIsForbidPopByPageAddress()
    }
  },
  /** 新砍一刀活动弹窗 */
  {
    name: 'newCutOnce',
    loadNextTiming: 'afterLoaded',
    load: async () => {
      await useNewCutOnceStore().freshPopData()
    },
    show: () => {
      // 如果在游戏中 则不弹出
      if (useGameStore().isGamePlaying) {
        return false
      }
      const { hasLogined } = useMainStore()
      const { preJudgeOpenNewCutOnce, openNewCutOnceModal } =
        useNewCutOnceStore()
      const isShow = preJudgeOpenNewCutOnce({
        isLoginTrigger: hasLogined
      })
      if (isShow) {
        openNewCutOnceModal()
      }
      return isShow
    }
  },
  /** 抽奖助力活动弹窗 */
  {
    name: 'cutOnce',
    loadNextTiming: 'afterLoaded',
    load: async () => {
      await useEventStore().freshPopData()
    },
    show: () => {
      // 如果在游戏中 则不弹出
      if (useGameStore().isGamePlaying) {
        return false
      }
      const { hasLogined } = useMainStore()
      const { preJudgeOpenCutOnce, openCutOnceModal } = useEventStore()
      const isShow = preJudgeOpenCutOnce({
        isLoginTrigger: hasLogined
      })
      if (isShow) {
        openCutOnceModal()
      }
      return isShow
    }
  },
  /** 签到活动弹窗 */
  {
    name: 'sign',
    loadNextTiming: 'afterLoaded',
    load: async () => {
      await useSignStore().freshPopData()
    },
    show: () => {
      // 如果在游戏中 则不弹出
      if (useGameStore().isGamePlaying) {
        return false
      }
      const { hasLogined } = useMainStore()
      const { preJudgeOpenSign, openSignModal } = useSignStore()
      const isShow = preJudgeOpenSign({
        isLoginTrigger: hasLogined
      })
      if (isShow) {
        openSignModal()
      }
      return isShow
    }
  },
  /** 新版首充弹窗 */
  {
    name: 'firstChargeConstant',
    loadNextTiming: 'afterLoaded',
    load: async () => {
      await useFirstChargeConstantStore().freshPopData()
    },
    show: () => {
      const { hasLogined } = useMainStore()
      const { preJudgeOpenFirstCharge, openFirstChargeModal } =
        useFirstChargeConstantStore()
      const isShow = preJudgeOpenFirstCharge({
        isLoginTrigger: hasLogined
      })
      if (isShow) {
        openFirstChargeModal()
      }
      return isShow
    }
  },
  /** 新人福利弹框 */
  {
    name: 'newBenefits',
    loadNextTiming: 'afterLoaded',
    load: async () => {
      await useTaskModalConstant().freshConstantData([TCACE.xrfl])
    },
    show: (modalName: string) => {
      useTaskModalConstant().openSequentiallyTaskModal(
        {
          apiCode: [TCACE.xrfl]
        },
        modalName
      )
      return true
    }
  },
  /** 每日任务弹框 */
  {
    name: 'tasksDaily',
    loadNextTiming: 'afterLoaded',
    load: async () => {
      await useTaskModalConstant().freshConstantData([TCACE.mrrw])
    },
    show: (modalName: string) => {
      useTaskModalConstant().openSequentiallyTaskModal(
        {
          apiCode: [TCACE.mrrw]
        },
        modalName
      )
      return true
    }
  },
  /** 每周任务弹框 */
  {
    name: 'tasksWeekly',
    loadNextTiming: 'afterLoaded',
    load: async () => {
      await useTaskModalConstant().freshConstantData([TCACE.mzrw])
    },
    show: (modalName: string) => {
      useTaskModalConstant().openSequentiallyTaskModal(
        {
          apiCode: [TCACE.mzrw]
        },
        modalName
      )
      return true
    }
  },
  /** 神秘任务弹框 */
  {
    name: 'tasksEvery3Day',
    loadNextTiming: 'afterLoaded',
    load: async () => {
      await useTaskModalConstant().freshConstantData([TCACE.smrw])
    },
    show: (modalName: string) => {
      useTaskModalConstant().openSequentiallyTaskModal(
        {
          apiCode: [TCACE.smrw]
        },
        modalName
      )
      return true
    }
  },
  /** 待领取弹窗 */
  {
    name: 'waitingGet',
    loadNextTiming: 'afterLoaded',
    load: async () => {
      await useWaitingGetStore().freshPopData()
    },
    show: () => {
      // 如果在游戏中 则不弹出
      if (useGameStore().isGamePlaying) {
        return false
      }
      const { hasLogined } = useMainStore()
      const { preJudgeOpenWaitingGet, openWaitingGetModal } =
        useWaitingGetStore()
      const isShow = preJudgeOpenWaitingGet({
        isLoginTrigger: hasLogined
      })
      if (isShow) {
        openWaitingGetModal()
      }
      return isShow
    }
  }
]

export default modalQueue
