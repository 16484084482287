import {
  Base,
  Component,
  Mixins,
  Prop,
  Ref,
  Watch
} from '@/vue-property-decorator'
import { DirectiveOptions } from 'vue'
import { assetsPath } from '@/utils/business-utils/assets'
import {
  createOssAssetsPathOrigialRetry,
  createOssCommonDomainRetry,
  createOssDomainRetry,
  domainRetryListWrap
} from '@/utils/business-utils/retry'
import { retryMap } from '@/utils/Tool'
import { urlFormatter } from '@/utils/FormatterUtils'
import { useMainStore } from '@/store/index'
import { v4 } from 'uuid'
import OssAssets from '@/mixins/business/ossAssets'
import UnitMixins, { Props as UnitMixinsProps } from '@/mixins/unit'
import observerInstance from '@/context/observerInstance'
import style from './style.module.scss'
/** 占位空图片 */
const placeholder =
  'data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQImWNgYGBgAAAABQABh6FO1AAAAABJRU5ErkJggg=='

/** 图片渲染结果 */
export enum ImgLoadedResult {
  /** 渲染失败 */
  Error = 1,
  /** 渲染了空的占位符 */
  Empty = 2,
  /** 渲染成功 */
  Success = 3
}

/** 事件 */
export type ImgEvents = {
  /** 图片渲染结果 */
  onLoadedResult: ImgLoadedResult
}

interface Options {
  /**
   * 开启后直接在created时走赋值原图的逻辑
   */
  immediate?: boolean
  /**
   * 开启后 图片 为 wait状态时不渲染子组件
   */
  performance?: boolean
  /**
   * 懒加载，是否滚动到视口再加载
   */
  lazy?: boolean
  swRuntimeCacheName?: string
  type?: 'img' | 'div' | 'video' | 'li' | 'a' | 'i' | 'span'
  /**
   * 图片出来是否显示blur动画
   */
  blurAnimation?: boolean
  thumb?: string
  errorImg?: string
}
export interface Props extends UnitMixinsProps {
  /** 同步一组元素中的低级id(swipperId) */
  syncGroupsWrapId?: string
  /** 同步一组元素(当前在swiper中使用) */
  syncGroupsSrc?: boolean
  /** 开启骨架模式 图片加载时候会有骨架屏的滚动背景占位 */
  useSkeleton?: boolean
  src: string
  width?: number
  height?: number
  options?: Options
  [name: string]: unknown
}
export interface IMGScopedSlots {
  default?: void
}

type Image = HTMLImageElement | null
interface State {
  imgSrc: string
  /**
   * wait:初始
   * loading:下载原图中
   * success:首次下载成功
   * error：下载出错
   * loaded：浏览器触发的缓存直接调用
   */
  status: 'wait' | 'loading' | 'success' | 'error' | 'loaded'
  loadError: boolean
}

const BaseOptions: Options = {
  immediate: false,
  performance: true,
  type: 'img',
  /**
   * 默认开启懒加载
   */
  lazy: true,
  /**
   * 默认不开启模糊动画
   */
  blurAnimation: window.wgMock.blurAnimation
}

/**
 * 阿里oss相关缩略图、倍图、webp、懒加载支持组件
 * @param src 图片地址,路径不带域名
 * @param width 图片宽度
 * @param height 图片高度
 * @param options{
 *  type?: 'img' | 'div' | 'video' | 'li' | 'a' | 'i' | 'span'; // 默认'img',取值「'img'|'div'|'video'|'li'|'a'|'i'|'span'」
    thumb?: string; // 缩略图地址 传了就会先展示缩略图
    errorImg?: string; // 原图加载错误的占位图
 *  }
 * @param [name: string]: any; // 其余element属性
 *  <my-img src='https://baidu.com/test.png'/>
 */

export type MyImgDirectiveOptions = {
  src: string
  urlCallback?: (el: Element) => void
}

@Component({ name: 'MyImg' })
export default class MyImg extends Mixins<
  Base<State, Props, ImgEvents, IMGScopedSlots>,
  UnitMixins,
  OssAssets
>(Base, UnitMixins, OssAssets) {
  /**
   * @example <xxx         
   *          {...{
              directives: [
                {
                  name: 'my-img',
                  value: {
                    src: '/lobby_asset/{layout}-{bg}-{skin}/common/common/bg_pattern_tile.png'
                    // 内部默认逻辑为赋值为backgroudImage 不加important属性，如果需要其它操作，传入urlCallback处理
                    // urlCallback: (url: string, el: HTMLElement) => {
                    //   el.style.backgroundImage = `url(${url})!important`
                    // }
                  }
                }
              ]
            }}/>
   */
  static Directive: DirectiveOptions = {
    // 指令的定义
    bind: function (el, binding) {
      const src = assetsPath(binding.value.src || '')
      const img = ((el as unknown as { Image: Image }).Image = new Image())
      const urlCallback = (binding.value.urlCallback = (
        url: string,
        el: HTMLElement
      ) => {
        if (el.tagName === 'IMG') {
          ;(el as HTMLImageElement).src = url
        } else {
          el.style.backgroundImage = `url(${url})`
        }
      })
      const retryConfig = (() => {
        const { ossSkinOrigin, ossDomain, ossCommonDomain } = useMainStore()
        const ossCommonAssetsHost = ossCommonDomain.find((domain) =>
          (src || '').startsWith(domain)
        )

        const ossAssetsHost = ossDomain.find((domain) =>
          (src || '').startsWith(domain)
        )

        const ossLobbyAssetPathOrigin = ossSkinOrigin.find((domain) =>
          (src || '').startsWith(domain)
        )
        /**
         * 如果是公共桶返回公共桶域名列表
         */
        if (ossCommonAssetsHost)
          return {
            src: ossCommonAssetsHost,
            list: domainRetryListWrap('ossCommonDomain'),
            retry: createOssCommonDomainRetry
          }
        /**
         * 如果是站点桶皮肤资源
         */
        if (ossLobbyAssetPathOrigin)
          return {
            src: ossLobbyAssetPathOrigin,
            list: domainRetryListWrap('assetsPathOrigin'),
            retry: createOssAssetsPathOrigialRetry
          }

        /**
         * 如果是站点桶普通资源
         */
        if (ossAssetsHost)
          return {
            src: ossAssetsHost,
            list: domainRetryListWrap('ossDomain'),
            retry: createOssDomainRetry
          }

        /**
         * 其它域名资源
         */
        return {
          src: '',
          list: [''],
          retry: (promiseFn: (domain: string) => Promise<string>) =>
            retryMap([''], promiseFn)
        }
      })()

      const retry = retryConfig.retry

      retry((domain) => {
        return new Promise((resolve, reject) => {
          const target = domain
          let loadSrc = target ? src.replace(retryConfig.src, target) : src
          urlCallback(loadSrc, el)
          img.src = loadSrc
          if (img.complete) {
            resolve(loadSrc)
          } else {
            img.onload = () => {
              resolve(loadSrc)
            }
            img.onerror = () => {
              /**
               * 美术资源如果avif和webp失败时用png兜底
               */
              if (
                /\/lobby_asset\/[\S]+/.test(img.src) &&
                /\.(avif|webp)(\?.*)?$/.test(img.src)
              ) {
                loadSrc = loadSrc.replace(/\.(avif|webp)(\?.*)?$/, `.png$2`)
                urlCallback(loadSrc, el)
                img.src = loadSrc
              } else {
                reject()
              }
            }
          }
        })
      })
        .then((loadSrc) => {
          urlCallback(loadSrc, el)
          ;(el as unknown as { Image: Image }).Image = null
        })
        .catch(() => {
          ;(el as unknown as { Image: Image }).Image = null
        })
    }
  }
  public static urlCache: Array<{
    realSrc: string
    renderSrc: string
  }> = []
  private groupsId = `my-img-${v4()}`
  private Image: Image = null
  state: State = {
    imgSrc: '',
    loadError: false,
    status: 'wait'
  }
  @Prop({ required: true })
  private readonly src!: Props['src']

  @Prop()
  private readonly width!: Props['width']

  @Prop()
  private readonly height!: Props['height']

  @Prop()
  private readonly options!: Props['options']

  @Prop({ type: Boolean, default: false })
  private readonly useSkeleton!: NonNullable<Props['useSkeleton']>

  @Ref()
  protected readonly imgRef!: HTMLElement

  /**
   * 用于同步swiper被克隆的节点最终的src属性
   */
  @Prop()
  private readonly syncGroupsWrapId!: NonNullable<Props['syncGroupsWrapId']>

  @Prop({
    default: false
  })
  private readonly syncGroupsSrc!: Props['syncGroupsSrc']

  /**
   * 需要检测的目标资源路径
   */
  protected get ossTargetSrc() {
    return this._src || ''
  }

  /**
   * 传入的原始值
   */
  private get realSrc() {
    return this.src || placeholder
  }

  /**
   * 当前渲染的src
   */
  private get renderSrc() {
    return this.state.imgSrc
  }

  /**
   * url 特殊处理 比如加 query参数以命中 sw 缓存分组策略
   */
  private get _src(): string {
    let src = assetsPath(this.realSrc || '')
    try {
      const swRuntimeCacheName =
        this._options.swRuntimeCacheName ||
        (/\.(png|jpe?g)\?t=\d{10}$/.test(src) ? 'web-lobby-runtime' : '')

      //如果是美术资源文件夹下的图且是png格式，就替换成当前客户端支持的格式
      if (/\/lobby_asset\/[\S]+/.test(src) && /\.(png)(\?.*)?$/.test(src)) {
        src = src.replace(
          /\.png(\?.*)?$/,
          `.${(() => {
            if (window.imgSupport.avif) {
              return 'avif'
            } else if (window.imgSupport.webp) {
              return 'webp'
            } else {
              return 'png'
            }
          })()}$1`
        )
      }

      return swRuntimeCacheName
        ? urlFormatter(src, {
            swRuntimeCacheName
          })
        : src
    } catch (error) {
      return src
    }
  }

  /**
   * 合并初始设置和传入的配置
   */
  private get _options() {
    const thumb = this.options?.thumb || placeholder
    return {
      thumb,
      ...BaseOptions,
      ...this.options
    }
  }

  @Watch('src')
  protected onSrcChanged() {
    this.initImg()
  }

  isImmediate() {
    return this._options.immediate
  }

  doCache(loadSrc: string) {
    const cache = MyImg.urlCache.find((it) => it.realSrc === this.realSrc)
    if (cache) {
      cache.renderSrc = loadSrc
    } else {
      if (MyImg.urlCache.length > 1000) {
        MyImg.urlCache.shift()
      }
      MyImg.urlCache.push({
        realSrc: this.realSrc,
        renderSrc: loadSrc
      })
    }
  }

  created() {
    /**
     * 开启了直接加载的src直接赋值
     */
    if (this.isImmediate()) {
      this.initImg(true)
    }
  }

  mounted() {
    if (!this.isImmediate()) {
      this.initImg()
    }
  }

  beforeDestroy() {
    this.doDestroy()
  }

  private doDestroy() {
    // 如果组件在加载完之前被卸载了需要解除绑定onload事件,以及定时器,还有无网时等待加载的图片；
    if (this.Image) {
      this.Image.onload = null
    }

    observerInstance.removeComponent(this)
    // this.observer?.unobserve(this.$el)
    // this.observer?.disconnect()
  }

  private initImg(immediate = false) {
    if (immediate) {
      this.setState({
        status: '',
        imgSrc: this.getSrc()
      })
      return
    }
    const run = () =>
      this.setState({
        imgSrc: this.getSrc()
      })

    if (window.wgMock.noLazy) {
      run()
      return
    }

    this._options.lazy ? this.lazyLoad(run) : run()
  }

  private replaceSrcHost(target?: string) {
    target = target || this.retryConfig.list[0]
    return target ? this._src.replace(this.retryConfig.src, target) : this._src
  }

  /**
   * 图片重试配置
   */
  private get retryConfig() {
    /**
     * 如果是公共桶返回公共桶域名列表
     */
    if (this.ossCommonAssetsHost)
      return {
        src: this.ossCommonAssetsHost,
        list: domainRetryListWrap('ossCommonDomain'),
        retry: createOssCommonDomainRetry
      }
    /**
     * 如果是站点桶皮肤资源
     */
    if (this.ossLobbyAssetPathOrigin)
      return {
        src: this.ossLobbyAssetPathOrigin,
        list: domainRetryListWrap('assetsPathOrigin'),
        retry: createOssAssetsPathOrigialRetry
      }

    /**
     * 如果是站点桶普通资源
     */
    if (this.ossAssetsHost)
      return {
        src: this.ossAssetsHost,
        list: domainRetryListWrap('ossDomain'),
        retry: createOssDomainRetry
      }

    /**
     * 其它域名资源
     */
    return {
      list: [''],
      src: '',
      retry: (promiseFn: (domain: string) => Promise<string>) =>
        retryMap([''], promiseFn)
    }
  }
  /** 缓存容器dom,本次事件中所有容器都共用一个父级,以节约性能,当前仅能在swiper中这样搞,其它地方地方如果也要使用dom得注意冲突 */
  public static containerDom: Record<string, HTMLElement> = {}
  /** 是否已经同步过(每个img只会进行一次同步) */
  private isSynced = false
  /** 增加swiper这种clone节点后的dom同步操作 */
  private async syncSrcWithGroupsId(loadSrc: string) {
    try {
      // 已经同步过了,则不再进行同步
      if (this.isSynced === true) {
        return
      }
      /** 取得包裹的dom节点,仅从该节点下进行搜索,节省性能. */
      const getWrapDom = () => {
        /** 包裹的dom */
        let wrapDom: HTMLElement = document.body
        const wrapId = this.syncGroupsWrapId
        if (wrapId) {
          if (!MyImg.containerDom[wrapId]) {
            // 加一个保险,万一函数没有找到body
            MyImg.containerDom[wrapId] =
              document.querySelector(wrapId) || document.body
            setTimeout(() => {
              MyImg.containerDom[wrapId] = undefined as unknown as HTMLElement
            }, 0)
          }
          wrapDom = MyImg.containerDom[wrapId]
        }
        return wrapDom
      }
      if (this.syncGroupsSrc) {
        await Promise.resolve()
        const syncDoms = getWrapDom().querySelectorAll(
          `[data-groups-id='${this.groupsId}']`
        )
        if (syncDoms.length > 1) {
          for (let index = 0; index < syncDoms.length; index++) {
            const element = syncDoms[index] as HTMLElement
            if (element === this.$el) continue
            element.setAttribute('data-status', 'loaded')
            element.setAttribute('data-synced', 'true')
            if (!this.useBackground) {
              element.setAttribute('src', loadSrc)
            } else {
              element.style.backgroundImage = `url(${loadSrc})`
            }
          }
        }
        this.isSynced = true
      }
    } catch (error) {}
  }
  /**
   * 下载原图
   */
  private loadingOriginal(): string {
    /**
     * 记录当前传的src属性
     */
    const realSrc = this.realSrc

    const done = (loadSrc: string, realSrc: string) => {
      /**
       * 如果赋值时src重新赋值了变化了就结束
       */
      if (this.realSrc !== realSrc) return ''
      this.doDestroy()
      this.setState({
        imgSrc: loadSrc,
        status: 'loaded'
      })
      this.syncSrcWithGroupsId(loadSrc)
      this.$emit('load', loadSrc)
      this.doCache(loadSrc)
      // 向上抛出当前渲染的结果(占位符和成功)
      if (this.realSrc === placeholder) {
        this.$emit('loadedResult', ImgLoadedResult.Empty)
      } else {
        this.$emit('loadedResult', ImgLoadedResult.Success)
      }
      return loadSrc
    }
    const fail = (realSrc: string) => {
      /**
       * 如果赋值时renderSrc已经变化了就结束
       */
      if (this.realSrc !== realSrc) return
      this.setState({
        status: 'error',
        loadError: true,
        ...(this._options.errorImg
          ? {
              imgSrc: this._options.errorImg
            }
          : {
              imgSrc: placeholder
            })
      })
      this.doDestroy()
      // 向上抛出当前渲染的结果(错误)
      this.$emit('loadedResult', ImgLoadedResult.Error)
    }
    const load = () => {
      const retry = this.retryConfig.retry
      retry((domain) => {
        return new Promise((resolve, reject) => {
          const target = domain
          let loadSrc = target ? this.replaceSrcHost(target) : this._src
          /**
           * 未配置缩略图的话直接赋值当前值上去，以触发浏览器的逐帧渲染
           */

          // if (!this.getThumb()) {
          //   this.setState({
          //     imgSrc: loadSrc
          //   })
          // }
          this.Image = new Image()
          this.Image.src = loadSrc
          if (this.Image.complete) {
            resolve(loadSrc)
          } else {
            this.setState({
              status: 'loading'
            })
            this.Image.onload = () => {
              resolve(loadSrc)
            }
            this.Image.onerror = () => {
              /**
               * 美术资源如果avif和webp失败时用png兜底
               */
              const errorImg = this.Image?.src || ''
              if (
                this.Image &&
                /\/lobby_asset\/[\S]+/.test(errorImg) &&
                /\.(avif|webp)(\?.*)?$/.test(errorImg)
              ) {
                loadSrc = errorImg.replace(/\.(avif|webp)(\?.*)?$/, `.png$2`)
                this.Image.src = loadSrc
              } else {
                reject()
              }
            }
          }
        })
      })
        .then((loadSrc) => {
          done(loadSrc, realSrc)
        })
        .catch(() => {
          fail(realSrc)
        })
    }
    // if (!realSrc) {
    //   return done(realSrc, realSrc)
    // }
    const cache = MyImg.urlCache.find((it) => it.realSrc === this.realSrc)
    /**
     * 有缓存的情况下尝试直接用缓存赋值
     */
    if (cache?.realSrc && cache.renderSrc) {
      this.Image = new Image()
      this.Image.src = cache.renderSrc
      /**
       * 句柄存在时直接赋值，否则正常下载，失败时走首次下载逻辑
       */
      if (this.Image.complete) {
        return done(cache.renderSrc, realSrc)
      } else {
        this.Image.onload = () => {
          done(cache.renderSrc, realSrc)
        }
        this.Image.onerror = () => {
          load()
        }
      }
    } else {
      /**
       * 走首次下载逻辑
       */
      load()
    }
    return ''
  }

  private lazyLoad(callback: () => void) {
    if (!window.IntersectionObserver) {
      callback()
      return
    }
    observerInstance.addLazyBox(this, { callback })
  }

  private getSrc(): State['imgSrc'] {
    if (this._options.type === 'video') {
      this.setState({
        status: ''
      })
      return this.replaceSrcHost()
    }
    // 尝试获取原图
    const src = this.loadingOriginal()
    /**
     * 直接能拿到原图值 说明缓存过 就直接显示
     */
    if (src) {
      return src
      /**
       * 首次下载
       */
    } else {
      if (this._options.thumb) {
        return this.getThumb()
      }
    }
    return this.replaceSrcHost()
  }

  /**
   * 获取缩略图
   */
  private getThumb() {
    return this._options.thumb || ''
  }

  private get useBackground() {
    return !['img', 'video'].includes((this._options?.type as string) || '')
  }

  /**
   * 统一计算样式
   */
  private get targetStyle() {
    const src = this.renderSrc
    const width = this.width
      ? this.width / this.baseUnit + this.unit
      : this.width
    const height = this.height
      ? this.height / this.baseUnit + this.unit
      : this.height

    return {
      width,
      height,
      ...(this.useBackground
        ? {
            backgroundPosition: '0 0',
            backgroundImage: `url(${src})`,
            backgroundSize: '100% 100%'
          }
        : {})
    } as StyleValue
  }

  render() {
    const src = this.renderSrc
    const type = this._options.type
    const renderChild =
      !this._options.performance ||
      (this._options.performance && this.state.status !== 'wait') ||
      window.wgMock.noLazy

    const jsxAttrs = {
      ref: 'imgRef',
      class: [
        style.main,
        this.useSkeleton ? style.useSkeleton : '',
        this.useSkeleton ? 'use-skeleton' : ''
      ],
      style: this.targetStyle,
      attrs: {
        'data-groups-id': this.groupsId,
        'data-src-render': src,
        ...(!this.useBackground
          ? {
              src,
              alt: '.'
            }
          : {}),
        'data-blur': this._options.blurAnimation ? 1 : 0,
        'data-status': this.state.status,
        ...(this.state.loadError
          ? {
              'data-src-real': this.realSrc
            }
          : {})
      },
      on: {
        click: (e: Event) => this.$emit('click', e)
      }
    }

    if (type === 'img') {
      return <img {...jsxAttrs} />
    } else {
      if (type === 'video') {
        return (
          <video {...jsxAttrs} autoplay>
            {renderChild &&
              (this.$slots?.default || this.$scopedSlots.default?.())}
          </video>
        )
      }
      return (
        <type {...jsxAttrs}>
          {this.state.loadError && this.$slots?.errorImg}
          {renderChild &&
            (this.$slots?.default || this.$scopedSlots.default?.())}
        </type>
      )
    }
  }
}
