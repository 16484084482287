import { WebPushInfos } from '@/api/common/type'
import { apiGetWebPush } from '@/api/common'
import { apiUpdateUserInfo } from '@/api/center/setting'
import { loadScripts } from '@/utils/Loader'
import { useMainStore } from '@/store/index'
window.webPushLog = ['----']
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const detectInfo = (params: any[]) => {
  // 若要进行检测,此处就填true,不进行检测了就填否
  const isDetect = true
  if (isDetect) {
    window.webPushLog.push(params)
    setTimeout(() => {
      // 每五分钟,清空一次缓存的信息
      window.webPushLog = ['----']
    }, 1000 * 60 * 5)
  }
}

// 极光推送接口数据
// const ApiWebPushData: WebPushData[] = []
export class WebPush {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  private static instance: WebPush
  /** 获得实例 */
  public static getInstance() {
    if (!this.instance) {
      this.instance = new WebPush()
    }
    return this.instance
  }

  /** 应用唯一标识,相当于后台配置的webKey */
  public appkey = ''
  /** 用户的唯一标识,有些特定的推送,专门推送给特定的人,需要用到它 */
  private user_str = ''
  // 重连次数
  private repeatTimes = 5

  /** 初始化极光推送 */
  public async init() {
    detectInfo(['极光推送开始的地方'])
    if (!this.appkey) {
      return
    }
    // 将用户名转换成字符串,undefined也会转成'undefined',用于表示所有未登录的用户
    this.user_str = String(useMainStore()?.userInfos?.username)
    this.loadJs()
  }

  /** 设置配置,主要是appkey */
  public async setConfig(pushData?: WebPushInfos) {
    if (!pushData) {
      /** 当前极光推送数据 */
      const response = await apiGetWebPush()
      pushData = response?.data?.data || ({} as WebPushInfos)
    }
    detectInfo(['接收到的后台数据', pushData])
    this.appkey = pushData.webKey
  }

  /** 加载极光js */
  private loadJs() {
    loadScripts(
      [
        {
          id: 'gt',
          src: '/libs/webPush@2.1.0/webPushSdk.min.2.1.0.js'
        }
      ],
      false,
      false
    )
      .then(() => {
        //默认 "/sw.min." + sdkEnv.version + ".js"
        const swUrl =
          window.location.origin + '/libs/webPush@2.1.0/sw.min.2.1.0.js'
        // 极光通道断开连接时的回调
        window.MTpushInterface.mtPush.onDisconnect(() => {
          this.repeatTimes--
          detectInfo(['断开了链接!'])
          if (this.repeatTimes > 0) {
            this.loadJs()
          }
        })
        //得到推送消息(web推送，浏览器厂商通道)
        window.MTpushInterface.onMsgReceive((msgData) => {
          //msgData数据结构{data:{xxx},type:0} type:0是极光通道，1是系统通道
          detectInfo(['得到推送消息', msgData])
        })
        detectInfo(['this.user_str', this.user_str])
        //  Push initialization
        window.MTpushInterface.init({
          // report_url: 'xxx/v3/report', //统计上报地址 必填
          swUrl,
          appkey: this.appkey,
          user_str: this.user_str,
          // baseUrl: 'www.engagelab.com', //服务器域名如果不存在则用打包时注入的地址
          fail(data) {
            detectInfo(['在线推送创建失败', data])
          },
          success(data) {
            detectInfo(['在线推送创建成功', data])
          },
          webPushcallback(code, tip) {
            detectInfo(['用户得到的状态码及提示,code,tip', code, tip])
          },
          canGetInfo(data) {
            //此时可以得到 RegId 也可以在data里面取到所有的数据
            try {
              detectInfo([
                '得到RegId',
                window.MTpushInterface.getRegistrationID()
              ])
              detectInfo(['得到接入成功后的更多信息', data])
              // 存在regid,并且是已登录状态,才往后台传输regid
              if (data.regid && useMainStore().hasLogined) {
                detectInfo(['当前已登录,往后台传输regId', data.regid])
                const regid = data.regid
                // 这个极光id的保存,并不是需要那么及时性,所以增加一个延迟,
                //TODO66 后面再找产品开单子,看是否能让后台返回当前用户的极光ID,进对比,如果id相同,就不再更新这个id了.
                setTimeout(() => {
                  apiUpdateUserInfo({ jpushWebId: regid })
                }, 5000)
              }
              // MTpushInterface.setTagsAlias({ tags: ["test1", "test2"], alias: "swefgwwefwfwfwf" });
            } catch (error) {
              console.error('Notification error---', error)
            }
          },
          custom: () => {
            detectInfo(['custom方法执行了'])
          }
        })
      })
      .catch(() => {
        detectInfo(['初始化失败'])
      })
  }
}
