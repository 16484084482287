import { AccountType } from '@/api/common/type'
import {
  GlobalConst,
  GlobalEvents,
  GlobalManager,
  GlobalService
} from '@/context'
import { SystemInfos } from '@/api/common/type'
import { WithGeeGuard } from './GeeTest'
import {
  apiCheckUsername,
  apiFastLogin,
  apiGetFastLogin,
  apiGetHomeGold,
  apiLogin,
  apiLogout,
  apiRegister,
  apiRreRegisterVerify,
  apiSMSLogin,
  apiThirdWayLogin,
  apiThirdWayRegister
} from '@/api/common'
import { checkPasswordStrength } from '@/utils/PasswordStrength'
import { delay } from '@/utils/Tool'
import { merge } from 'lodash'
import { onAppEvent } from '@/utils/business-utils'
import { useActivityChestStore } from '@/views/task/store/activityChest'
import { useGameStore } from '@/views/game/store'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import type {
  CurrencyInfos,
  NormalLoginFormData,
  NormalRegisterRequest,
  SMSLoginReq,
  ThirdWayLoginNewAccountRes,
  ThirdWayLoginReq,
  UserInfos
} from '@/api/common/type'
import type { GeeHeaders } from './GeeTest'
// TODO66 标记日期3月3号 弹窗版的任务已经弃用,替换成了页面版的,使用的地方暂时全都注释
// import { useTaskModalStore } from '@/views/global-modal/modal/tasks-modal-dialog/store/task-modal-store'
import { getCurrentDevice } from '@/utils/Device'
import { useFirstChargeConstantStore } from '@/views/global-modal/modal/first-charge-constant/store'
import { useTaskModalConstant } from '@/views/global-modal/modal/tasks-modal-dialog/store/task-modal-constant'
import { useTaskPageStore } from '@/views/task/store/taskPageStore'
import { useTokenStore } from '@/store/token'
import { windowConfig } from '@/utils/window'
import Modal from '@/controller/Modal'
import Site from './Site'
import autoModalManager from '@/context/autoModalManager'
import to from 'await-to-js'
const device = getCurrentDevice()
const isH5Pwa = device.isH5Pwa()

export type LoginType =
  | 'normal'
  | 'fastlogin'
  | 'trymode'
  | 'sms'
  | 'thirdlogin'

/** 网络类型定义 */
enum NetworkType {
  WIFI = 'wifi',
  CELLULAR = 'cellular',
  NONE = 'none',
  UNKNOWN = 'unknown'
}

type EffectiveType = '2g' | '3g' | '4g' | 'unknown'

interface NetworkInfo {
  type: NetworkType
  effectiveType?: EffectiveType // 只有在 type 为 CELLULAR 时有效
}
/** 系统类型定义 */
interface OSRegex {
  regex: RegExp
  versionRegex?: RegExp
  name: string
}
export default class User {
  /** 获取当前运行环境是否是桌面快捷方式*/
  public static get getIsPwaParams() {
    if (isH5Pwa) {
      return { isPwa: 1 }
    }
    return {}
  }
  /** 类型映射 */
  private static OS_REGEXES: OSRegex[] = [
    { regex: /Windows NT 10.0/, name: 'Windows 10' },
    { regex: /Windows NT 6.3/, name: 'Windows 8.1' },
    { regex: /Windows NT 6.2/, name: 'Windows 8' },
    { regex: /Windows NT 6.1/, name: 'Windows 7' },
    {
      regex: /Mac OS X/,
      versionRegex: /Mac OS X (\d+[\._]\d+)/,
      name: 'MacOS'
    },
    { regex: /iPhone|iPad|iPod/, versionRegex: /OS (\d+[\_]\d+)/, name: 'iOS' },
    { regex: /Android/, versionRegex: /Android (\d+\.\d+)/, name: 'Android' }
  ]
  public static get createLoginBasePayload() {
    const { osType, uuid } = useMainStore()
    return {
      clientType: 5,
      jpush_id: '',
      loginId: uuid,
      /**
       * 该os_type字段影响后续此用户关联的系统，关联很多后面的如活动此系统是否可以领取等问题
       */
      os_type: (() => {
        if (windowConfig.package.isLite) {
          return windowConfig.speedPackageConfig?.osType
        }
        return osType
      })(),
      /**
       * 新增deviceOsType字段仅仅用于登录注册日志记录使用
       * 极速包环境也需要把登录注册的动作记录为原生类型
       */
      deviceOsType: GlobalService.getDeviceOsType(),
      deviceModel: GlobalService.getDeviceModel(),
      ...Site.getChannelInfo(),
      ...(windowConfig.package.isLite
        ? {
            clientType: 2,
            mtpush_id: windowConfig?.speedPackageConfig?.pushId
          }
        : {}),
      domain: location.origin
    }
  }
  public static get getNetworkType(): NetworkInfo {
    interface ExtendedNetworkInformation extends NetworkInfo {
      effectiveType?: '2g' | '3g' | '4g' | 'unknown'
    }

    interface ExtendedNavigator extends Navigator {
      connection?: ExtendedNetworkInformation
    }

    const myNavigator = navigator as ExtendedNavigator

    let type = NetworkType.UNKNOWN
    let effectiveType: '2g' | '3g' | '4g' | 'unknown' = 'unknown'

    switch (myNavigator?.connection?.type) {
      case 'wifi':
        type = NetworkType.WIFI
        break
      case 'cellular':
        type = NetworkType.CELLULAR
        effectiveType = myNavigator.connection?.effectiveType || 'unknown'
        break
      case 'none':
        type = NetworkType.NONE
        break
      default:
        type = NetworkType.UNKNOWN
        break
    }

    return {
      type,
      effectiveType
    }
  }

  /**
   * 提取用户代理字符串中的版本信息
   * @param userAgent 用户代理字符串，通常是 window.navigator.userAgent
   * @param versionRegex 用于匹配版本信息的正则表达式
   * @returns 如果匹配成功，则返回版本信息字符串；否则返回空字符串
   */
  private static extractVersion(
    userAgent: string,
    versionRegex?: RegExp
  ): string {
    if (!versionRegex) {
      return ''
    }
    const match = versionRegex.exec(userAgent)
    return match ? ` ${match[1].replace('_', '.')}` : ''
  }

  /**
   * 获取操作系统及其版本信息
   * @returns 如果能够识别操作系统及其版本，则返回形如 "Windows 10" 的字符串；
   *          否则返回 "Unknown OS"
   */
  public static get getOSVersion(): string {
    const userAgent = window.navigator.userAgent

    for (const { regex, versionRegex, name } of User.OS_REGEXES) {
      if (regex.test(userAgent)) {
        const version = this.extractVersion(userAgent, versionRegex)
        return name + version
      }
    }

    return 'Unknown OS'
  }
  /** 统一上报login 去重处理 */
  private static logSend() {
    if (sessionStorage.getItem('IndicatorB')) {
      return
    }
    sessionStorage.setItem('IndicatorB', 'true')
    onAppEvent('login')
    GlobalEvents.dispatch({
      type: 'MONITOR_EVENT',
      eventName: 'IndicatorB',
      payload: {}
    })
  }
  /**
   * 登出处理
   */
  public static async logout() {
    const { userInfos, updateUserInfos, networkProtect, initGetredDot } =
      useMainStore()
    /**
     * 登出接口发出后，调用暂停其它请求的方法
     */
    setTimeout(() => {
      networkProtect()
    }, 10)

    try {
      GlobalEvents.dispatch({
        type: 'LOGOUT_BEFORE'
      })
      /**退出登陆需要刷新小气泡接口 */
      initGetredDot(true)
      await apiLogout()
      GlobalEvents.dispatch({
        type: 'LOGOUT_SUCCESS',
        payload: {
          userInfos: {
            username: userInfos?.username
          }
        }
      })
      onAppEvent('logout')
    } finally {
      updateUserInfos(null)

      // useTaskModalStore()
      //   .myReset()
      //   .then(() => {
      //     useTaskModalStore().freshCodeCateReceive()
      //   })
      // 标记日期3月8号 这两个reset是刻意保留的,不要删除,它们用来在退出时,重置未登录状态的用户弹窗状态
      useTaskModalConstant().myRest()
      useFirstChargeConstantStore().myReset()
      GlobalManager.Page.toRoot()
    }
  }
  /**
   * 登出弹框
   */
  public static async logoutModal() {
    const { t } = useI18n()
    const modal = GlobalManager.Modal.create({
      title: t('lobby.common.tips.title'),
      titlePosition: 'center',
      titleType: 'info',
      class: 'my-modal-base-cancel',
      content: t('lobby.header.logoutTips'),
      onCancel: async () => {
        try {
          await User.logout()
          modal.destroy()
          // 销毁弹窗队列
          autoModalManager.destroy()
        } catch (error) {
          // console.error(error)
        }
      },
      cancelText: t('lobby.header.logoutOkText') as string,
      okText: t('lobby.header.logoutCancelText') as string
    })
  }
  /**
   *  普通登录/短信验证登录
   */
  @WithGeeGuard()
  public static async login(
    payload: NormalLoginFormData,
    headers?: GeeHeaders
  ) {
    const [error, loginResponse] = await to(apiLogin(payload, headers))
    if (error) return Promise.reject(error)

    const code = loginResponse?.data?.code
    const userInfo = loginResponse?.data?.data

    /** 登录二步验证 */
    if (
      code === GlobalConst.ServiceCode.SUCCESS &&
      userInfo?.loginVerify &&
      userInfo.loginVerifyInfo?.username
    ) {
      await Modal.close('loginRegisterModal')
      await Modal.close('veriryBindPhone')

      Modal.open('loginAuthModal', {
        formData: { ...payload, username: userInfo.loginVerifyInfo.username },
        loginVerify: userInfo.loginVerify
      })
      return Promise.reject()
    }
    if (userInfo) this.handleLoginSuccess(userInfo, 'normal', payload.userpass)
    return userInfo
  }

  /**
   * 检测用户名是否已注册
   * @param username 用户名
   * @returns 是否已注册
   */
  public static async isRegistered(username: string) {
    const [err, res] = await to(apiCheckUsername({ username }))
    if (err) return false
    return !res.data.data?.available
  }

  @WithGeeGuard()
  public static async smsLogin(payload: SMSLoginReq, headers?: GeeHeaders) {
    const loginResponse = await apiSMSLogin(payload, headers)
    const userInfos = loginResponse?.data?.data
    if (userInfos) {
      this.handleLoginSuccess(userInfos, 'sms')
    }
  }

  /** 校验密码是否符合后台设置的复杂密码 */
  public static validatePassword(password: string): boolean {
    const {
      strongPasswdRequireUppercase,
      strongPasswdRequireLowercase,
      strongPasswdRequireNum,
      strongPasswdRequireSpecial,
      strongPasswdLength
    } = useMainStore()?.systemInfos as SystemInfos

    // 密码长度
    if (password.length < strongPasswdLength) {
      return false
    }
    // 是否包含数字
    if (strongPasswdRequireNum && !/\d/.test(password)) {
      return false
    }
    // 是否包含特殊符号
    if (strongPasswdRequireSpecial && !/[!@#$%^&*_=+-,./?]+/.test(password)) {
      return false
    }
    // 是否包含小写字母
    if (strongPasswdRequireLowercase && !/[a-z]/.test(password)) {
      return false
    }
    // 是否包含大写字母
    if (strongPasswdRequireUppercase && !/[A-Z]/.test(password)) {
      return false
    }
    return true
  }

  /**
   * @description 调用快速登录的接口也需要保留上一次的信息
   */
  private static updateUserinfoByCallFastLogin(userInfos: UserInfos) {
    const { updateUserInfos, userInfos: lastUserInfos } = useMainStore()
    // 获取上一次是否领取渠道彩金的数据
    const lastIsChannelPrize = lastUserInfos?.isChannelPrize ?? 0
    // 上一次从登录接口的返回是否登录过app或者h5的值
    const lastLoginOsType = lastUserInfos?.loginOsType ?? 0
    updateUserInfos(
      {
        ...userInfos,
        // 如果该设备之前已经领取过，那么就算换账号也是领取过
        isChannelPrize: lastIsChannelPrize,
        // 使用上一次的普通登录的是否登录过app的值
        loginOsType: lastLoginOsType
      },
      'fastlogin'
    )
  }

  /** 登录成功回调 */
  private static async handleLoginSuccess(
    userInfos: UserInfos,
    loginType: LoginType,
    userpass?: string
  ) {
    Modal.close('firstChargeConstant')
    const { updateUserInfos, systemInfos, updateBrowserFingerId } =
      useMainStore()
    const { loginPasswdStrengthDetectSwitch, strongPasswdWeakLoginRemind } =
      systemInfos as SystemInfos
    const { t } = useI18n()
    if (userInfos) {
      const score = checkPasswordStrength(userpass ?? '', userInfos.platfromid)
      // 登录成功后如果后台开启了提示并且用户密码为弱密码给出提示
      // 安全校验小于2时为弱密码
      if (
        userInfos.account_type === AccountType.Normal &&
        userInfos.userkey &&
        userpass &&
        loginPasswdStrengthDetectSwitch &&
        strongPasswdWeakLoginRemind &&
        score < 2
      ) {
        Modal.message({
          type: 'warning',
          content: t('lobby.modal.login.passwordHint')
        })
      }
      if (loginType === 'fastlogin') {
        this.updateUserinfoByCallFastLogin(userInfos)
      } else {
        updateUserInfos(userInfos, loginType)
        // 更新设备号
        updateBrowserFingerId(userInfos.deviceFingerprint)
      }
      this.logSend()
      if (loginType === 'fastlogin') {
        // 此处的登录,是刷新页面的登录,此时不能重置数据,不能备掉勾选状态
        useGameStore().getByTemplate()
        // useTaskModalStore().freshCodeCateReceive()
      }
      // 如果是快速登录(刷新页面的场景,不进行重置)
      else {
        useActivityChestStore().$reset()
        useFirstChargeConstantStore().myReset()
        useTaskModalConstant().myRest()
        useTaskPageStore().myReset()
      }
      useGameStore().fetchPlatformExchangeList()
      // ws监听更新用户最新金额
      useMainStore().listenChangeGameGold()
    }
  }

  /**
   * 1.已经登录且开启了记住密码 刷新页面进行快速登录
   * 2. 注册成功后直接通过快速登录进行登录
   * 3. 如果传了userkey 用fastLogin做登录，如果不传userkey则用本地的token直接获取用户信息
   */
  public static async fastLogin(
    payload: { userkey: string; mtpush_id?: string },
    options: {
      retry?: boolean
      noErrorMessage?: boolean
      clearTokenOnError?: boolean
      isRetryMode?: boolean
    } = {
      /**
       * 是否开启重试
       */
      retry: true,
      /**
       * 是否不弹错误提示消息
       */
      noErrorMessage: false,
      /**
       * 失败时是否清理token
       */
      clearTokenOnError: false,
      /**
       * 当次是否是重试
       */
      isRetryMode: false
    }
  ) {
    const reFastLogin = !!payload.userkey
    const {
      retry = false,
      noErrorMessage = true,
      isRetryMode = false
    } = options
    const [error, loginResponse] = await to(
      reFastLogin
        ? apiFastLogin(payload, noErrorMessage || !isRetryMode)
        : apiGetFastLogin(noErrorMessage || !isRetryMode)
    )
    if (error) {
      /**
       * 如果没开启重试模式 或者 开启了重试模式但该次请求为重试请求
       */
      if (!retry || isRetryMode) {
        //重置service里的key，可拦截登录状态发生变化期间的请求
        GlobalService.reset()
        return Promise.reject(error)
      } else {
        await this.fastLogin(payload, {
          ...options,
          isRetryMode: true
        })
      }
    }

    const userInfosResponse = loginResponse?.data?.data
    if (userInfosResponse) {
      const userInfos = reFastLogin
        ? userInfosResponse
        : Object.assign(
            {},
            userInfosResponse,
            useTokenStore().tokenInfos
            //如果接口返回了还是以接口的为准
            // GlobalService.pickTokenInfos({
            //   token: userInfosResponse.session_key,
            //   newjwt: userInfosResponse.jwt_token
            // }) || {}
          )

      this.handleLoginSuccess(userInfos, 'fastlogin')
    }
  }

  public static mockUnLoginedUserInfo(
    config: { tryCurrency?: CurrencyInfos } = {}
  ) {
    const { updateUserInfos, currentTryCurrency } = useMainStore()

    const userInfos = {
      game_gold: 2000,
      account_type: 1,
      platfromid: 'web_lobby_guest_account',
      mode: 0,
      currency:
        config?.tryCurrency?.currencyCode ||
        currentTryCurrency?.currencyCode ||
        ''
    }
    updateUserInfos(userInfos as UserInfos, 'trymode')
  }
  /**
   * 可能直接登录成功，可能未注册就引导注册
   */
  @WithGeeGuard()
  public static async thirdWayLogin(
    payload: ThirdWayLoginReq,
    headers?: GeeHeaders
  ) {
    const { updateUserInfos } = useMainStore()
    const loginResponse = await apiThirdWayLogin(payload, headers)
    const userInfos = loginResponse?.data?.data
    /**
     * code 为 100 时 说明是 新账号 需要引导修改账号密码
     */
    if (userInfos) {
      if ((userInfos as ThirdWayLoginNewAccountRes).code !== 100) {
        updateUserInfos(userInfos as UserInfos, 'thirdlogin')
        // useTaskModalStore()
        //   .myReset()
        //   .then(() => {
        //     useTaskModalStore().freshCodeCateReceive()
        //   })
        useActivityChestStore().$reset()
        useTaskPageStore().myReset()
        useFirstChargeConstantStore().myReset()
        useTaskModalConstant().myRest()
        this.logSend()
      } else {
        return userInfos as ThirdWayLoginNewAccountRes
      }
    }
  }
  /**
   *  三方注册并自动登录
   */
  @WithGeeGuard()
  public static async thirdWayRegister(
    payload: ThirdWayLoginReq,
    headers?: GeeHeaders
  ) {
    const { updateUserInfos } = useMainStore()
    const registerResponse = await apiThirdWayRegister(payload, headers)
    const userInfos = registerResponse?.data?.data
    if (userInfos) {
      updateUserInfos(userInfos, 'thirdlogin')
      // useTaskModalStore()
      //   .myReset()
      //   .then(() => {
      //     useTaskModalStore().freshCodeCateReceive()
      //   })
      useActivityChestStore().$reset()
      useTaskPageStore().myReset()
      useFirstChargeConstantStore().myReset()
      useTaskModalConstant().myRest()
      this.logSend()
    }
  }

  /**
   * 更新货币信息
   * minRequestTime为至少的请求时长
   */
  public static async updatePrize(minRequestTime = 0) {
    const { updateUserInfos } = useMainStore()
    const [prizeResponse] = await Promise.all([
      apiGetHomeGold(),
      delay(minRequestTime)
    ])
    updateUserInfos(
      merge({}, useMainStore().userInfos, {
        auditMode: prizeResponse.data.data?.auditMode,
        bonus: prizeResponse.data.data?.bonus,
        game_gold: prizeResponse.data.data?.game_gold
      })
    )
  }

  /**
   *  普通注册前验证参数
   */
  public static async preRegisterVerify(
    payload: NormalRegisterRequest,
    headers?: GeeHeaders
  ) {
    return await apiRreRegisterVerify(payload, headers)
  }

  /**
   *  普通注册
   */
  @WithGeeGuard()
  public static async register(
    payload: NormalRegisterRequest,
    headers?: GeeHeaders
  ) {
    const registerResponse = await apiRegister(payload, headers)
    const userkey = registerResponse.data.data?.userkey
    if (userkey) {
      await this.fastLogin({ userkey }).catch(() => {
        // eslint-disable-next-line no-empty-function
      })
    }
  }
}
