import { IThirdPartyLoginStrategy } from '../strategies/bases/LoginStrategy'
import { LoginInfos, ThirdWayType } from '../types'
import { ThirdWayRequesterType, ThirdWayStatus } from '@/api/common/type'
import { apiGetSystemStatus } from '@/api/common'
import { apiSecurityBindThirdWay } from '@/api/center/security'
import { pick } from 'lodash'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import { windowConfig } from '@/utils/window'
import BusinessUtils from '@/utils/business-utils'
import FaceBookLoginStrategy from '../strategies/FacebookLogin'
import GoogleLoginStrategy from '../strategies/GoogleLogin'
import LineLoginStrategy from '../strategies/LineLogin'
import Modal from '@/controller/Modal'
import Page from '@/controller/Page'
import QQLoginStrategy from '../strategies/QQLogin'
import User from '@/controller/User'
import WechatLoginStrategy from '../strategies/WechatLogin'

export { ThirdWayType }

/** 策略构造函数接口 */
interface StrategyConstructor {
  new (): IThirdPartyLoginStrategy
  /**
   * 获取实例方法
   */
  getInstance?: () => IThirdPartyLoginStrategy
  /**
   * 是否为单例类
   */
  isSingleton?: boolean
}

class ThirdPartyController {
  private strategies: {
    [key in ThirdWayType]: StrategyConstructor
  }

  /** 构造第三方登录策略 */
  constructor() {
    this.strategies = {
      [ThirdWayType.GOOGLE]: GoogleLoginStrategy,
      [ThirdWayType.FACEBOOK]: FaceBookLoginStrategy,
      [ThirdWayType.LINE]: LineLoginStrategy,
      [ThirdWayType.QQ]: QQLoginStrategy,
      [ThirdWayType.WECHAT]: WechatLoginStrategy
    }
  }

  /** 显示错误窗口，第三方登录遇到错误时调用此方法 */
  private showSrviceModal(): void {
    const { t } = useI18n()
    const modal = Modal.create({
      title: t('lobby.common.tips.title'),
      titlePosition: 'center',
      titleType: 'info',
      closable: true,
      content: t('lobby.modal.login.googleInitError'),
      class: 'googleInitError',
      onOk: async () => {
        try {
          await Page.toCustomerService()
          modal.destroy()
        } catch (error) {
          console.error(error)
        }
      },
      cancelButtonProps: {
        style: {
          display: 'none'
        }
      },
      okText: t('lobby.common.components.auth.contactUS') as string
    })
  }

  /** 映射后端绑定key */
  private get threeWayMap(): Record<ThirdWayType, ThirdWayStatus> {
    return {
      [ThirdWayType.FACEBOOK]: ThirdWayStatus.FACEBOOK,
      [ThirdWayType.GOOGLE]: ThirdWayStatus.GOOGLE,
      [ThirdWayType.WECHAT]: ThirdWayStatus.WECHAT,
      [ThirdWayType.QQ]: ThirdWayStatus.QQ,
      [ThirdWayType.LINE]: ThirdWayStatus.LINE
    }
  }

  /** 处理第三方登录，如果登录成功则将用户引导至注册页面 */
  private async handleLogin(
    mode: ThirdWayType,
    loginInfos: LoginInfos
  ): Promise<void> {
    const requester_type = mode as unknown as ThirdWayRequesterType
    const response = await User.thirdWayLogin({
      access_token: loginInfos.access_token,
      requester_type
    })

    Modal.close('loginRegisterModal')
    if (response) {
      let email = loginInfos?.email
      if (!email) {
        email = response.email
      }
      Modal.openLoginRegisterModal({
        register: true,
        thirdParty: true,
        email,
        id: loginInfos?.id ?? '',
        access_token: loginInfos.access_token,
        requester_type,
        platformId: response.new_username,
        passwd: response.new_password
      })
    }
  }

  /** 处理第三方绑定，如果绑定成功则更新用户主信息，并给出成功信息 */
  private async handleBind(
    mode: ThirdWayType,
    loginInfos: LoginInfos
  ): Promise<void> {
    const { t } = useI18n()
    const requester_type = mode as unknown as ThirdWayRequesterType
    const res = await apiSecurityBindThirdWay({
      access_token: loginInfos.access_token,
      requester_type
    })
    const { updateThirdInfo } = useMainStore()
    const email = loginInfos.email ?? res.data.data?.email ?? ''
    Modal.message({
      type: 'success',
      content: t('lobby.center.security.thirdWay.success')
    })
    updateThirdInfo({
      thirdEmail: BusinessUtils.emailDesensitization(email),
      thirdType: this.threeWayMap[mode]
    })
  }

  /** 根据用户的登录状态处理第三方用户信息。如果用户已登录，则尝试进行第三方绑定。如果用户未登录，则尝试进行登录，并在必要时引导用户进行注册
   * @param mode 第三方登录模式，Google，Facebook，Line等
   * @param loginInfos 用户登陆信息
   */
  public async handleThirdPartyUserInfo(
    mode: ThirdWayType,
    loginInfos: LoginInfos
  ): Promise<void> {
    const { hasLogined } = useMainStore()
    if (hasLogined) {
      // 绑定对应三方
      this.handleBind(mode, loginInfos)
    } else {
      // 可能直接登录成功，未注册就引导注册
      this.handleLogin(mode, loginInfos)
    }
  }

  // 极速包走自己的jsBridge
  private async speedPackageLogin(mode: ThirdWayType) {
    const speedPackageConfig = windowConfig.speedPackageConfig

    if (speedPackageConfig) {
      const payload = {
        osType: speedPackageConfig.osType
      }
      apiGetSystemStatus(payload, {
        customParams: {
          useAppEnv: true
        }
      }).then((res) => {
        const message = JSON.stringify({
          type: mode,
          payload: pick(res.data.data?.homeGetSysInfo || {}, [
            'facebook',
            'google',
            'line'
          ])
        })
        window.jsBridge?.postMessage('ThirdLogin', message)
      })

      return await new Promise((resolve) => {
        window.SpeedPackageThirdLoginCallback = function (actions: {
          type: string
          payload: LoginInfos
        }) {
          resolve(actions.payload)
        }
      })
    }
  }

  /**
   * 处理用户点击第三方登录按钮的事件，会调用相应的第三方登录策略进行登录，并处理登录结果信息
   * @param mode 第三方登录模式，Google，Facebook，Line等
   * @param onStartLoading 显示加载loading。
   * @param onEndLoading 隐藏加载loading。
   */
  public async handleClick(
    mode: ThirdWayType,
    onStartLoading?: () => void,
    onEndLoading?: () => void
  ): Promise<void> {
    try {
      let loginInfos!: LoginInfos

      if (windowConfig.speedPackageConfig) {
        loginInfos = (await this.speedPackageLogin(mode)) as LoginInfos
      } else {
        let strategy: IThirdPartyLoginStrategy

        const StrategyClass = this.strategies[mode]
        if (
          StrategyClass.isSingleton &&
          typeof StrategyClass.getInstance === 'function'
        ) {
          // 如果是单例策略，使用 getInstance 方法获取策略实例
          strategy = StrategyClass.getInstance()
        } else {
          strategy = new this.strategies[mode]()
        }

        onStartLoading?.()
        await strategy.prepare()
        onEndLoading?.()

        loginInfos = (await strategy.logIn()) as LoginInfos
      }

      onStartLoading?.()
      await this.handleThirdPartyUserInfo(mode, loginInfos)
      onEndLoading?.()
    } catch (error) {
      console.error(`三方登录报错: ${error}`)
      this.showSrviceModal()
      onEndLoading?.()
    }
  }
}

export const _instance = new ThirdPartyController()
