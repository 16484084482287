import { AxiosResponse } from 'axios'
import {
  GeetestConfig,
  GeetestValidateResult,
  TGeetestCaptchaResult
} from '../type/geeTest'
import { GeetestValidate } from '@/api/common/type'
import {
  apiLoginAfterGeetestValidate,
  apiLoginBeforeGeetestValidate
} from '@/api/common'
import { merge, pick } from 'lodash'
import { resCodeMessage } from '@/utils/Tool'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import Const from '@/context/const'
import Events from '@/context/events'
import GeeTestHelper from '../helpers/index'
import moment from 'moment'

interface GeetestOptions {
  domId?: string
  geetestConfig: GeetestConfig
}

export default class GeeTest {
  /** 合并传入的选项和默认选项 */
  private static _prepareOptions(options: GeetestOptions): GeetestOptions {
    const defaultOptions: GeetestOptions = {
      geetestConfig: {
        captchaId: '',
        product: 'bind', // 极验验证弹窗方式
        hideBar: false // 默认打开关闭按钮
      },
      domId: 'geetest'
    }
    return { ...defaultOptions, ...options }
  }

  /** 获取当前语言 */
  private static _getLanguage(): string {
    const { language } = useMainStore()
    const lang = Const.GeetestLanguageMap[language]
    if (lang === 'none') {
    }
    return lang
  }

  /** 调用注册接口，获取极验验证码对象 */
  private static async _callRegister(
    options: GeetestOptions
  ): Promise<TGeetestCaptchaResult> {
    return new Promise<TGeetestCaptchaResult>((resolve, reject) => {
      try {
        const config = {
          product: 'bind',
          language: this._getLanguage(),
          onError: this._handleError,
          getType: {
            apiServers: Const.GeetestApiServers
          },
          ...options.geetestConfig
        }
        window?.initGeetest4 && window.initGeetest4(config, resolve)
      } catch (error) {
        this._handleError()
        reject(error)
      }
    })
  }

  /** 将验证按钮插入到宿主页面中指定的DOM元素内 */
  private static _callRenderToBody(
    options: GeetestOptions,
    captchaObj: TGeetestCaptchaResult
  ): void {
    if (options.domId) {
      const geetestDom = document.getElementById(options.domId)

      if (geetestDom) {
        captchaObj.appendTo(`#${options.domId}`)
        return
      }

      const newGeetestDom = document.createElement('div')
      newGeetestDom.id = options.domId
      document.body.append(newGeetestDom)
      captchaObj.appendTo(`#${options.domId}`)
    }
  }

  /**
   * 封装的极验验证方法，用于初始化和处理极验验证过程
   * @param captchaObj 极验验证码对象
   * @returns 返回一个 Promise，用于处理验证结果或错误情况
   */
  private static _setupGeetestValidation(
    captchaObj: TGeetestCaptchaResult
  ): Promise<GeetestValidateResult> {
    const { $patch } = useMainStore()
    return new Promise<GeetestValidateResult>((resolve, reject) => {
      // 错误处理函数
      const handleError = () => {
        captchaObj.destroy()
        reject('Geetest Error')
      }

      captchaObj
        // 当验证码组件准备好时触发
        .onReady(() => {
          captchaObj.showCaptcha()
        })
        // 当验证码验证成功时触发
        .onSuccess(() => {
          resolve(captchaObj.getValidate())
        })
        // 当用户关闭验证码弹窗时触发
        .onClose(handleError)
        // 当发生错误时触发
        .onError((errorInfo) => {
          if (
            errorInfo.error_code === 'error_12' ||
            errorInfo.error_code === 'error_01'
          ) {
            const time = moment().unix()
            $patch({
              geetestRestrictedTime: time
            })
            this.checkGeetestRestricted()
          } else {
            this._handleError(errorInfo)
          }
          handleError()
        })
    })
  }

  /** 处理错误信息 */
  private static _handleError(errorInfo?: {
    desc: string
    msg: string
    code: number
  }) {
    const { t } = useI18n()
    Events.dispatch({
      type: 'RESPONSE_ERROR',
      payload: {
        message: `${t('lobby.common.components.geetest.error')}${JSON.stringify(
          (() => {
            try {
              return pick(errorInfo, ['code', 'lot_number'])
            } catch (error) {
              return {}
            }
          })()
        )}`
      }
    })
  }

  /** 加载极验行为验证 SDK */
  private static async initSDK(): Promise<void> {
    await GeeTestHelper.loadSDK(
      'gt',
      '/libs/gt@4.1.1/gt.js',
      'initGeetest4',
      false,
      false
    )
  }

  /**
   * 检查极验是否操作频繁
   * @param duration - 限制时间（默认为60秒）
   * @returns 是否操作频繁
   */
  private static checkGeetestRestricted(duration = 60): boolean {
    const { geetestRestrictedTime } = useMainStore()
    const { t } = useI18n()
    const diff = moment().unix() - geetestRestrictedTime
    const restricted = diff <= duration
    if (restricted) {
      //操作频繁，请xx秒后再试
      Events.dispatch({
        type: 'RESPONSE_ERROR',
        payload: {
          message: t('lobby.common.errorMessages.GEETEST_RESTRICTED', {
            seconds: duration - diff
          }) as string
        }
      })
    }
    return restricted
  }

  /**
   * 服务端校验极验数据
   * @param payload - 极验验证数据
   * @returns Promise
   */
  private static async validateGeetest(payload: GeetestValidate) {
    const { hasLogined } = useMainStore()
    return hasLogined
      ? apiLoginAfterGeetestValidate(payload)
      : apiLoginBeforeGeetestValidate(payload)
  }

  /**
   * 根据给定选项配置和初始化极验行为验证
   * @param options - 用于配置极验的选项
   * @returns 返回极验验证结果
   */
  private static async initGeetest(
    options: GeetestOptions = { geetestConfig: { captchaId: '' } }
  ): Promise<GeetestValidateResult> {
    options = this._prepareOptions(options)
    // 加载极验行为验证 SDK
    await this.initSDK()

    /** geetest极验加载bug, 先初始化bind后，再初始化popup就会渲染报错，目前解决方案是删除initGeetest4对象,和对应js文件，重新加载一次geetest库 */
    if (options.geetestConfig.product === 'popup' && window.initGeetest4) {
      delete window.initGeetest4
    }

    // 从服务器获取极验配置参数
    const captchaObj = await this._callRegister(options)

    // 将验证码渲染至页面
    this._callRenderToBody(options, captchaObj)

    // 返回极验验证结果
    const result = await this._setupGeetestValidation(captchaObj)

    return merge(result, {
      // 避免极验窘机 bypass 模式不返回 captcha_id
      captcha_id: result?.captcha_id || options.geetestConfig.captchaId
    })
  }

  private static async validate(account: string, captchaId?: string) {
    if (captchaId) {
      if (GeeTest.checkGeetestRestricted()) {
        return Promise.reject()
      }

      const result = await GeeTest.initGeetest({
        geetestConfig: { captchaId }
      })

      if (result) {
        await GeeTest.validateGeetest({
          account,
          ...result,
          captcha_id: result.captcha_id || captchaId
        })
      }
    }
  }

  private static geeTestList() {
    return [
      Const.ServiceCode.FORCE_GEETEST,
      Const.ServiceCode.SWIPER_PUZZLE_GEETEST,
      Const.ServiceCode.GRAPHIC_CLICK_GEETEST,
      Const.ServiceCode.NINE_SQUARE_GEETEST
    ]
  }

  /**
   * @业务逻辑使用 业务内部用于判断是否是公共极验被终止，需要获取这个状态时调用，由于公共处理极验，当业务内部捕获到极验错误，则说明公共极验被终止，可用于判断终止后状态恢复
   * @param error 请求接口返回的错误
   * @returns Boolean
   */
  public static hasAbort(error: unknown) {
    const { code } = resCodeMessage(error)
    return GeeTest.geeTestList().includes(code)
  }

  public static async geeTest(error: AxiosResponse) {
    const { code, msg } = resCodeMessage(error)
    if (GeeTest.geeTestList().includes(code)) {
      try {
        await GeeTest.validate(useMainStore().uuid, msg)
        // 极验成功，走重试接口
        return { retry: true }
      } catch (error) {
        // 极验失败，不需要重试，直接当作接口错误信息返回 Geetest Error
        return { geeTestError: error }
      }
    }
    return {}
  }
}
