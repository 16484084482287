/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import { Cache } from './cache'
import { MD5 } from 'crypto-js'
import { cloneDeep, get, omit, pick } from 'lodash'
import qs from 'qs'
/**
 * 基础网络请求
 */
export abstract class Netable {
  /**
   * 计算请求的唯一标识，用于判断多个请求是否为同一个
   * @param conf
   */
  protected abstract identify(conf: AxiosRequestConfig): string

  /**
   * 判断是否已经有请求发出去了
   * @param id
   * @param conf
   */
  protected abstract has(id: string, conf: AxiosRequestConfig): boolean

  /**
   * 判断是否暂停发送（如果要暂停子类自己维护暂停队列）。子类需要同时重写paused及resume方法
   * @param conf
   * @returns 恢复的事件名
   */
  protected abstract paused(
    conf: AxiosRequestConfig,
    id: string
  ): false | string

  /**
   * 恢复已暂停的请求。子类需要同时重写paused及resume方法
   */
  protected abstract resume(): void

  /**
   * 对原始请求数据做加密
   * @param res
   */
  protected abstract encode(res: AxiosRequestConfig): void

  /**
   * 对响应数据做解密
   * @param res
   */
  protected abstract decode(res: AxiosResponse): void

  /**
   * 响应成功回来
   * @param res
   */
  protected abstract afterResponse(res: AxiosResponse): void

  /**
   * 加密后在发送请求前做一些别的处理，如添加特别的数据到参数或者header中
   * @param conf AxiosRequestConfig
   */
  protected abstract beforeSend(conf: AxiosRequestConfig): void

  /**
   * 解析提取真正的数据体
   * @param res
   */
  protected abstract unpack(res: AxiosResponse): any

  /**
   * 处理异常
   * @param res
   */
  protected abstract catch(res: AxiosResponse | AxiosError): void

  protected abstract checkErrorType(
    res: AxiosResponse | AxiosError
  ): boolean | string

  /**
   * 保存请求队列
   * @param id
   * @param resolve
   * @param reject
   */
  protected abstract queuefy(
    id: string,
    resolve: (v: unknown) => unknown,
    reject: (v: unknown) => unknown
  ): void

  /**
   * 发送请求
   * @param req AxiosRequestConfig
   */
  public abstract request(req: AxiosRequestConfig): Promise<any>
}

export interface NetworkCall {
  [prop: string]: Array<Array<(v: unknown) => unknown>>
}

export abstract class Net extends Netable {
  protected instance
  protected queue: NetworkCall = {}
  protected pauseQueue: Record<
    string,
    {
      url: string
      resolve: (value: unknown) => void
    }
  > = {}
  protected resultMapper: Map<string, unknown> = new Map()

  public cache: Cache = new Cache()

  constructor(instance: AxiosInstance) {
    super()
    this.instance = instance
    // request interceptors
    this.instance.interceptors.request.use((conf: AxiosRequestConfig) => {
      this.setup(conf)
      this.encode(conf)
      this.beforeSend(conf)
      return conf
    })

    // response interceptors
    this.instance.interceptors.response.use(
      (res: AxiosResponse): unknown => {
        this.afterResponse(res)
        this.decode(res)
        if (this.checkErrorType(res)) {
          return this.catch(res)
        }
        return this.unpack(res)
      },
      (e) => {
        return this.catch(e)
      }
    )
  }

  protected identify(conf: AxiosRequestConfig<any>): string {
    const url = conf?._origin_?.url || conf.url
    return MD5(
      `${url}${qs.stringify(conf.data)}${qs.stringify(conf.params)}`
    ).toString()
  }

  protected has(id: string, req: AxiosRequestConfig): boolean {
    return !!this.queue[id] && !!req
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected paused(_conf: AxiosRequestConfig, id: string): false | string {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected resume(...args: unknown[]): void {
    //
  }
  /**
   * 用于恢复队列中的请求
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected resumeHandler(req: AxiosRequestConfig<any>, event: string): void {
    req = req._origin_ || req
    const id = this.identify(req)
    const key = `${id}-${event}`
    this.pauseQueue[key].resolve('')
    delete this.pauseQueue[key]
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected beforeSend(_conf: AxiosRequestConfig): void {
    //
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected afterResponse(res: AxiosResponse<any, any>): void {
    //
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected setup(conf: AxiosRequestConfig): void {
    //
  }

  protected unpack(res: AxiosResponse<any, any>) {
    return res
  }

  protected queuefy(
    id: string,
    resolve: (v: unknown) => unknown,
    reject: (v: unknown) => unknown
  ): void {
    if (!this.queue[id]) {
      this.queue[id] = [[resolve, reject]]
    } else {
      this.queue[id].push([resolve, reject])
    }
  }

  public request(req: AxiosRequestConfig<any>, retryCount = 0): Promise<any> {
    const lastAxiosResponse = req._lastAxiosResponse
    /**
     * 删除循环引用
     */
    if (lastAxiosResponse?.config._lastAxiosResponse) {
      delete lastAxiosResponse.config._lastAxiosResponse
    }
    /**
     * 还原成初始配置
     */
    req = req._origin_ || req
    /**
     * 记录重试次数
     */
    req._retryCount = retryCount
    /**
     * 备份原始请求配置并删除循环引用
     */
    req._origin_ = cloneDeep(omit(req, ['_origin_']))
    /**
     * 记录上次的请求结果
     */
    req._lastAxiosResponse = cloneDeep(lastAxiosResponse)

    let cacheTime = 0
    const cacheDep = req.customParams?.cacheDep || this.cache.dep(req)
    const useCache = req.customParams?.useCache ?? true
    /**
     * 是否使用队列 不合并请求就不用队列
     */
    const useQueuefy = !req.customParams?.noMerge ?? true
    /**
     * 1.传入了cache为true
     * 2.未传cache配置，但命中默认缓存的逻辑
     */
    if (
      req.customParams?.cache === true ||
      (typeof req.customParams?.cache === 'undefined' &&
        this.cache.defaultUse(req))
    ) {
      //传字符串default时设为5分钟
      cacheTime = 1000 * 60 * 5
    } else if (typeof req.customParams?.cache === 'number') {
      cacheTime = Math.max(req.customParams?.cache ?? 0, 0)
    }

    const id = this.identify(req)
    return new Promise((resolve, reject) => {
      const request = () => {
        const success = (res: unknown) => {
          if (useQueuefy) {
            ;(this.queue[id] || []).splice(0).forEach(([a]) => {
              a(res as unknown)
            })
            delete this.queue[id]
          } else {
            resolve(res)
          }
        }
        const fail = (res: unknown) => {
          if (useQueuefy) {
            ;(this.queue[id] || []).splice(0).forEach(([, b]) => {
              b(res)
            })
            delete this.queue[id]
          } else {
            reject(res)
          }
        }

        const doAxios = () => {
          this.instance
            .request(req)
            .then((res) => {
              success(res)
              /**
               * 减少缓存的量
               */
              const raw = {
                ...pick(res, ['data', 'status']),
                config: {
                  ...pick(get(res, 'config'), ['url']),
                  headers: {
                    ...pick(get(res, 'config.headers'), ['x-request-id'])
                  }
                },
                headers: {
                  ...pick(get(res, 'headers'), ['x-trace-id'])
                }
              }
              if (cacheTime) {
                this.cache.set(id, raw, {
                  cacheDep,
                  cacheTime,
                  axiosRequestConfig: req
                })
              } else {
                /** 不缓存时，set最新数据 */
                if (this.cache.has(id, { cacheDep, axiosRequestConfig: req })) {
                  this.cache.set(id, raw, {
                    cacheDep,
                    cacheTime,
                    axiosRequestConfig: req
                  })
                }
              }
            })
            .catch((res) => {
              const needUseCacheInError =
                res?.config?.customParams?.useCacheInError?.(
                  res,
                  this.checkErrorType(res)
                )

              if (
                needUseCacheInError &&
                this.cache.has(id, { cacheDep, axiosRequestConfig: req })
              ) {
                this.cache
                  .get(id, { cacheDep, axiosRequestConfig: req })
                  .then((res) => {
                    success(res)
                  })
                  .catch(() => {
                    fail(res)
                  })
              } else {
                fail(res)
              }
            })
        }
        if (
          useCache &&
          cacheTime > 0 &&
          this.cache.has(id, {
            cacheDep,
            checkExpiration: true,
            axiosRequestConfig: req
          })
        ) {
          this.cache
            .get(id, {
              cacheDep,
              checkExpiration: true,
              axiosRequestConfig: req
            })
            .then((res) => {
              success(res)
            })
            .catch(() => {
              doAxios()
            })
        } else {
          doAxios()
        }
      }
      if (retryCount > 0) {
        request()
        return
      }
      if (useQueuefy) {
        if (this.has(id, req)) {
          this.queuefy(id, resolve, reject)
          return
        }
        this.queuefy(id, resolve, reject)
      }

      const pausedEvent = this.paused(req, id)
      if (pausedEvent) {
        new Promise((resolve) => {
          this.pauseQueue[`${id}-${pausedEvent}`] = {
            url: req.url || '',
            resolve
          }
        }).then(() => {
          request()
        })
      } else {
        request()
      }
    })
  }
}
