import { FinanceWithdrawTabsEnum } from '@/views/finance/withdraw/const'
import { GlobalEvents, GlobalManager } from '@/context'
import { NoticeType } from '@/views/notice/const'
import { TabsEnum as ReportTabsEnum } from '@/views/center/report/config'
import { RouteName } from '@/router/config'
import { TabsEnum } from '@/views/center/security/const'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import Modal from './Modal'

// import router 会导致循环引用
const getRouter = () => {
  return GlobalManager.App.$router
}

/** 跳转提现页面 */
export const navigateToWithdrawPage = (
  current = FinanceWithdrawTabsEnum.WITHDRAW
) => {
  const {
    hasLogined,
    validateConfig: { hasWithdrawPwd }
  } = useMainStore()
  if (!hasWithdrawPwd) {
    const { t } = useI18n()
    if (hasLogined) {
      GlobalManager.Modal.message({
        type: 'warning',
        content: t('lobby.center.security.forceBindWidthdrawPass')
      })
    }
    getRouter().push({
      name: RouteName.SECURITY,
      query: {
        current: TabsEnum.WITHDRAWPASS.toString(),
        isCallbackWithdraw: current.toString()
      }
    })
  } else {
    getRouter().push({
      name: RouteName.WITHDRAW,
      query: {
        current: current.toString()
      }
    })
  }
}

/** 跳转客服页面 */
export const navigateToServicePage = () => {
  getRouter().push({
    name: RouteName.NOTICE,
    query: {
      noticeType: `${NoticeType.SERVICE}`
    }
  })
}
/** 跳转代理页面 */
export const navigateToRomotePage = () => {
  getRouter().push({
    name: RouteName.PROMOTE
  })
}

/** 打开充值弹框 */
export const openPayModal = () => {
  Modal.open('payModal')
}

/** 跳转有奖反馈页面 */
export const navigateToFeedBackPage = () => {
  getRouter().push({
    name: RouteName.NOTICE,
    query: {
      noticeType: `${NoticeType.FEEDBACK}`
    }
  })
}

/** 跳转安全中心页面 */
export const navigateToSecurityPage = () => {
  getRouter().push({
    name: RouteName.SECURITY
  })
}

/** 打开app下载弹框 */
export const openDownloadAppModal = () => {
  GlobalEvents.dispatch({ type: 'download_app' })
}

/** 跳转我的页面 */
export const navigateToMinePage = () => {
  getRouter().push({
    name: RouteName.MINE
  })
}

/** 跳转账户明细页面 */
export const navigateToAccountDetailPage = () => {
  getRouter().push({
    name: RouteName.REPORT,
    query: {
      current: ReportTabsEnum.ACCOUNT_DETAILS
    }
  })
}

/** 跳转投注记录页面 */
export const navigateToBettingRecordPage = () => {
  getRouter().push({
    name: RouteName.REPORT,
    query: {
      current: ReportTabsEnum.BETTING_RECORD
    }
  })
}

/** 跳转个人报表页面 */
export const navigateToPersonalStatementPage = () => {
  getRouter().push({
    name: RouteName.REPORT,
    query: {
      current: ReportTabsEnum.PERSONAL_STATEMENT
    }
  })
}

/** 跳转修改登录密码 */
export const navigateToModifyPasswordPage = () => {
  getRouter().push({
    name: RouteName.SECURITY,
    query: {
      current: '4'
    }
  })
}
