import { GameCategoryIds } from '@/views/game/consts/categorys'
import {
  GeneralButtonColor,
  ImageItem,
  ImportantButtonColor,
  OtherColor
} from '@/api/common/type'
import { GlobalManager } from '@/context'
import { RouteName } from '@/router/config'
import { useMainStore } from '@/store/index'
import Skin from './Skin'

// 游戏图标展示图
const gameCategoryMap = new Map([
  ['hotIcon', GameCategoryIds.Hot], // 热门
  ['chessCardIcon', GameCategoryIds.Chess], // 棋牌类
  ['fishingIcon', GameCategoryIds.Fish], // 捕 鱼
  ['electronicIcon', GameCategoryIds.Electronic], // 电 子
  ['personIcon', GameCategoryIds.Real], // 真 人
  ['sportsIcon', GameCategoryIds.Sports], // 体 育
  ['lotteryIcon', GameCategoryIds.Lottery], // 彩 票
  ['clubIcon', GameCategoryIds.Club], // 俱乐部
  ['friendIcon', GameCategoryIds.ClubFriend], // 朋友局
  ['athleticsIcon', GameCategoryIds.Gaming] // 竞技类别图标
])

/**大厅自定义配置-只针对h5通用版式 */
export default class HallCustomization {
  /**判断当前站点是否是普通版式 这个类里所有的方法都是针对h5的普通版才有效。web ,或者其他版式都无效*/
  private static get isNormalLayout() {
    const { isWeb } = useMainStore()
    const { isClassicStyle } = Skin.currentTheme()
    return isClassicStyle && !isWeb
  }

  /** 个人中心背景 */
  public static get mineBg() {
    const style: Partial<CSSStyleDeclaration> = {}
    if (!HallCustomization.isNormalLayout) return style
    const { homeLayoutInfos } = useMainStore()
    if (homeLayoutInfos && homeLayoutInfos.centerImages?.length > 0) {
      style.backgroundImage = `url(${homeLayoutInfos.centerImages})`
      style.backgroundSize = 'cover'
    }
    return style
  }

  /**大厅背景 */
  public static get hellBg() {
    const { isWeb } = useMainStore()

    const style: Partial<CSSStyleDeclaration> = {}
    //只在H5 game页才设定背景图。
    if (isWeb) return style
    if (GlobalManager.App.$route.name !== RouteName.GAME) return style
    // theme增加一张固定的背景图
    const { isTheme14 } = Skin.currentTheme()
    if (isTheme14) {
      style.backgroundImage =
        '/lobby_asset/{layout}-{bg}-common/common/home/img_db_dt_bg.png'
      style.backgroundSize = '100% 100%'
      style.backgroundPosition = '0px 0px'
      return style
    }
    if (!HallCustomization.isNormalLayout) return style
    const { homeLayoutInfos } = useMainStore()
    if (homeLayoutInfos && homeLayoutInfos.hallLoginImage?.length > 0) {
      style.backgroundImage = homeLayoutInfos.hallLoginImage
      style.backgroundSize = 'cover'
      const cutOffset = homeLayoutInfos.cutOffset
      if (window.outerHeight <= 1920 && cutOffset) {
        //如果低于1920的屏，就要做小屏幕适配。在原背景图设置偏移量
        //cutOffset是后台设置的基于2340高度的偏移量，例如 cutOffset = 234表示小屏幕渲染背景图要从上往下1/10的地方开始
        //客户端分辨率不一定是1080*2340，要根据实际宽度与1080的缩放比例算出实际高度与2340的百分比
        const oldW = 1080,
          oldH = 2340
        //先算出客户端背景图实际渲染宽度与后台设定宽高的缩放比例
        const sclea = window.outerWidth / oldW
        //算出背景图实际渲染出来的高度
        const outerHeight = oldH * sclea
        //在实际高度上算出小屏幕适配从上往下开始的位置
        const Offset = outerHeight * (cutOffset / oldH)

        style.backgroundPositionY = `-${Offset}px`
      }
      return style
    }
  }

  /**底部导航背景图 */
  public static get tabBarBg() {
    const style: Partial<CSSStyleDeclaration> = {}
    if (!HallCustomization.isNormalLayout) return style
    const { homeLayoutInfos } = useMainStore()
    if (homeLayoutInfos && homeLayoutInfos.bottomImages?.length > 0) {
      style.backgroundImage = `url(${homeLayoutInfos.bottomImages})`
      style.backgroundSize = 'contain'
    }
    return style
  }

  /**游戏平台背景图 */
  public static get gameListBg() {
    let src = null
    if (!HallCustomization.isNormalLayout) return src
    const { homeLayoutInfos } = useMainStore()
    if (homeLayoutInfos && homeLayoutInfos.gameImages?.length > 0) {
      src = homeLayoutInfos.gameImages
    }
    return src
  }

  /**游戏列表tab背景图 */
  public static get hellTabsBg() {
    let src = null
    if (!HallCustomization.isNormalLayout) return src
    const { homeLayoutInfos } = useMainStore()
    if (homeLayoutInfos && homeLayoutInfos.gameMenuBasemap?.length > 0) {
      src = homeLayoutInfos.gameMenuBasemap
    }
    return src
  }

  /**激活的游戏列表tab背景图 */
  public static get hellTabsActive(): string | null {
    let src = null
    if (!HallCustomization.isNormalLayout) return src
    const { homeLayoutInfos } = useMainStore()
    if (homeLayoutInfos && homeLayoutInfos.gameMenuSelectBasemap?.length > 0) {
      src = homeLayoutInfos.gameMenuSelectBasemap
    }
    return src
  }

  /**游戏列表tab背景图-大个的 */
  public static get hellBigTabsBg() {
    let src = null
    if (!HallCustomization.isNormalLayout) return src
    const { homeLayoutInfos } = useMainStore()
    if (homeLayoutInfos && homeLayoutInfos.gameMenuBasemap2?.length > 0) {
      src = homeLayoutInfos.gameMenuBasemap2
    }
    return src
  }

  /**激活的游戏列表tab背景图-大个的 */
  public static get hellBigTabsActive() {
    let src = null
    if (!HallCustomization.isNormalLayout) return src
    const { homeLayoutInfos } = useMainStore()
    if (homeLayoutInfos && homeLayoutInfos.gameMenuSelectBasemap2?.length > 0) {
      src = homeLayoutInfos.gameMenuSelectBasemap2
    }
    return src
  }

  /**
   * 首页顶部、中部、底部图标
   * @param type 类型{顶部top、中部middle、底部bottom}
   */
  public static useHomeCustomizeIcon(type: string) {
    const { homeLayoutInfos } = useMainStore()
    const { homeTopImage, homeMiddleImage, homeBottomImage } =
      homeLayoutInfos || {}
    const customizeTopIconMap = new Map()
    const customizeMiddleIconMap = new Map()
    const customizeBottomIconMap = new Map()

    if (!HallCustomization.isNormalLayout) {
      return {
        customizeTopIconMap,
        customizeMiddleIconMap,
        customizeBottomIconMap
      }
    }

    if (type === 'top') {
      homeTopImage?.forEach((e: ImageItem) => {
        customizeTopIconMap.set(e.key, e.value)
      })
    }

    if (type === 'middle') {
      homeMiddleImage?.forEach((e: ImageItem) => {
        customizeMiddleIconMap.set(e.key, e.value)
      })
    }

    if (type === 'bottom') {
      homeBottomImage?.forEach((e: ImageItem) => {
        customizeBottomIconMap.set(e.key, e.value)
      })
    }

    return {
      customizeTopIconMap,
      customizeMiddleIconMap,
      customizeBottomIconMap
    }
  }

  /**
   * 游戏类别图标
   */
  public static useGameShowImage() {
    const { homeLayoutInfos } = useMainStore()
    const { gameShowImage, gameSelectedImage } = homeLayoutInfos || {}
    const gameIconMap = new Map()
    const gameIconSelectMap = new Map()

    if (!HallCustomization.isNormalLayout) {
      return {
        gameIconMap,
        gameIconSelectMap
      }
    }

    // 游戏类别展示图
    gameShowImage?.forEach((e: ImageItem) => {
      const gameCategoryId = gameCategoryMap.get(e.key)
      if (gameCategoryId !== undefined) {
        gameIconMap.set(gameCategoryId, e.value)
      }
    })

    // 游戏类别选中图
    gameSelectedImage?.forEach((e: ImageItem) => {
      const gameCategorySelectId = gameCategoryMap.get(e.key)
      if (gameCategorySelectId !== undefined) {
        gameIconSelectMap.set(gameCategorySelectId, e.value)
      }
    })

    return { gameIconMap, gameIconSelectMap }
  }

  /**
   * 个人中心顶部、中部、列表图标
   * @param type 类型{顶部top、中部middle、列表list}
   */
  public static useOwnerCustomizeIcon(type: string) {
    const { homeLayoutInfos } = useMainStore()
    const { ownerTopImage, ownerMiddleImage, ownerBottomImage } =
      homeLayoutInfos || {}
    const ownerTopIconMap = new Map()
    const ownerMiddleIconMap = new Map()
    const ownerBottomIconMap = new Map()

    if (!HallCustomization.isNormalLayout) {
      return {
        ownerTopIconMap,
        ownerMiddleIconMap,
        ownerBottomIconMap
      }
    }

    if (type === 'top') {
      ownerTopImage?.forEach((e: ImageItem) => {
        ownerTopIconMap.set(e.key, e.value)
      })
    }

    if (type === 'middle') {
      ownerMiddleImage?.forEach((e: ImageItem) => {
        ownerMiddleIconMap.set(e.key, e.value)
      })
    }

    if (type === 'list') {
      ownerBottomImage?.forEach((e: ImageItem) => {
        ownerBottomIconMap.set(e.key, e.value)
      })
    }

    return {
      ownerTopIconMap,
      ownerMiddleIconMap,
      ownerBottomIconMap
    }
  }

  /** 覆盖全局文字颜色 */
  public static setGlobalTextColor() {
    try {
      if (!HallCustomization.isNormalLayout) return
      const { homeLayoutInfos } = useMainStore()
      if (!homeLayoutInfos) return

      const htmlStyle = document.documentElement.style
      // 后端给的三个颜色配置，都是数组
      const {
        hallLoginImageColor,
        hallLoginBasePlateColor,
        importantButtonColor,
        generalButtonColor,
        otherColor
      } = homeLayoutInfos
      //背景色是单独一个配置项 先处理 对应key：bg_1、filter_bg
      if (hallLoginImageColor?.length > 0) {
        htmlStyle.setProperty('--theme-bg-color', hallLoginImageColor)
      }
      //109333新增底板色 对应bg2 ddt_icon
      if (hallLoginBasePlateColor?.length > 0) {
        htmlStyle.setProperty('--theme-main-bg-color', hallLoginBasePlateColor)
        htmlStyle.setProperty(
          '--theme-game-card-ddt_icon',
          hallLoginBasePlateColor
        )
      }

      //前端遍历下转成对象方便取值
      const colorConfig = {
        [ImportantButtonColor.generalColor]: '',
        [ImportantButtonColor.generalCentreColor]: '',
        [ImportantButtonColor.importantColor]: '',
        [GeneralButtonColor.ordinaryColor]: '',
        [GeneralButtonColor.secondaryColor]: '',
        [GeneralButtonColor.weakerColor]: '',
        [OtherColor.leftUnColor]: '',
        [OtherColor.leftOnColor]: '',
        [OtherColor.bottomUnColor]: ''
      }
      if (importantButtonColor?.length > 0) {
        importantButtonColor.forEach((item) => {
          colorConfig[item.key] = item.value
        })
      }

      if (generalButtonColor?.length > 0) {
        generalButtonColor.forEach((item) => {
          colorConfig[item.key] = item.value
        })
      }

      if (otherColor?.length > 0) {
        otherColor.forEach((item) => {
          colorConfig[item.key] = item.value
        })
      }

      //重要按钮/字体颜色
      if (colorConfig[ImportantButtonColor.generalColor].length > 0) {
        htmlStyle.setProperty(
          '--theme-primary-color',
          colorConfig[ImportantButtonColor.generalColor]
        )
        //美术说底部导航栏激活色也跟着主色走
        htmlStyle.setProperty(
          '--theme-active-bg-color',
          colorConfig[ImportantButtonColor.generalColor]
        )
        //美术说活动页头部tab激活色也跟着主色走
        htmlStyle.setProperty(
          '--theme-ant-primary-color-0',
          colorConfig[ImportantButtonColor.generalColor]
        )
      }
      if (colorConfig[ImportantButtonColor.generalCentreColor].length > 0) {
        htmlStyle.setProperty(
          '--theme-primary-font-color',
          colorConfig[ImportantButtonColor.generalCentreColor]
        )
      }
      if (colorConfig[ImportantButtonColor.importantColor].length > 0) {
        htmlStyle.setProperty(
          '--theme-text-color-darken',
          colorConfig[ImportantButtonColor.importantColor]
        )
      }

      //一般按钮/字体颜色
      if (colorConfig[GeneralButtonColor.ordinaryColor].length > 0) {
        htmlStyle.setProperty(
          '--theme-text-color',
          colorConfig[GeneralButtonColor.ordinaryColor]
        )
      }
      if (colorConfig[GeneralButtonColor.secondaryColor].length > 0) {
        htmlStyle.setProperty(
          '--theme-text-color-lighten',
          colorConfig[GeneralButtonColor.secondaryColor]
        )
        //109333待输入字体和次级字体合并。去掉原待输入字体key
        htmlStyle.setProperty(
          '--theme-text-color-placeholder',
          colorConfig[GeneralButtonColor.secondaryColor]
        )
      }
      if (colorConfig[GeneralButtonColor.weakerColor].length > 0) {
        htmlStyle.setProperty(
          '--theme-color-line',
          colorConfig[GeneralButtonColor.weakerColor]
        )
      }

      //其他颜色
      if (colorConfig[OtherColor.leftUnColor].length > 0) {
        htmlStyle.setProperty(
          '--theme-text-color-side-tabs',
          colorConfig[OtherColor.leftUnColor]
        )
      }
      if (colorConfig[OtherColor.leftOnColor].length > 0) {
        htmlStyle.setProperty(
          '--theme-aside-active-font-color',
          colorConfig[OtherColor.leftOnColor]
        )
      }
      if (colorConfig[OtherColor.bottomUnColor].length > 0) {
        htmlStyle.setProperty(
          '--theme-btm-def-color',
          colorConfig[OtherColor.bottomUnColor]
        )
      }
    } catch (e) {
      console.error(e)
    }
  }
}
