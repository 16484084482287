// 全局发布订阅事件车.
import { AppEventType } from '@/utils/business-utils/type'
import { CustomizeNewStyleParams } from '@/api/event/type'
import { GameItemInter } from '@/service/game/entity/gameItem'
import { Language } from '@/i18n'
import { MonitorEventType } from '@/controller/MonitoringAnalysis'
import { SafetyFormData } from '@/views/global-modal/modal/asset-security/type'
import EventEmitter from 'events'
import type { AxiosResponse } from 'axios'
import type { LoginType } from '@/controller/User'
import type { Response } from './network'
import type { UserInfos } from '@/api/common/type'
import type { VueConstructor } from 'vue'

export type SiteMaintainPayload = {
  res?: AxiosResponse<Response>
  isSiteFreeze?: boolean
  ipCheck?: boolean
  isSiteFreezeAndMaintain?: boolean
  useMockMaintainIframe?: boolean
  requestErrorCountConsequent?: number
  onError?: () => void
  onCallback?: () => void
}

export type GlobalEventActions =
  | {
      type: 'LOGIN_SUCCESS'
      payload: {
        userInfos: PartialDeep<UserInfos>
        loginType?: LoginType
      }
    }
  | {
      type: 'LOGOUT_BEFORE'
    }
  | {
      type: 'LOGOUT_SUCCESS'
      payload: {
        userInfos: PartialDeep<UserInfos>
      }
    }
  | {
      type: 'LOADED'
      payload: {
        Vue: VueConstructor<Vue>
      }
    }
  | {
      type: 'HOME_SCROLL'
      payload: {
        event: Event
        x?: string
        y?: string
        mainScrollTo: ({ x, y }: { x?: number; y?: number }) => void
      }
    }
  | {
      type: 'HOME_SCROLL_END'
      payload: {
        event: Event
        x?: string
        y?: string
      }
    }
  | {
      type: 'BETTER_SCROLL'
    }
  | {
      type: 'REFRESH_URL'
      payload: {
        url?: string
        base?: string
        req?: string
        res?: string
        start?: number
        status?: unknown
        headers?: unknown
        message?: string
      }
    }
  | {
      type: 'GAME_LIST_SCROLL'
    }
  | {
      type: 'GAME_LIST_SCROLL_END'
    }
  | {
      type: 'ASIDE_SCROLL'
    }
  | {
      type: 'TOKEN_EXPIRED'
      payload: { message: string }
    }
  | {
      type: 'RESPONSE_ERROR'
      payload: { message: string }
    }
  | {
      type: 'PAGE_VISIBILITY_CHANGE'
      payload: {
        visibility: boolean
      }
    }
  | {
      /** 下载横幅,伸缩动画的执行 */
      type: 'SUGGEST_STRETCH_ANIMATION'
    }
  | {
      /**
       * 客户端数据初始化完成
       */
      type: 'CLIENT_DATA_INITIALIZATION_COMPETED'
    }
  | {
      /**
       * 派发处理ant-design各类弹窗关闭
       */
      type: 'ANTD_POPUP_INVISIBLE'
      payload?: {
        antdCompNames: Array<string>
      }
    }
  | {
      // 当tab切换时,指定首页滚动到指定位置
      type: 'DO_HOME_SCROLL'
      payload?: {
        x?: number
        y?: number
        duration?: number
      }
    }
  | {
      // 当tab切换时,指定游戏列表滚动到指定位置
      type: 'DO_GAME_LIST_SCROLL'
      payload?: {
        x?: number
        y?: number
        duration?: number
      }
    }
  | {
      // 首页,滚动到锚点以前(用于:在滚动到锚点的滚动动效中,暂停滚动引起的tab的切换)
      type: 'HOME_SCROLL_ANCHOR_BEFORE'
    }
  | {
      /**
       * system/status接口加载后
       */
      type: 'SYSTEM_STATUS_LOADED'
    }
  | {
      type: 'TO_HOME'
    }
  | {
      type: 'line'
      payload: {
        code: string
      }
    }
  | {
      type: 'PWA_INSTALL'
      payload?: {
        cb?: () => void
      }
    }
  | {
      type: 'SITE_MAINTAIN'
      payload?: SiteMaintainPayload
    }
  | {
      // 自定义活动预览
      type: GlobalEventActionsTypeEnum.EVENT_CUSTOMIZE_MOCK_PREVIEW
      payload: CustomizeNewStyleParams
    }
  | {
      type: 'download_app'
    }
  | {
      type: 'open_music'
    }
  | {
      type: 'close_music'
    }
  | {
      type: 'VERSION_CHECK'
      payload: {
        version: number
        disabled?: boolean
        force?: boolean
      }
    }
  | {
      type: 'TRACE_REPORT'
      payload: Record<string, unknown>
    }
  | {
      type: 'CLOSE_DROPDOWN'
    }
  | {
      type: 'MONITOR_EVENT'
      eventName: MonitorEventType
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      payload: any
    }
  | {
      type: 'REPORT_MANAGER'
      eventName: MonitorEventType | AppEventType
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      payload: any
    }
  | {
      type: 'CLOSE_LOGIN_AUTH'
    }
  | {
      type: 'ASSET_SECURITY_AUTH'
      payload: SafetyFormData
    }
  | {
      type: 'CONFIG_CHANGE_ERR'
    }
  | {
      type: 'CONFIG_CHANGE_SUCCESS'
    }
  | {
      /**保存桌面快捷方式成功后的事件回调 */
      type: 'SAVE_SHORTCUT_SUCCESSS'
    }
  | {
      /**弹出放开强制的下载APP通过渠道配置 */
      type: 'DOWNLOAD_APP_NOT_REQUIRE_FORCE_BY_CHANNEL'
    }
  | {
      /**弹出限制强制的下载APP通过渠道配置 */
      type: 'DOWNLOAD_APP_REQUIRE_FORCE_BY_CHANNEL'
    }
  | {
      type: 'SECURITY_STEP_NEXT'
    }
  | {
      type: 'DOWNLOAD_APP_BY_AFTER_CLICK_GAME'
    }
  | {
      type: 'LANGUAGE_CHANGE'
      payload: Language
    }
  | {
      type: 'PAYMODAL_OPEN'
    }
  | {
      type: 'PAYMODAL_CLOSE'
    }
  | {
      type: 'PWA_INSTALL_BY_CHANNEL'
    }
  | {
      type: 'PWATOAPP_INSTALL_BY_CHANNEL'
    }
  | {
      type: 'GAME_LOGIN_SUCCESS'
      payload: {
        gameUrl: string
        gameInfo: GameItemInter
      }
    }
  | {
      type: 'DOWNLOAD_APP_CHANNEL_BY_CALL_GAMEAPI'
    }
  | {
      type: 'OTHER_CLIENT_LOGIN'
      payload: {
        token: string
        deviceModel: string
      }
    }

export enum GlobalEventActionsTypeEnum {
  /**下载PWATOAPP通过渠道配置 */
  PWATOAPP_INSTALL_BY_CHANNEL = 'PWATOAPP_INSTALL_BY_CHANNEL',
  /**下载APP弹出框，通过点击三方游戏后弹出 */
  DOWNLOAD_APP_BY_AFTER_CLICK_GAME = 'DOWNLOAD_APP_BY_AFTER_CLICK_GAME',
  CLOSE_DROPDOWN = 'CLOSE_DROPDOWN',
  TRACE_REPORT = 'TRACE_REPORT',
  VERSION_CHECK = 'VERSION_CHECK',
  open_music = 'open_music',
  close_music = 'close_music',
  download_app = 'download_app',
  SITE_MAINTAIN = 'SITE_MAINTAIN',
  /** 安装桌面快捷方式通过渠道*/
  PWA_INSTALL_BY_CHANNEL = 'PWA_INSTALL_BY_CHANNEL',
  /**安装桌面快捷方式 */
  PWA_INSTALL = 'PWA_INSTALL',
  /**弹出放开强制的下载APP通过渠道配置 */
  DOWNLOAD_APP_NOT_REQUIRE_FORCE_BY_CHANNEL = 'DOWNLOAD_APP_NOT_REQUIRE_FORCE_BY_CHANNEL',
  /**弹出限制强制的下载APP通过渠道配置 */
  DOWNLOAD_APP_REQUIRE_FORCE_BY_CHANNEL = 'DOWNLOAD_APP_REQUIRE_FORCE_BY_CHANNEL',
  /**弹出渠道下载APP通过调用后端的登录游戏接口 */
  DOWNLOAD_APP_CHANNEL_BY_CALL_GAMEAPI = 'DOWNLOAD_APP_CHANNEL_BY_CALL_GAMEAPI',
  /**保存桌面快捷方式成功后的事件回调 */
  SAVE_SHORTCUT_SUCCESSS = 'SAVE_SHORTCUT_SUCCESSS',
  line = 'line',
  TO_HOME = 'TO_HOME',
  SYSTEM_STATUS_LOADED = 'SYSTEM_STATUS_LOADED',
  HOME_SCROLL_ANCHOR_BEFORE = 'HOME_SCROLL_ANCHOR_BEFORE',
  DO_GAME_LIST_SCROLL = 'DO_GAME_LIST_SCROLL',
  DO_HOME_SCROLL = 'DO_HOME_SCROLL',
  GAME_LOGIN_SUCCESS = 'GAME_LOGIN_SUCCESS',
  ANTD_POPUP_INVISIBLE = 'ANTD_POPUP_INVISIBLE',
  CLIENT_DATA_INITIALIZATION_COMPETED = 'CLIENT_DATA_INITIALIZATION_COMPETED',
  SUGGEST_STRETCH_ANIMATION = 'SUGGEST_STRETCH_ANIMATION',
  PAGE_VISIBILITY_CHANGE = 'PAGE_VISIBILITY_CHANGE',
  RESPONSE_ERROR = 'RESPONSE_ERROR',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  /** 自定义活动预览 */
  EVENT_CUSTOMIZE_MOCK_PREVIEW = 'EVENT_CUSTOMIZE_MOCK_PREVIEW',
  ASIDE_SCROLL = 'ASIDE_SCROLL',
  GAME_LIST_SCROLL = 'GAME_LIST_SCROLL',
  BETTER_SCROLL = 'BETTER_SCROLL',
  HOME_SCROLL = 'HOME_SCROLL',
  LOADED = 'LOADED',
  LOGOUT_BEFORE = 'LOGOUT_BEFORE',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  SECURITY_STEP_NEXT = 'SECURITY_STEP_NEXT',
  LANGUAGE_CHANGE = 'LANGUAGE_CHANGE',
  PAYMODAL_OPEN = 'PAYMODAL_OPEN',
  PAYMODAL_CLOSE = 'PAYMODAL_CLOSE'
}

export class MyEvent extends EventEmitter {
  constructor() {
    super()
    this.setMaxListeners(50)
  }
  public readonly GlobalEventActionsName = 'global-event-actions-name'
  public dispatch(action: GlobalEventActions) {
    return this.emit(this.GlobalEventActionsName, action)
  }
  public subscribe(callback: (action: GlobalEventActions) => void) {
    return this.on(this.GlobalEventActionsName, callback)
  }
  public subscribeOnce(callback: (action: GlobalEventActions) => void) {
    return this.once(this.GlobalEventActionsName, callback)
  }
  public unsubscribe(callback: (action: GlobalEventActions) => void) {
    return this.off(this.GlobalEventActionsName, callback)
  }
}

export default new MyEvent()
