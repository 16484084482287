import { ThemeComponentType } from './type'

export default {
  [ThemeComponentType.HomePage]: {
    com: () =>
      import(
        /* webpackChunkName: "home@theme=39" */ '@/views/game/themes/theme-39/home'
      )
  },
  [ThemeComponentType.SearchPage]: {
    com: () =>
      import(
        /* webpackChunkName: "search@theme=39" */ '@/views/game/themes/theme-39/search'
      )
  },
  [ThemeComponentType.SubGamePage]: {
    com: () =>
      import(
        /* webpackChunkName: "subGame@theme=39" */ '@/views/game/themes/theme-39/subGame'
      )
  },
  [ThemeComponentType.EmbeddedPage]: {
    com: () =>
      import(
        /* webpackChunkName: "embedded@theme=39" */ '@/views/game/themes/theme-39/embedded'
      )
  },
  [ThemeComponentType.Footer]: {
    com: () =>
      import(
        /* webpackChunkName: "footer@theme=39" */ '@/layouts/components/footer/themes/theme-39'
      )
  },
  [ThemeComponentType.FloatBar]: {
    com: () =>
      import(
        /* webpackChunkName: "floatBar@theme=39" */ '@/layouts/components/floatBar/themes/theme-39'
      )
  },
  [ThemeComponentType.Styles]: {
    com: () =>
      import(
        /* webpackChunkName: "style@theme=39" */ '@/views/global-styles/themes/theme-39'
      )
  },
  [ThemeComponentType.Layouts]: {
    com: () =>
      import(
        /* webpackChunkName: "layout@theme=39" */ '@/layouts/themes/theme-39'
      )
  }
}
