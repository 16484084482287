import { Base, Component, Prop, Watch } from '@/vue-property-decorator'
import { brazilianCurrencyFormatter } from '@/utils/business-utils/finance'
import { createLockFrame } from '@/utils/Tool'
import Animation, { Ease } from '@/utils/Animation'
import AppTypes, { string } from '@/vue-types'
export type Props = {
  value: number | string
  formatter?: (value: number) => string | number
  duration?: number
  ease?: Ease
  prefix?: string
  noAnimation?: boolean
}
type State = {
  value: number
}

export type Slots = {
  renderItem: string
}
/**
 * 调试count-to动画帧率对比
 */
const raf = createLockFrame(18)
@Component<CountTo>({
  name: 'CountTo'
})
export default class CountTo extends Base<State, Props, unknown, Slots> {
  private animation!: Animation
  state = {
    value: 0
  }
  @Prop()
  private readonly value!: Props['value']
  @Prop()
  private readonly noAnimation!: Props['noAnimation']
  @Prop()
  private readonly prefix!: Props['prefix']
  @Prop(AppTypes.number.def(3000))
  private readonly duration!: NonNullable<Props['duration']>
  @Prop(string<Ease>().def('none'))
  private readonly ease!: NonNullable<Props['ease']>
  @Prop(
    AppTypes.func.def((value: number) => {
      return brazilianCurrencyFormatter(value)
    })
  )
  private readonly formatter!: NonNullable<Props['formatter']>

  @Watch('value', {
    immediate: true
  })
  protected onValueChange() {
    const target = {
      value: this.state.value
    }
    if (this.animation) {
      this.animation.kill()
    }
    if (this.noAnimation) {
      this.setState({
        value: Number(this.value)
      })
      return
    }
    /**
     * 2024.05.23 架构建议此处数字跳动不需要使用requestAnimationFrame的动态帧率
     * 改成固定帧率的setTimeout实现以节约性能
     */
    this.animation = new Animation(target, { raf })
      .to(
        {
          value: Number(this.value)
        },
        this.duration,
        this.ease
      )
      .on(Animation.EventType.UPDATE, ({ value }) => {
        this.setState({
          value
        })
      })
      .on(Animation.EventType.COMPLETE, () => {
        this.setState({
          value: Number(this.value)
        })
      })
  }
  render() {
    const result = String(this.formatter?.(this.state.value)) || ''
    return (
      <span class="notranslate">
        {this.prefix && this.$scopedSlots?.renderItem?.(this.prefix)}
        {result.split('').map((char) => {
          return this.$scopedSlots?.renderItem?.(char) || char
        })}
      </span>
    )
  }
}
