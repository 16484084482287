import {
  BindType,
  LanguageMatchMode,
  LinkType,
  SiteConfig,
  SiteInfos,
  SiteURLInfos
} from '@/api/common/type'
import { CURRENT_LANGUAGE_CONFIG } from '@/config/lang.config'
import { FrameStorageType } from '@/views/global-modal/modal/force-popup/types'
import { GameNavigation } from '@/views/game/classes'
import {
  GlobalConst,
  GlobalCrypto,
  GlobalEvents,
  GlobalManager,
  GlobalService
} from '@/context'
import { Language, languageFromStorage, useI18n } from '@/i18n'
import { MessageAllRequest } from '@/api/home/type'
import { PngSprite } from '@/components/icon-sprite'
import { SkinType, nodeThemeConfig } from '@/context/const'
import { THEME_PC } from './mapSkin'
import { ThemeCssVar } from '@/config/theme.config'
import { ValueOf } from 'type-fest'
import { WebPush } from './WebPush/WebPush'
import {
  apiGetAllCurrency,
  apiGetBaseSiteConfig,
  apiGetHeartBeat,
  apiGetIpBindData,
  apiGetLinkSetting,
  apiGetOptimizationSiteConfig,
  apiGetSiteInfo,
  apiGetSiteURLInfos,
  apiGetSkinAssetsHash,
  apiGetSmsCountry,
  apiGetSystemStatus
} from '@/api/common'
import { assetsPath, cssAssetsPathReplace } from '@/utils/business-utils/assets'
import {
  createLobbyMessagesWithIndexDB,
  lobbyMessageWithServer,
  lobbyMessages
} from '@/i18n/map'
import { createOssAssetsPathOrigialRetry } from '@/utils/business-utils/retry'
import { defaultUrlQueryParams } from '@/context/const'
import { get, invert, merge, pick } from 'lodash'
import { getQueryString, getStorage, setStorage } from '@/utils/Tool'
import { getURLParameters } from '@/utils/Route'
import { loadJson } from '@/utils/Loader'
import { mapFieldLocalStorage } from '@/views/global-modal/modal/force-popup/const'
import { trace } from '@/context/tacker'
import { useFooterStore } from '@/layouts/components/footer/store'
import { useHomeStore } from '@/store/home'
import { useMainStore } from '@/store/index'
import { windowConfig } from '@/utils/window'
import BusinessUtils, { styleStringToObject } from '@/utils/business-utils'
import ColorUtils from '@/utils/ColorUtils'
import CryptoJS from 'crypto-js'
import ImageUtils from '@/utils/ImageUtils'
import Modal from './Modal'
import PingManagement from '@/controller/Ping'
import Polling from '@/controller/Polling'
import Skin, { ThemeSkinType as ThemeSkinTypes } from './Skin'
import axios from 'axios'
import moment from 'moment'
import useYuebaoStore from '@/views/yuebao/main/store'

export default class Site {
  /**
   * 获取取到信息（废弃，为了兼容直接写死）
   */
  public static getChannelInfo() {
    return {
      platformType: '1001',
      operationId: 0,
      pkgId: 1
    }
  }

  /**
   * 装载马甲包配置，比如更新uuid值
   */
  public static setupWgPackageConfig() {
    const packageDeviceUUID = windowConfig?.wgPackage?.device || ''
    if (packageDeviceUUID) {
      const { updateDeviceUUID } = useMainStore()
      updateDeviceUUID(packageDeviceUUID)
    }
    if (
      !/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(
        packageDeviceUUID
      )
    ) {
      trace('wg-package-device-error', {
        data: JSON.stringify(windowConfig?.wgPackage)
      })
    }
  }
  /**
   * 初始化站点数据
   */
  public static async init(useInjectData = false) {
    /**
     * 装载马甲包的一些配置
     */
    this.setupWgPackageConfig()
    /**
     * 获取上个版式数据
     */
    BusinessUtils.lastLayout = getStorage('web.lobby.lastLayout')

    /**
     * 获取站点URL配置列表
     */
    await Site.initSiteURLInfos(useInjectData).catch(() => {
      /**
       *
       */
    })

    if (!useInjectData || window?.LOBBY_SITE_CONFIG?.CREATE_BY_STATIC) {
      const res = await apiGetBaseSiteConfig()
      if (res.data?.data) {
        const newConfig = merge(
          {},
          window.LOBBY_SITE_CONFIG || {},
          res.data?.data || {}
        )
        useMainStore().siteConfig = newConfig
        window.LOBBY_SITE_CONFIG = Object.assign({}, newConfig, {
          _backup: window.LOBBY_SITE_CONFIG
        })
      }
    }

    await Promise.all([
      Site.initSiteInfos(useInjectData).catch(() => {
        /**
         *
         */
      }),
      Site.initSystemInfos(useInjectData).catch(() => {
        /**
         *
         */
      }),
      /**
       * 获取首页版式配置
       */
      Site.initHomeLayoutInfos(useInjectData).catch(() => {
        /**
         *
         */
      }),
      /**
       * 获取币种列表信息
       */
      Site.initCurrencyInfos(useInjectData).catch(() => {
        /**
         *
         */
      }),
      /**
       * 获取站点URL配置列表
       */
      Site.initSiteURLInfos(useInjectData).catch(() => {
        /**
         *
         */
      })
    ])

    setTimeout(() => {
      Polling.create({
        key: Polling.LobbyPollingType.HEART_BEAT,
        callback: apiGetHeartBeat.bind(this),
        leading: true,
        interval: 1000 * 90
      })
    }, 1000 * 10)
  }
  /**
   * 获取本机时间 秒时间戳
   */
  public static getTime() {
    return moment().unix()
  }
  /**
   * 获取服务器时间 秒时间戳
   */
  public static getServerTime() {
    const localTime = this.getTime()
    try {
      const mainStore = useMainStore()
      return localTime + mainStore.timeDiff
    } catch (error) {
      return localTime
    }
  }
  /**
   * 依赖变化
   */
  public static async getConfigDependencies(isFirst = false) {
    const { getBrandLogoInfos } = useMainStore()
    const updateTitle = async () => {
      const resonse = await apiGetSiteInfo()
      if (resonse) {
        const siteInfos = resonse.data.data
        const mainStore = useMainStore()
        mainStore.siteInfos = {
          ...mainStore.siteInfos,
          ...pick(siteInfos, ['title'])
        } as SiteInfos
      }
    }
    const updateSystemStatus = async () => {
      const {
        osType,
        updateVipSwitchStatus,
        updateLargeForcedBind,
        updateSystemInfos,
        updateLargeAmountRange
      } = useMainStore()
      const { updateSocialMediaList } = useFooterStore()
      const { updateSwitchData } = useYuebaoStore()
      useMainStore().isSystemStatusLoading = true
      const response = await apiGetSystemStatus({
        osType
      }).finally(() => {
        useMainStore().isSystemStatusLoading = false
        GlobalEvents.dispatch({ type: 'SYSTEM_STATUS_LOADED' })
      })
      const systemStatusData = response?.data?.data

      if (systemStatusData) {
        if (systemStatusData.messageBannerIndex) {
          const { updateBanner } = useHomeStore()
          updateBanner(systemStatusData.messageBannerIndex || [])
        }

        if (systemStatusData.promoteGetSocialmedia) {
          updateSocialMediaList(systemStatusData.promoteGetSocialmedia || [])
        }

        if (systemStatusData.userVipConfig) {
          updateVipSwitchStatus(systemStatusData.userVipConfig)
        }

        if (systemStatusData.yuebaoGetSetting) {
          updateSwitchData(systemStatusData.yuebaoGetSetting)
        }

        if (systemStatusData.homeGetSysInfo) {
          updateSystemInfos(systemStatusData.homeGetSysInfo)
        }

        if (systemStatusData.homeGetSysInfo?.largeAmountMustBind) {
          updateLargeForcedBind(
            systemStatusData.homeGetSysInfo.largeAmountMustBind
          )
        }

        if (systemStatusData.homeGetSysInfo?.largeAmountRange) {
          updateLargeAmountRange(
            systemStatusData.homeGetSysInfo.largeAmountRange
          )
          Site.triggerForcedBinding()
        }
      }
    }
    const updateOptimizationSiteConfig = async () => {
      const response = await apiGetOptimizationSiteConfig({
        previewonly: false
      })
      const optimizationSiteConfig = response?.data.data
      if (optimizationSiteConfig) {
        /**
         * 更新布局字段(服务端调用initHomeLayoutInfos已提前注入布局字段，存在html缓存时间比较久，故客户端这里请求不覆盖服务端数据源)
         */
        // if (optimizationSiteConfig?.layoutDesign) {
        //   const mainStore = useMainStore()
        //   mainStore.homeLayoutInfos = optimizationSiteConfig?.layoutDesign
        // }
        // 一个重点:此函数会在登录时,或登录状态刷新页面时调用,因此极光推送也仅会在登录状态有效
        const webPush = WebPush.getInstance()
        if (optimizationSiteConfig.engageLabWebPush) {
          // 如果极光的appkey还没有配置过appkey,则传入
          if (!webPush.appkey) {
            webPush.setConfig(optimizationSiteConfig.engageLabWebPush)
          }
          webPush.init()
        }
        if (optimizationSiteConfig.brandLogoUse) {
          useMainStore().brandLogoInfos = optimizationSiteConfig.brandLogoUse
        } else {
          /**
           * 品牌设置字段依赖多语言
           */
          getBrandLogoInfos()
        }
        if (optimizationSiteConfig.channelGlobalConfig) {
          useMainStore().channelGlobalConfig =
            optimizationSiteConfig.channelGlobalConfig
        }
      }
    }

    const updateHolidayTheme = async () => {
      const { holidayThemeType } = Skin
      if (holidayThemeType) {
        Site.setHolidyAssets()
        document.documentElement.setAttribute(
          'data-holiday-theme',
          `${holidayThemeType}`
        )
      } else {
        document.documentElement.removeAttribute('data-holiday-theme')
      }
    }

    const update = [
      /**
       * title字段依赖客户端语言参数
       */
      updateTitle(),
      /**
       * 更新系统配置
       */
      updateSystemStatus(),
      /**
       * 更新java系统配置
       */
      updateOptimizationSiteConfig()
    ]

    /**
     * 仅首次才触发的行为
     */
    if (isFirst) {
      update.push(
        /**
         * 更新节日主题
         */
        updateHolidayTheme()
      )
    }
    Promise.all(update)
  }

  /**
   * 更新cssVariables 变量，支持IE
   */
  public static async updateCssVars(variables: Record<string, string>) {
    const isSupported =
      window.CSS && window.CSS.supports && window.CSS.supports('--a', '0')
    if (!isSupported) {
      const { default: cssVars } = await import('css-vars-ponyfill')
      cssVars({
        watch: false,
        variables,
        onlyLegacy: true
      })
    } else {
      const docEle = document.documentElement
      Object.keys(variables).forEach((key) => {
        docEle.style.setProperty(key, variables[key])
      })
    }
  }

  public static async setAntDesignTheme(primaryColor: string) {
    const themeMap = {
      ...ColorUtils.getAntdSerials(primaryColor).reduce((pre, color, i) => {
        pre[`--theme-ant-primary-color-${i}`] = color
        return pre
      }, {} as Record<string, string>),
      '--theme-primary-color': primaryColor
    }
    await Site.updateCssVars(themeMap)
  }
  public static async setSkin(siteInfos: SiteInfos, skinName: SkinType) {
    const { type, backgroundColor, skinTypeValue } = siteInfos
    const docEle = document.documentElement
    const { ThemeSkinType, getCssVar } = nodeThemeConfig
    // DQ、273定制版比较特殊基于欧美版布局
    const skinType = Skin.europeanAmericanSet.has(type)
      ? ThemeSkinType.EUROPEAN_AMERICAN
      : type

    docEle.setAttribute('data-skin-layout', `${skinType}`)
    docEle.setAttribute('data-skin-bg', `${backgroundColor}`)
    docEle.setAttribute('data-skin-id', `${skinTypeValue}`)
    const hasStyle = docEle.getAttribute('style') || ''
    let currentTheme = styleStringToObject(
      docEle.getAttribute('style') || ''
    ) as ThemeCssVar

    const mappingTheme = THEME_PC[type] && useMainStore().isWeb
    let skinConfigInfo = {}

    if (mappingTheme) {
      const values = THEME_PC[type]?.split('-')
      siteInfos.type = Number(values[0]) as ThemeSkinTypes
      siteInfos.backgroundColor = Number(values[1]) as 0 | 1
      siteInfos.skinTypeValue = Number(values[2]) as
        | 1
        | 2
        | 3
        | 4
        | 5
        | 6
        | 7
        | 8

      const result = await loadJson(
        `/node/html-hot-replace/cached/skinColor.json?${this.getTime()}`
      )

      skinConfigInfo = result[`${values[0]}-${values[2]}`] as Record<
        string,
        string
      >

      useMainStore().$patch({
        siteInfos: { ...siteInfos }
      })

      docEle.setAttribute('data-skin-layout', `${siteInfos.type}`)
      docEle.setAttribute('data-skin-bg', `${siteInfos.backgroundColor}`)
      docEle.setAttribute('data-skin-id', `${siteInfos.skinTypeValue}`)
    }

    try {
      if (!hasStyle || mappingTheme) {
        const { baseThemeVar, ThemeVarConfig } = await import(
          '@/../public/node/html-hot-replace/theme-var/index.js'
        )

        currentTheme = getCssVar(skinName, {
          serverColor: mappingTheme
            ? skinConfigInfo
            : useMainStore()?.siteInfos?.skinConfigInfo || {},
          themeVar: {
            baseThemeVar,
            ThemeVarConfig
          }
        }) as ThemeCssVar
      }
    } catch (error) {}

    useMainStore().currentTheme = currentTheme

    const mainStore = useMainStore()
    mainStore.$patch({
      skinName
    })

    Site.updateCssVars(currentTheme as unknown as Record<string, string>)
    Site.setAntDesignTheme(currentTheme['--theme-primary-color'])
  }

  /**
   * 加载svg合图挂载到body
   */
  public static async loadSvgSprite(
    url = `/lobby_asset/{layout}-{bg}-{skin}/sprite.svg`
  ) {
    const mainStore = useMainStore()
    // 开启svg雪碧图骨架动画
    mainStore.$patch({ loadingSvgSprite: true })
    try {
      const response = await createOssAssetsPathOrigialRetry((origin) => {
        return axios.get(assetsPath(url, origin))
      })

      if (response?.data) {
        const div = document.createElement('div')
        /**
         * 简单校验svg资源格式
         */
        if (/^<svg/.test(response.data)) {
          div.innerHTML = response.data
          const $svg = div.childNodes[0] as SVGElement
          Object.assign($svg.style, {
            position: 'absolute',
            width: 0,
            height: 0
          })
          document.body.insertBefore($svg, document.body.firstChild)
        }
      }
    } finally {
      // 关闭svg雪碧图骨架动画
      mainStore.$patch({ loadingSvgSprite: false })
    }
  }

  /**
   * 初始化主题,如果匹配不到则默认蓝白,皮肤名算法直接引用public中的js文件
   * @param siteInfos
   */
  public static async initTheme(siteInfos: SiteInfos) {
    const skinName = nodeThemeConfig.getSkinName(siteInfos)
    await Site.setSkin(siteInfos, skinName)
  }

  /**
   * 链接如果为渠道链接且没有设置语言时，强制走智能匹配模式
   * 智能匹配模式语言优先级：链接 >当前选择语言缓存 （即用户选择的语言）> 系统/浏览器语言 > IP(弃用) > 默认语言
   * 系统默认：链接 >当前选择语言缓存 （即用户选择的语言）> 默认语言
   */
  public static async initI18n(siteInfos: SiteInfos) {
    const config = invert(GlobalConst.ServiceLanguageMap)
    const mainStore = useMainStore()

    /**
     * 服务端当前站点默认语言code
     */
    let siteDefaultServiceLanguageCode = siteInfos.languageInfos.find(
      (it) => it.defaultLanguageTag
    )?.languageCode
    const language = siteInfos.languageInfos.map(
      ({ languageCode }) => config[languageCode]
    ) as unknown as Language[]
    if (
      !siteDefaultServiceLanguageCode ||
      !CURRENT_LANGUAGE_CONFIG.includes(siteDefaultServiceLanguageCode)
    ) {
      //后台设置了其他未开发的语言时，匹配下一个勾选的语言，如果匹配不到默认英文
      const nextServiceLanguageCode =
        siteInfos.languageInfos.find(
          (it) =>
            it.languageCode && CURRENT_LANGUAGE_CONFIG.includes(it.languageCode)
        )?.languageCode || 'en'
      siteDefaultServiceLanguageCode = nextServiceLanguageCode
    }

    /**
     * 当前要设置的语言是否站点后台配置以及前端有支持
     */
    const isSiteLanguageCode = (code: string) =>
      siteInfos.languageInfos.find((i) => i.languageCode === code) &&
      CURRENT_LANGUAGE_CONFIG.includes(code)

    const queryParams = pick(getURLParameters(), ['languageCode', 'cid', 'id'])
    // 根据链接参数设置默认语言（优先级最高）
    const languageCode = (
      queryParams?.languageCode as string
    )?.toLowerCase() as ValueOf<typeof GlobalConst.ServiceLanguageMap>
    if (isSiteLanguageCode(languageCode)) {
      siteDefaultServiceLanguageCode = languageCode
      mainStore.setLanguage(config[siteDefaultServiceLanguageCode] as Language)
      // 本地没有语言缓存继续匹配浏览器语言和ip语言(开启只能匹配模式才会走)
      // 渠道 未设置语言时，强制使用智能匹配
    } else if (
      (!languageFromStorage &&
        mainStore.siteInfos?.languageMatchMode ===
          LanguageMatchMode.SMART_MATCHING) ||
      ((queryParams?.id || queryParams?.cid) && !queryParams?.languageCode)
    ) {
      // 浏览器语言标识设置默认语言
      const browserLanguageCode = navigator.language
      const browserToLocalCode =
        (GlobalConst.BrowserLanguageMap[
          browserLanguageCode as keyof typeof GlobalConst.BrowserLanguageMap
        ] as ValueOf<typeof GlobalConst.ServiceLanguageMap>) ||
        (() => {
          const normalReg = (prefix: string) =>
            new RegExp(`${prefix}(-[A-Z]+)?`, 'i')
          /**
           * 增加系统浏览器语言灵活匹配
           */
          const match = {
            /**
             * en-xx 全命中 GlobalConst.BrowserLanguageMap.en 的值
             */
            [GlobalConst.BrowserLanguageMap.en]: [normalReg('en')],
            [GlobalConst.BrowserLanguageMap.th]: [normalReg('th')],
            [GlobalConst.BrowserLanguageMap.vi]: [normalReg('vi')],
            [GlobalConst.BrowserLanguageMap.ko]: [normalReg('ko')],
            [GlobalConst.BrowserLanguageMap.ja]: [normalReg('ja')],
            [GlobalConst.BrowserLanguageMap.es]: [normalReg('es')],
            [GlobalConst.BrowserLanguageMap.de]: [normalReg('de')],
            [GlobalConst.BrowserLanguageMap.fr]: [normalReg('fr')],
            [GlobalConst.BrowserLanguageMap.it]: [normalReg('it')],
            [GlobalConst.BrowserLanguageMap.hi]: [normalReg('hi')],
            [GlobalConst.BrowserLanguageMap.pt]: [normalReg('pt')],
            [GlobalConst.BrowserLanguageMap.fil]: [normalReg('tl')]
          }
          return Object.entries(match).find(([, rules]) =>
            rules.find((rule) => rule.test(navigator.language))
          )?.[0]
        })()
      // const ipLanguageCode = mainStore?.ipCheck.recommendLanguage as ValueOf<typeof GlobalConst.ServiceLanguageMap>
      if (isSiteLanguageCode(browserToLocalCode)) {
        siteDefaultServiceLanguageCode = browserToLocalCode
        mainStore.setLanguage(
          config[siteDefaultServiceLanguageCode] as Language
        )
      }
      //  else if (isSiteLanguageCode(ipLanguageCode)) {
      //   // 如果浏览器语言匹配不到就走ip语言，ip匹配不到走站点默认语言
      //   siteDefaultServiceLanguageCode = ipLanguageCode
      //   mainStore.setLanguage(
      //     config[siteDefaultServiceLanguageCode] as Language
      //   )
      // }
    }

    // 转本地站点默认语言code
    const siteDefaultLanguage: Language | undefined =
      siteDefaultServiceLanguageCode
        ? (config[siteDefaultServiceLanguageCode] as Language)
        : undefined

    // 推广语言永远优先级最高，其次本地选中语言
    let defaultLanguage
    if (isSiteLanguageCode(languageCode)) {
      defaultLanguage = siteDefaultLanguage
    } else if (languageFromStorage && language.includes(languageFromStorage)) {
      defaultLanguage = languageFromStorage
    } else {
      defaultLanguage = siteDefaultLanguage || language[0]
    }
    const docEle = document.documentElement
    docEle.setAttribute('lang', defaultLanguage)
    /**
     * $patch写法中变成此种 避免类型报错，但具体业务为什么需要如此还需要查一下
     */
    mainStore.siteConfig = {
      ...((mainStore.siteConfig as SiteConfig) || {}),
      languageCode: siteDefaultServiceLanguageCode
    }
    mainStore.$patch({
      language: defaultLanguage
    })

    GlobalManager.I18n.add([
      createLobbyMessagesWithIndexDB(GlobalManager.I18n.store),
      lobbyMessages,
      lobbyMessageWithServer
    ])
      .setOptions({
        language,
        defaultLanguage
      })
      .setLanguage(defaultLanguage)
  }

  public static async initSkinAssetsHash(useInjectData = false) {
    const { health, response: ossGetSkinAssetsHashResInject } =
      BusinessUtils.parseInjectData<'ossGetSkinAssetsHash'>(
        'ossGetSkinAssetsHash'
      )

    const skinAssetsHashResponse =
      useInjectData && health
        ? ossGetSkinAssetsHashResInject
        : await apiGetSkinAssetsHash()

    const skinAssetsHash = skinAssetsHashResponse!.data.data
    if (skinAssetsHash) {
      useMainStore().skinAssetsHash = skinAssetsHash
    }
  }

  public static async initSystemInfos(useInjectData = false) {
    const {
      updateSystemInfos,
      updateLinkType,
      updateBrowserFingerId,
      browserFingerId,
      osType
    } = useMainStore()

    const { health, response: systemStatusResInject } =
      BusinessUtils.parseInjectData<'apiGetSystemStatus'>('apiGetSystemStatus')

    const systemStatusResonse =
      useInjectData && health
        ? systemStatusResInject
        : await apiGetSystemStatus(
            { osType },
            {
              customParams: {
                useCache: true
              }
            }
          )

    const systemInfos = systemStatusResonse!.data.data?.homeGetSysInfo
    if (systemInfos) {
      updateSystemInfos(systemInfos)

      const afterGetClientSystemInfos = async () => {
        const queryParams = pick(getURLParameters(), ['id'])
        if (queryParams?.id) {
          const linkSettingResponse = await apiGetLinkSetting()
          const linkType = linkSettingResponse?.data?.data?.linkType
          if (linkType) {
            updateLinkType(linkType)
            if (linkType === LinkType.TEST_REGISTER) {
              GameNavigation.goSubGameDemoPage()
            }
          }
        }

        /**
         * 第三方SDK浏览器指纹（刷子检测）
         */
        if (systemInfos?.fingerprintJS?.publicKey && !browserFingerId) {
          // 延迟加载浏览器指纹
          setTimeout(() => {
            import('@fingerprintjs/fingerprintjs-pro').then(({ load }) => {
              const fpPromise = load({
                apiKey: systemInfos?.fingerprintJS?.publicKey
              })
              fpPromise
                .then((fp) => fp.get())
                .then((result) => {
                  updateBrowserFingerId(result?.visitorId)
                })
            })
          }, 3000)
        }
      }

      await afterGetClientSystemInfos().catch(() => {
        /**
         * 让其不阻塞流程
         */
      })
    }
  }

  public static async initChannelIpBindInfo() {
    const { updateIpBindInfo, ipBindInfos } = useMainStore()

    const queryParams = pick(getURLParameters(), [
      'cid',
      'aid',
      'id',
      'currency'
    ])
    const customChannelId = `${queryParams?.id || ''}${queryParams?.cid || ''}${
      queryParams?.aid || ''
    }`
    const { _customChannelId } = ipBindInfos || {}
    if (queryParams?.id || queryParams?.cid || queryParams?.aid) {
      /** 如果缓存有 而且一样 就不重打接口了 */
      if (_customChannelId === customChannelId) return

      const ipBindInfosResponse = await apiGetIpBindData({
        agentName:
          (queryParams?.id as string) || (queryParams?.aid as string) || '',
        channelId: Number(queryParams?.cid || 0),
        currency: (queryParams?.currency as string) || ''
      })
      const ipBindInfos = ipBindInfosResponse.data.data
      if (ipBindInfos) {
        updateIpBindInfo({
          ...ipBindInfos,
          _customChannelId: customChannelId
        })
      }
    } else {
      updateIpBindInfo(null)
    }
  }

  public static async initIpInfos() {
    return new Promise((resolve) => {
      try {
        const useMockMaintainIframe = !!getQueryString('useMockMaintainIframe')
        /**
         * 检查是否维护
         */
        GlobalEvents.dispatch({
          type: 'SITE_MAINTAIN',
          payload: {
            useMockMaintainIframe,
            ipCheck: true,
            onCallback: () => {
              resolve('')
            }
          }
        })
      } catch (error) {
        resolve('')
      }
    })
  }

  /**
   * 获取当前站点的详细信息
   */
  public static async initSiteInfos(useInjectData = false) {
    const mainStore = useMainStore()
    const { health, response: siteInfosResInject } =
      BusinessUtils.parseInjectData<'apiGetSiteInfo'>('apiGetSiteInfo')

    const resonse =
      useInjectData && health ? siteInfosResInject : await apiGetSiteInfo()
    const siteInfos = resonse!.data.data

    if (siteInfos) {
      // 如果后台设置为没有开发的版式，强制设为亚太蓝白
      if (
        !Object.values(nodeThemeConfig.ThemeSkinType).includes(siteInfos.type)
      ) {
        siteInfos.type = 1
        siteInfos.backgroundColor = 0
        siteInfos.skinTypeValue = 8
      }

      const layoutDepend = ['type']
      setStorage(pick(siteInfos, layoutDepend), 'web.lobby.lastLayout')
      mainStore.$patch({
        siteInfos: { ...siteInfos },
        skinId: `${siteInfos.type}-${siteInfos.backgroundColor}-${siteInfos.skinTypeValue}`
      })

      //初始化试玩币种
      this.initTryCurrency()
    }
  }

  /**
   * 设置节日主题
   * @param siteInfos
   */
  public static setHolidyAssets() {
    // createOssAssetsPathOrigialRetry((origin) => {
    //   return loadScripts({
    //     id: 'holiday.svg.sprite',
    //     src: assetsPath(
    //       `/lobby_asset/festival/{holiday}/{layout}-{bg}/sprite.js`,
    //       origin
    //     )
    //   })
    // }).catch(() => {
    //   /** */
    // })
    Site.loadSvgSprite(
      `/lobby_asset/festival/{holiday}/{layout}-{bg}/sprite.svg`
    )
  }

  /**
   * 站点数据获取完毕后下载资源配置等
   */
  public static async initAssetsConfig() {
    const { siteInfos } = useMainStore()
    if (siteInfos) {
      await Promise.all([Site.initTheme(siteInfos)]).catch(() => {
        /**
         * 允资源加载失败，不阻塞主流程
         */
      })
    }
  }

  /** 异步加载多语言配置和 PNG、SVG 合图 */
  public static initializeLanguageAndSprites(useInjectData = false) {
    const { siteInfos } = useMainStore()
    if (siteInfos) {
      Site.initI18n(siteInfos).catch(() => {
        /**/
      })
      Site.loadPngSprite().catch(() => {
        /**/
      })
      Site.loadSvgSprite().catch(() => {
        /**/
      })
      //加载皮肤资源哈希值映射，存在本地
      Site.initSkinAssetsHash(useInjectData).catch(() => {
        /**/
      })
    }
  }

  /** 加载png合图资源 */
  private static async loadPngSprite() {
    const mainStore = useMainStore()
    // 开启png雪碧图骨架动画
    mainStore.$patch({ loadingImgSprite: true })
    try {
      const res = await createOssAssetsPathOrigialRetry((origin) => {
        return loadJson(
          assetsPath(
            `/lobby_asset/{layout}-{bg}-{skin}/main.sprites.json`,
            origin
          )
        )
      }).catch(() => ({}))
      try {
        const base64 = get(res, 'image')
        if (base64) {
          const blobUrl = URL.createObjectURL(ImageUtils.base64ToBlob(base64))
          Object.keys(res).forEach((key) => {
            if (key !== 'image') {
              ;(res as Record<string, PngSprite>)[key].image = blobUrl
            }
          })
        }
      } catch (error) {}

      if (res) {
        mainStore.$patch({
          allPngSprite: res as Record<string, PngSprite>
        })
        cssAssetsPathReplace()
      }
    } finally {
      // 关闭png雪碧图骨架动画
      mainStore.$patch({ loadingImgSprite: false })
    }
  }

  /**
   * 获取当前站点的SMS信息
   * @returns Error | undefined，当返回 Error 时，说明接口请求失败，且本地缓存没有数据
   */
  public static async initSmsCountry() {
    const { updateCountryInfos, smsCountrys } = useMainStore()
    try {
      const resonse = await apiGetSmsCountry()
      const msCountry = resonse.data.data
      if (msCountry) {
        /** 中文首字母排序 */
        msCountry?.countryInfos?.sort((a, b) =>
          a.countryName.localeCompare(b.countryName, 'zh-Hans-CN')
        )
        updateCountryInfos(msCountry)
      }
      Site.initSmsSegment()
    } catch (error) {
      // 允许请求失败
      Site.initSmsSegment()
      if (!smsCountrys.length) return error as Error
    }
  }

  /**
   * 初始化默认手机区号，根据场景权重
   * @returns void
   */
  public static initSmsSegment(topSegment?: string) {
    const { ipCheck, defaultCountry, smsSegment, smsCountrys } = useMainStore()
    const cacheData = useMainStore().getAutoFillData()
    if (!smsCountrys.length) {
      useMainStore().updateSmsSegment()
      return
    }

    const sortCountrys = [
      /** 传入需要最高优先级显示的区号 */
      topSegment,
      /** 缓存在store里的用户上次选择的区号 */
      smsSegment,
      /** 记住密码缓存的区号 */
      cacheData.smsSegment,
      /** ip推荐区号 */
      ipCheck.recommendAreaCode,
      /** 区号列表返回的默认区号 */
      defaultCountry?.phoneNumberSegment,
      /** 兜底默认展示列表第一个区号 */
      smsCountrys[0].phoneNumberSegment
    ]
    const segment = sortCountrys.find((phoneSegment) =>
      smsCountrys.find((item) => item.phoneNumberSegment === phoneSegment)
    )

    useMainStore().updateSmsSegment(segment)
  }

  /**
   * 获取首页版本配置
   * 通过apiGetOptimizationSiteConfig方法获取时只要layoutDesign字段，
   * 其它数据都不需要，只有 layoutDesign 是需要骨架屏销毁就要确定的
   */
  public static async initHomeLayoutInfos(useInjectData = false) {
    const mainStore = useMainStore()
    let homeLayoutInfos
    /**
     * 预览模式的版式数据从其它途径拿，切需要规避缓存
     */
    if (defaultUrlQueryParams.previewonly) {
      const response = await apiGetOptimizationSiteConfig({
        previewonly: true
      }).catch(() => {
        /**
         *
         */
      })

      const optimizationSiteConfig = response?.data.data
      if (optimizationSiteConfig?.layoutDesign) {
        homeLayoutInfos = optimizationSiteConfig?.layoutDesign
      }
    } else {
      const { health, response: optimizationSiteConfig } =
        BusinessUtils.parseInjectData<'apiGetOptimizationSiteConfig'>(
          'apiGetOptimizationSiteConfig'
        )
      const optimizationSiteConfigResponse =
        useInjectData && health
          ? optimizationSiteConfig!
          : await apiGetOptimizationSiteConfig(
              {
                previewonly: false
              },
              {
                customParams: {
                  useCache: true
                }
              }
            )

      homeLayoutInfos = optimizationSiteConfigResponse!.data?.data?.layoutDesign
    }

    try {
      if (homeLayoutInfos) {
        mainStore.homeLayoutInfos = homeLayoutInfos
      }
    } catch (e) {
      // console.error(e)
    }
  }
  /**
   * 获取币种列表信息
   */
  public static async initCurrencyInfos(useInjectData = false) {
    const mainStore = useMainStore()
    const { health, response } =
      BusinessUtils.parseInjectData<'apiGetAllCurrency'>('apiGetAllCurrency')
    const currencyData =
      useInjectData && health
        ? (() => {
            return (response!.data.data || []).map((it) => {
              return Object.entries(it).reduce((pre, [key, value]) => {
                const newKey = nodeThemeConfig.dataMap.allCurrency?.[
                  key
                ] as string
                pre[newKey] = value
                return pre
              }, {} as Record<string, unknown>)
            })
          })()
        : (await apiGetAllCurrency())?.data?.data
    mainStore.$patch({
      currencyList: currencyData ?? []
    })
  }

  /**
   * 获取弹窗信息
   */
  public static async getFrameBoxInfos() {
    const mainStore = useMainStore()
    const { getMessageAll } = useHomeStore()
    const platfromid = mainStore.userInfos?.platfromid || ''
    const plyload = {
      frame: true,
      unReadListCount: true,
      marqueePage: 1,
      marqueeSize: 10,
      list: 0
    } as MessageAllRequest
    const res = await getMessageAll(plyload)
    if (!res?.frame) {
      return
    }
    // let frameBoxInfos = await apiGetMessageFrame()
    const { notice, announcement, frame } = res.frame
    let frameBoxInfos = [
      ...(notice || []),
      ...(announcement || []),
      ...(frame || [])
    ]
    // 遍历接口数据,全都设置成不记为为false的状态 (需要记住)
    frameBoxInfos.forEach((item) => (item.not_remind_again = false))
    // 由于接口走静态化而不是后台实时返回，弹窗的展示时间有可能实际已经过了有效期。所以后端增加了一个过期时间字段，前端判断下时间已过期，就不展示了
    const nowTime = new Date().getTime()
    frameBoxInfos = frameBoxInfos.filter((item) => {
      if (!item.frameEndTime) {
        return true
      }
      return Number(item.frameEndTime) * 1000 > nowTime
    })
    // 本地缓存字符串
    const frameBoxStr = localStorage.getItem('frameBoxInfos')
    // 取本地缓存的数据
    const frameBoxStorages: Array<FrameStorageType> =
      (frameBoxStr && JSON.parse(frameBoxStr)) || []
    /**
     * 后台返回数据和本地存储数据进行一个对比, 如果本地弹框设置了不再提示（not_remind_again），
     * 就改变对应数据状态，否则默认设置不再提示状态为false
     * 2022/9/9 新增根据当前登录用户信息platfromid来存储比对弹框数据
     */
    // 本地缓存为空,直接存入数据
    if (frameBoxStorages.length === 0) {
      frameBoxStorages.push({
        platfromid,
        data: frameBoxInfos.map(mapFieldLocalStorage)
      })
    }
    // 本地缓存有值,需要对原值做修改
    else {
      // 找出当前缓存中的旧值
      const currentFrameBoxInfo = frameBoxStorages.find(
        (item) => platfromid === item.platfromid
      )
      // 如果旧值存在
      if (currentFrameBoxInfo) {
        // 此处也就是4v4的二维循环,内容不多
        frameBoxInfos.forEach((x) => {
          currentFrameBoxInfo.data.forEach((y) => {
            const xFrameType = x.frameType || x.frame_type
            const yFrameType = y.frameType || y.frame_type
            if (x.id === y.id && xFrameType === yFrameType) {
              x.not_remind_again = y.not_remind_again
              x.not_remind_login = y.not_remind_login
              x.storageExpirationTime = y.storageExpirationTime
            }
          })
        })
        // 更新local存储的数据与store保持同步
        currentFrameBoxInfo.data = frameBoxInfos
      }
      // 如果旧值不存在,也是直接添加
      else {
        frameBoxStorages.push({
          platfromid,
          data: frameBoxInfos.map(mapFieldLocalStorage)
        })
      }
    }
    //如果最终计算出来需要缓存的元素,只保留最后五个,不足五个则有几个保留几个.
    const latelyFiveFrame = frameBoxStorages.slice(-5)
    // vvvvvvvvvvvvvvvvvvvvvvvvvvvvv2023年9月9号后若还看到此断遍历代码,请将之删除vvvvvvvvvvvvvvvvvvvvvvvvvvvvv
    // 2023年6月9号标记 此遍历处理代码是为了之前的缓存中存了一些未简化过的数据,所以在此处进行一下处理.
    latelyFiveFrame.forEach(
      (frame) => (frame.data = frame.data.map(mapFieldLocalStorage))
    )
    // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^2023年9月9号后若还看到此断遍历代码,请将之删除^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
    /** 前端自己维护一个今日已读的状态，记录在缓存里，每日重置 */
    try {
      localStorage.setItem('frameBoxInfos', JSON.stringify(latelyFiveFrame))
      const readState = localStorage.getItem('frameBoxReadState')
      if (readState) {
        const reads = JSON.parse(readState) as { date: string; ids: number[] }
        //缓存里记录的已读消息的id 跟接口返回的弹窗列表匹配，标记为已读状态
        const { date, ids } = reads
        //判断记录日期是不是今天，已读状态每天重置
        if (moment(date).isSame(moment(), 'day')) {
          ids.forEach((id) => {
            const frame = frameBoxInfos.find((item) => {
              return item.id == id
            })
            frame && (frame.read = true)
          })
        }
      }
    } catch (e) {
      // console.error(e)
    }
    mainStore.$patch({
      frameBoxInfos
    })
  }

  private static urlcheck(urls: SiteURLInfos): void {
    if (
      [
        urls.api_domain?.length,
        urls.web_domain?.length,
        urls.oss_domain?.length
      ].includes(0)
    ) {
      GlobalEvents.dispatch({
        type: 'TRACE_REPORT',
        payload: {
          event: 'DoaminEmpty',
          api: urls.api_domain?.length,
          web: urls.web_domain?.length,
          oss: urls.oss_domain?.length
        }
      })
    }
  }

  /**
   * 获取当前站点的相关URL信息
   */
  public static async initSiteURLInfos(useInjectData = false) {
    try {
      const mainStore = useMainStore()
      const { health, response } =
        BusinessUtils.parseInjectData<'ossGetSiteUrlConfig'>(
          'ossGetSiteUrlConfig'
        )

      let siteURLInfos: SiteURLInfos | null = null

      if (useInjectData && health) {
        try {
          siteURLInfos = JSON.parse(
            GlobalCrypto.decrypt(
              response?.data.data || '',
              'thanks,pig4cloud',
              {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
              }
            )
          )
        } catch (error) {
          siteURLInfos = await apiGetSiteURLInfos()
        }
      } else {
        siteURLInfos = await apiGetSiteURLInfos()
      }
      if (siteURLInfos) {
        Site.urlcheck(siteURLInfos)
        mainStore.$patch({
          siteURLInfos,
          urltime: Date.now()
        })
        // 注册线路切换管理器
        PingManagement.initLines(GlobalService.apiDomain)
        // 延迟启动线路检测任务 开启时需要确保域名测速能证明api线路是通畅的，比如 oss 和 api 集群中 speed.png 和 op.json 都能通 所以不适合
        setTimeout(PingManagement.runCheck, 2000)
      }
    } catch (error) {}
  }

  /**
   * 若登录了直接进行操作，否则
   * 引导用户进行登录，登录成功后执行操作
   * @param next
   */
  public static async guide2Login(next: () => void) {
    await new Promise((resolve, reject) => {
      const { hasLogined, isInitializationCompleted } = useMainStore()
      if (hasLogined) {
        resolve(null)
      } else {
        if (isInitializationCompleted) {
          Modal.openLoginRegisterModal({
            onLoginSuccess() {
              resolve(null)
            }
          })
        } else {
          reject()
        }
      }
    })
    next()
  }
  /*
   * 检测 是否为 demo 体验账号，是的话
   * @returns
   */
  public static guide2DemoAcountDialog() {
    const { isDemoMode } = useMainStore()
    const { t } = useI18n()
    if (isDemoMode) {
      GlobalManager.Modal.message({
        type: 'warning',
        content: t('lobby.error.demoAccountTips')
      })
    }
    return isDemoMode
  }

  /**
   * 若登录了直接进行操作，否则
   * 引导用户进行注册，注册成功后执行操作
   * @param next
   */
  public static async guide2Register(next: () => void) {
    await new Promise((resolve, reject) => {
      const { hasLogined, isInitializationCompleted } = useMainStore()
      if (hasLogined) {
        resolve(null)
      } else {
        if (isInitializationCompleted) {
          Modal.openLoginRegisterModal({
            accountRegister: true,
            onLoginSuccess() {
              resolve(null)
            }
          })
        } else {
          reject()
        }
      }
    })
    next()
  }

  /**初始化试玩币种 */
  public static initTryCurrency() {
    const { updataCurrentyTryCache, currentTryCurrency, siteInfos } =
      useMainStore()
    if (!siteInfos) return
    const tryCurrency = siteInfos?.currencyInfos || []
    //先拿url的参数
    const queryParams = pick(getURLParameters(), ['currency'])
    if (queryParams?.currency) {
      //判断站点币种是否还包含这个币种，有可能是过期数据
      const config = tryCurrency?.find(
        (item) => item.currencyCode == queryParams?.currency
      )
      return updataCurrentyTryCache(config || tryCurrency[0])
    }
    //再拿本地缓存
    const currency = currentTryCurrency
    if (currency) {
      try {
        //判断站点币种是否还包含这个币种，有可能是过期数据
        const config = tryCurrency?.find(
          (item) => item.currencyCode == currency?.currencyCode
        )
        return updataCurrentyTryCache(config || tryCurrency[0])
      } catch (e) {
        updataCurrentyTryCache(tryCurrency[0])
      }
      return
    }
    return updataCurrentyTryCache(tryCurrency[0])
  }

  /**
   * 大额触发强制绑定
   * */
  public static triggerForcedBinding() {
    try {
      const {
        hasLogined,
        userInfos,
        updateUserInfos,
        largeAmountRange,
        largeAmountMustBind
      } = useMainStore()
      //1 必须登录
      if (!hasLogined || !userInfos?.game_gold) {
        return
      }
      //2 检测后台开关
      if (!largeAmountMustBind || !largeAmountRange) {
        return
      }
      //3 币种符合&余额达标
      const currency = largeAmountRange[userInfos?.currency]
      if (currency && currency <= userInfos?.game_gold) {
        //触发强制绑定
        const user = {
          must_bind_item: 1,
          must_bind_phone: 0,
          must_bind_email: 0,
          must_bind_google_auth: 0
        }
        const { email, phone, googleAuth } = largeAmountMustBind
        if (!!phone) {
          user.must_bind_phone = BindType.REQUIRED
        }
        if (!!email) {
          user.must_bind_email = BindType.REQUIRED
        }
        if (!!googleAuth) {
          user.must_bind_google_auth = BindType.REQUIRED
        }
        updateUserInfos(merge({}, userInfos, user))
        useMainStore().$patch({ bindModalIsLargeBalances: true })
        useMainStore().modalManager({ queueName: ['showBind'] })
      }
    } catch (e) {
      // console.error(e)
    }
  }
}
