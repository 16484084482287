import { DateFormatMap } from '@/context/common/date'
import { FestivalPortalName } from '@/views/global-festival/config'
import { MomentLanguageMap } from '@/i18n/config'
import { ValueOf } from 'type-fest'
import { range } from 'lodash'
import { parse as urlParse } from 'url'
import qs from 'qs'
import styleVar from '@/styles/_variables.module.scss'
import themeConfig from '@/../public/node/html-hot-replace/theme.config.js'
import type { Language } from '@/i18n'

export const nodeThemeConfig = themeConfig

export const serviceLanCode = [
  'en',
  'zh',
  'zh_hk',
  'th',
  'vi',
  'ko',
  'ja',
  'pt',
  'es',
  'de',
  'fr',
  'it',
  'ru',
  'my',
  'id',
  'hi',
  'tl',
  'km',
  'te',
  'ta',
  'mr',
  'kn',
  'tr',
  'bn',
  'pa'
] as const

export type SkinType = string
export const sportsServiceLanCode = [
  'en',
  'cs',
  'ch',
  'ko',
  'jp',
  'th',
  'vn',
  'id'
] as const

export type AgentModeType = ValueOf<typeof Const.AgentMode>

export const durl = window.location.href

const { query: defaultQuery = '' } = urlParse(window.location.href, true)
export const defaultUrlQueryParams = qs.parse(defaultQuery as string) as Record<
  string,
  unknown
>
window.wgMock = window.wgMock || {}
window.wgMock.cardLite = defaultUrlQueryParams['mock.cardLite'] === 'true'
window.wgMock.keepAlive = defaultUrlQueryParams['mock.keepAlive'] === 'true'
window.wgMock.noLazy = defaultUrlQueryParams['mock.noLazy'] === 'true'
window.wgMock.blurAnimation =
  defaultUrlQueryParams['mock.blurAnimation'] === 'true'
window.wgMock.openPC = defaultUrlQueryParams['mock.openPC'] === 'true'
window.wgMock.animationMode = defaultUrlQueryParams[
  'mock.animationMode'
] as typeof window.wgMock.animationMode

const Const = {
  ServiceCode: {
    SUCCESSCODE: 0,
    SUCCESS: 1,
    UPAY_SUCCESS_CODE: 'C2',
    TOKEN_EXPIRED: -1,
    SERVER_MAINTAIN: -1,
    RESTRICTED_ACCESS: 9110,
    /** 强制验证 */
    FORCE_GEETEST: 1119,
    /** 手机验证码错误 */
    PHONE_CODE_ERROR: 1101,
    /** 需强制使用行为验证 */
    LOGIN_ERROR: 1011,
    /** 二步验证 */
    TOW_VERYFI: 1012,
    /** @邮箱 安全配置已更新，客户端需要刷新 */
    EMAIL_SECURITY_CONFIG_EXPIRED: 1044,
    /** @手机 安全配置已更新，客户端需要刷新 */
    PHONE_SECURITY_CONFIG_EXPIRED: 1248,
    /**滑动拼图验证 */
    SWIPER_PUZZLE_GEETEST: 1124,
    /**图标点选行为验证 */
    GRAPHIC_CLICK_GEETEST: 1125,
    /**九宫格行为验证 */
    NINE_SQUARE_GEETEST: 1126,
    /**登入短信行为验证 */
    SMS_CODE_GEETEST: 1127,
    /**手机号输入错误*/
    PHONE_ERROR: 1216,
    /**账号已冻结，请通过忘记密码解冻 */
    ACCOUNT_FREEZE: 1025,
    /**注册或登入参数缺失，请重新填写  */
    CONFIG_CHANGE_ERR: 1128,
    /**安全中心配置变更  */
    CONFIG_CHANGE_ERR_1: 1247,
    /**进入游戏前需要验证手机号码  */
    SMS_CODE_GAME: 3002,
    /**进入游戏前需要绑定提现方式  */
    BIND_WITHDRAW: 3003,
    /**
     * 试玩账号打开游戏错误展示注册弹框
     */
    TEST_REGISTER_GAME: 3004,
    /** 进入游戏判断首充 */
    FIRST_RECHARGE: 3006,
    /** 进入游戏判断下载app */
    DOWNLOAD_APP: 3007,
    /**弹出渠道下载APP通过调用后端的登录游戏接口 */
    DOWNLOAD_APP_CHANNEL_BY_CALL_GAMEAPI: 3010,
    /** 页面过期 刷新重试 */
    PAGE_OVERDUE: 4020,
    /**
     * 中心钱包暂无可拉回余额提示
     */
    CENTER_WALLET_NOT_BALANCE: 9527,
    /**
     * 试玩游戏没开线路，跟上面3004一样处理，弹出注册页
     */
    DEMO_GAME_LINE_DISABLED: 210103,
    /**
     * 游戏不支持试玩 不知道跟上面3004有啥区别 后端都说不清
     */
    DEMO_GAME_DISABLED: 41020,
    /**
     * 站点冻结错误码
     */
    SITE_FREEZE_ERROR: 9111,
    /**
     * 站点维护
     */
    SITE_MAINTAIN_ERROR: -3,
    /**
     * 站点冻结且维护
     */
    SITE_FREEZE_AND_MAINTAIN_ERROR: -4,
    /**
     * 用户名已存在
     */
    NAME_EXISTED_ERROR: 1013,
    /** 邮箱验证码已失效，请重新验证 */
    EMAIL_CAPTCHA_EXPIRED: 1332,
    /** 邮箱验证码不能为空 */
    EMAIL_CAPTCHA_EMPTY: 1326,
    /** 短信验证码已失效，请重新验证 */
    PHONE_CAPTCHA_EXPIRED: 1333,
    /** 短信验证码不能为空 */
    PHONE_CAPTCHA_EMPTY: 1325,
    //版本过低，需引导用户刷新进行更新
    VERSION_ERROR: 999,
    /**
     * 其他端登录了
     */
    OTHER_CLIENT_LOGIN: 1401
  },
  SportsServiceCode: {
    SUCCESS: 1,
    TOKEN_EXPIRED: -1,
    SERVER_MAINTAIN: -1,
    RESTRICTED_ACCESS: 9110,
    FORCE_GEETEST: 1119,
    /** 手机验证码错误 */
    PHONE_CODE_ERROR: 1101,
    /** 需强制使用行为验证 */
    LOGIN_ERROR: 1011,
    /**滑动拼图验证 */
    SWIPER_PUZZLE_GEETEST: 1124,
    /**图标点选行为验证 */
    GRAPHIC_CLICK_GEETEST: 1125,
    /**九宫格行为验证 */
    NINE_SQUARE_GEETEST: 1126,
    /**登入短信行为验证 */
    SMS_CODE_GEETEST: 1127,
    /**账号已冻结，请通过忘记密码解冻 */
    ACCOUNT_FREEZE: 1025,
    /**注册或登入参数缺失，请重新填写  */
    CONFIG_CHANGE_ERR: 1128,
    /**进入游戏前需要验证手机号码  */
    SMS_CODE_GAME: 3002,
    /**进入游戏前需要绑定提现方式  */
    BIND_WITHDRAW: 3003,
    /**
     * 试玩账号打开游戏错误展示注册弹框
     */
    TEST_REGISTER_GAME: 3004,
    /**
     * 中心钱包暂无可拉回余额提示
     */
    CENTER_WALLET_NOT_BALANCE: 9527
  },
  /**
   * 站点代理模式
   */
  AgentMode: {
    /**
     * 无限极差
     */
    Infinite: 0,
    /**
     * 一级代理
     */
    FirstLevel: 1,
    /**
     * 净盈利
     */
    NetProfit: 2,
    /**
     * 三级净盈利
     */
    ThreeNetProfix: 10002,
    /**
     * 全民代理
     */
    National: 10003
  } as const,
  MomentLanguageMap,
  /**
   * 项目前端国际化语言code映射go服务端语言code
   */
  ServiceLanguageMap: {
    en_US: 'en',
    zh_CN: 'zh',
    zh_TW: 'zh_hk',
    th_TH: 'th',
    vi_VN: 'vi',
    ko_KR: 'ko',
    ja_JP: 'ja',
    pt_PT: 'pt',
    es_ES: 'es',
    de_DE: 'de',
    fr_FR: 'fr',
    it_IT: 'it',
    ru_RU: 'ru',
    my_MM: 'my',
    id_ID: 'id',
    hi_IN: 'hi',
    tl_PH: 'tl',
    km_KH: 'km',
    te_IN: 'te',
    ta_LK: 'ta',
    mr_IN: 'mr',
    kn_IN: 'kn',
    tr_TR: 'tr',
    bn_BN: 'bn',
    pa_PA: 'pa'
  } as Record<Language, typeof serviceLanCode[number]>,

  /**
   * 浏览器国际化语言code映射go服务端语言code
   */
  BrowserLanguageMap: {
    en: 'en',
    zh: 'zh',
    'zh-CN': 'zh',
    'zh-SG': 'zh',
    'zh-TW': 'zh_hk',
    'zh-HK': 'zh_hk',
    'zh-MO': 'zh_hk',
    th: 'th',
    vi: 'vi',
    ko: 'ko',
    ja: 'ja',
    pt: 'pt',
    'pt-PT': 'pt',
    'pt-BR': 'pt',
    es: 'es',
    de: 'de',
    fr: 'fr',
    'fr-FR': 'fr',
    it: 'it',
    'ru-RU': 'ru',
    'my-MM': 'my',
    'id-ID': 'id',
    hi: 'hi',
    'bn-IN': 'hi',
    fil: 'tl',
    'fil-PH': 'tl',
    km: 'km',
    te: 'te',
    ta: 'ta',
    mr: 'mr',
    kn: 'kn'
  },
  /**
   * 项目前端国际化语言code映射三方体育code
   */
  SportsServiceLanguageMap: {
    en_US: 'en',
    zh_CN: 'cs',
    zh_TW: 'ch',
    ko_KR: 'ko',
    ja_JP: 'jp',
    th_TH: 'th',
    vi_VN: 'vn',
    id_ID: 'id'
  } as Record<Language, typeof sportsServiceLanCode[number]>,
  /**
   * 项目前端日期翻译格式
   */
  DateFormatMap,
  /**
   * 项目前端国际化语言code映射极验语言code
   */
  GeetestLanguageMap: (() => {
    const geetestLanCode = [
      'eng',
      'zho',
      'zho-tw',
      'tha',
      'vie',
      'kor',
      'jpn',
      'por',
      'spa',
      'deu',
      'fra',
      'isl',
      'rus',
      'my',
      'ind',
      'him',
      'fil',
      'mya',
      'khm',
      'tel',
      'tam',
      'mar',
      'kan',
      'mya',
      'none'
    ] as const
    return {
      en_US: 'eng',
      zh_CN: 'zho',
      zh_TW: 'zho-tw',
      th_TH: 'tha',
      vi_VN: 'vie',
      ko_KR: 'kor',
      ja_JP: 'jpn',
      pt_PT: 'por',
      es_ES: 'spa',
      de_DE: 'deu',
      fr_FR: 'fra',
      it_IT: 'isl',
      ru_RU: 'rus',
      my_MM: 'mya',
      id_ID: 'ind',
      hi_IN: 'him',
      tl_PH: 'fil',
      km_KH: 'khm',
      te_IN: 'tel',
      ta_LK: 'tam',
      mr_IN: 'mar',
      kn_IN: 'kan'
    } as Record<Language, typeof geetestLanCode[number]>
  })(),
  CssClassName: {
    Swiper: {
      NoSwiping: 'swiper-no-swiping',
      Pagination: 'swiper-pagination',
      Button: {
        Prev: 'swiper-button-prev',
        Next: 'swiper-button-next'
      }
    },
    Animate: {
      /**
       * Ant-d 内置Vue 可用的transionName
       */
      TransitionName: {
        Zoom: 'zoom',
        Fade: 'fade',
        //从下入，从下出
        MoveDown: 'move-down',
        //从上入，从上出
        MoveUp: 'move-up'
      },
      Animated: 'animate__animated',
      NormalActive: 'animate__normal_active',
      Speed: {
        Fast: 'animate__fast',
        Faster: 'animate__faster',
        Slow: 'animate__slow',
        Slower: 'animate__slower'
      },
      WithoutNormalFlow: 'animate__without_normal_flow',
      Repeat: {
        infinite: 'animate__infinite'
      },
      FillMode: {
        forwards: 'animate__forwards'
      },
      Delay: range(50, 2001, 50).reduce(
        (pre: Record<number, `animate__delay-${string}ms`>, item) => {
          pre[item] = `animate__delay-${item}ms`
          return pre
        },
        {}
      ),
      Spin: 'animate__spin',
      SpinReverse: 'animate__spinReverse',
      ZoomIn: 'animate__zoomIn',
      ZoomOut: 'animate__zoomOut',
      VanishIn: 'animate__vanishIn',
      BounceIn: 'animate__bounceIn',
      FlipInY: 'animate__flipInY',
      Streamer: 'animate__streamer',
      SlideInLeft: 'animate__slideInLeft',
      SlideInRight: 'animate__slideInRight',
      SlideInBottom: 'animate__slideInBottom',
      SlideOutLeft: 'animate__slideOutLeft',
      SlideOutRight: 'animate__slideOutRight',
      SlideOutBottom: 'animate__slideOutBottom',
      PushEnter: 'animate__pushEnter',
      PushLeave: 'animate__pushLeave',
      PopEnter: 'animate__popEnter',
      PopLeave: 'animate__popLeave',
      FadeIn: 'animate__fadeIn',
      FadeOut: 'animate__fadeOut',
      FadeInLeft: 'animate__fadeInLeft',
      FadeInRight: 'animate__fadeInRight',
      FadeOutLeft: 'animate__fadeOutLeft',
      FadeOutRight: 'animate__fadeOutRight',
      HeartBeat: 'animate__heartBeat',
      ScrollHalf: {
        Up: 'animate__scrollUpHalf',
        Right: 'animate__scrollRightHalf',
        Down: 'animate__scrollDownHalf',
        Left: 'animate__scrollLeftHalf'
      },

      Hover: {
        Grow: 'animate__grow__hover',
        Float: 'animate__float__hover',
        Forward: 'animate__forward__hover',
        Backward: 'animate__backward__hover'
      },
      BeatUpAndDown: 'animate__beatUpAndDown'
    },
    Lobby: {
      FixedNoDesktop: 'wg-fixed-no-desktop',
      Ellipsis: styleVar.ellipsis,
      Color: {
        Primary: styleVar.primary,
        Red: styleVar.red,
        Green: styleVar.green,
        Yellow: styleVar.yellow,
        Text: styleVar.text,
        TextDarken: styleVar.textDarken,
        TextLighten: styleVar.textLighten
      },
      Antd: {
        SelectSearch: 'ant-select-dropdown-search'
      },
      /** TODO66 标记日期2月6号.在新版本发布后,确认没有地方引入时,可删除它.
       * @deprecated 已删除,需要暴露的类名,不使用变量,直接使用字符串就行
       *  theme-will-cut 使用 will-cut 代替
       *  theme-mask-hover 使用 mask-hover 代替
       */
      Card: {
        WillCut: 'theme-will-cut',
        MaskHover: 'theme-mask-hover'
      },
      /** 卡片列表使用不换行模式的全局类名，用来处理滚动 */
      CardScrollWrapList: 'game-card-scroll-wrap-list'
    }
  },
  /**
   * 这里有变动的情况下需同步修改
   * public\node\html-hot-replace\theme-config-parse.js
   */
  // ThemeName: nodeThemeConfig.ThemeName,
  ThemeSkinLayout: {
    0: 'normal' as const,
    1: 'normal' as const,
    2: 'european_american' as const,
    3: 'cyan_blue' as const,
    4: 'stake' as const,
    5: 'crown_gold' as const,
    6: 'galaxy_gold' as const,
    7: 'european_american' as const
  },
  /**
   * 全局插槽管理统一管理，避免业务内部同名覆盖
   */
  PortalName: {
    /**
     * 公共 subtitle
     */
    MySubTitle: 'my-sub-title',
    /**
     * 公共title右边的按钮插槽（左）
     */
    MyTitleRightSlotsLeft: 'my-title-right-slots-left',
    /**
     * 公共title右边的按钮插槽（右）
     */
    MyTitleRightSlotsRight: 'my-title-right-slots-right',
    /**
     * 公共title右边的按钮插槽（右）
     */
    MyTitleH5Slots: 'my-title-h5-slots',
    /**
     * 忘记密码页面底部下一步按钮
     */
    ForgotPwdBtns: 'forgot-pwd/btns',
    /**
     * 安全中心 无验证方式时 标题右边的 联系客服按钮
     */
    SecurityCenterService: 'center/security/service-btns',
    /**
     * 路由上方区域
     */
    RouterViewTop: 'router-view-top',
    /**
     * 青蓝版下载App展示区域
     */
    DownloadAppCyanBlue: 'download-app-cyan-blue',
    /**
     * App全局
     */
    GlobalApp: 'app',
    /**
     * 活动页底部
     */
    ActivityBottom: '/event/item/activity-bottom',
    /**
     * 任务弹窗一键领取插槽
     */
    TaksOneClick: 'taks-one-click',
    /**
     * 游戏列表页面和游戏页面顶部面包屑右边插槽
     */
    GameListTopRight: '/game/home/top-right',
    /**
     * 音乐播放器
     */
    WebMusic: 'music',
    MobileMusic: 'MobileMusic',
    /** 充值后弹窗底部按钮 */
    AfterRechargeActivityBottom: 'after-recharge-activity-bottom',
    FestivalPortalName
  },
  CacheNameSpace: {
    FinancePayTooltipVisible: (id: string | number) => `@@tooltipVisible-${id}`,
    FinancePayOnlinePlatformExpand: (id: string | number) =>
      `@@${id}-finance-online-platform-expand`,
    FinancePayTransferPlatformExpand: (id: string | number) =>
      `@@${id}-finance-transfer-platform-expand`,
    FinancePayOnlineChannelExpand: (id: string | number) =>
      `@@${id}-finance-online-channel-expand`,
    FinancePayTransferChannelExpand: (id: string | number) =>
      `@@${id}-finance-transfer-channel-expand`
  },
  /** @极验的自定义接口域名 替换默认域名 */
  GeetestApiServers: [
    'gcaptcha4-hrc.gsensebot.com',
    'gcaptcha4-hrc.geetest.com'
  ],
  EmailSuffix: {
    zh: [
      '@gmail.com',
      '@qq.com',
      '@163.com',
      '@126.com',
      '@sina.com',
      '@foxmail.com',
      '@outlook.com',
      '@live.com',
      '@sohu.com',
      '@mail.com',
      '@hotmail.com',
      '@tom.com',
      '@yahoo.com',
      '@msn.com'
    ],
    en: [
      '@gmail.com',
      '@outlook.com',
      '@live.com',
      '@hotmail.com',
      '@yahoo.com',
      '@qq.com',
      '@foxmail.com',
      '@163.com',
      '@126.com',
      '@sina.com',
      '@sohu.com',
      '@mail.com',
      '@tom.com',
      '@msn.com'
    ]
  }
}

export default Const
