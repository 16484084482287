import { useMainStore } from '@/store/index'

/**
 *  我方全站点监测埋点
 */
export type MonitorEventType =
  /** 统计IP访问首页的数量 对单次连线去重*/
  | 'IndicatorA'
  /** 统计登录用户的数量 对单次连线去重*/
  | 'IndicatorB'
  /** 统计用户点击进入游戏的数量 */
  | 'IndicatorC'
  /** 统计用户点击充值按钮的数量 */
  | 'IndicatorD'
  /** 统计点击领取奖励按钮且未进行充值操作的用户数量 */
  | 'IndicatorE'
  /** 统计所有按钮点击的排行榜 */
  | 'IndicatorF'
  /** 统计所有弹框 */
  | 'IndicatorG'
  /** 统计错误次数 */
  | 'Error'
  /** 统计响应时间 */
  | 'ResponseSlow'
  /** 性能指标统一搜集到同一个事件 Performance */
  /** (First Contentful Paint):FCP 为首次内容绘制。
   * 这个指标代表了从页面开始加载到任何文本、图像、非白色画布或 SVG 首次渲染的时间。
   * 它是一个重要的性能指标，用来衡量一个网页的加载速度。 */
  // | 'FCP'
  /** (First Input Delay): FID 是首次输入延迟的缩写，这是一项交互响应度指标，
   * 度量的是用户首次与页面互动（例如点击链接、按下按钮）到浏览器实际能够开始处理该事件的时间。 */
  // | 'FID'
  /** (Largest Contentful Paint): LCP 是最大内容绘制的缩写，它是一个表示页面加载速度的指标，
   * 衡量的是主要内容（如主要图像或文本块）首次出现在屏幕上的时间。 */
  // | 'LCP'
  /** (Time to First Byte): TTFB 是首字节时间的缩写，
   * 衡量的是从浏览器向服务器发送请求到从服务器接收第一个字节的时间 */
  // | 'TTFB'
  | 'Performance'
  /** 统计响应超时 */
  | 'ResponseTimeout'
  /** 统计响应出错 */
  | 'ResponseSystemError'

const ENV_VARS = [
  {
    env: 'dev',
    gtagId: 'G-KTFHW2L0KF'
  },
  {
    env: 'test',
    gtagId: 'G-KTFHW2L0KF'
  },
  {
    env: 'stest',
    gtagId: 'G-KTFHW2L0KF'
  },
  {
    env: 'try',
    gtagId: 'G-82C1TZ15S0'
  },
  {
    env: 'prod'
  }
]

export default class MonitoringAnalysis {
  public gtagId = ''
  constructor() {
    this.init()
  }

  public init(): void {
    const siteCode = useMainStore()?.siteConfig?.siteCode || ''
    /** 抓出当前环境 */
    const currentSiteInfo = ENV_VARS.find((el) => {
      return el.env === process.env.ENVIRONMENT
    })
    /** 生产以外直接往下走 */
    if (currentSiteInfo?.env != 'prod') {
      this.gtagId = currentSiteInfo?.gtagId ?? ''
    } else {
      /** 生产数据太多 目前先限制部分置点上报数据
       * 找出对应分组的gtagid
       */
      const curr = siteDataMap[siteCode] || ''
      this.gtagId = curr
    }
    /** 没有匹配到的id不做加载js */
    if (!this.gtagId) {
      return
    }
    this.loadJs()
    window.dataLayer = window.dataLayer || []
    this.gtag('js', new Date())
    this.gtag('config', this.gtagId)
  }

  private async loadJs() {
    const script = document.createElement('script')
    script.id = 'script-gtag'
    script.async = true
    script.src = `https://www.googletagmanager.com/gtag/js?id=${this.gtagId}`
    document.head.appendChild(script)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public gtag(..._args: any): void {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer?.push(arguments)
  }
}
const siteDataMap: { [siteId: string]: string } = {
  '135': 'G-V7FM60QW9W',
  '500': 'G-0YYJLE6XPE',
  '10003': 'G-6JQD1QB011',
  '169': 'G-JSR5533YFQ',
  '171': 'G-X2FKGVPRD9',
  '170': 'G-CT3SFH60NS',
  '150': 'G-1T8GV680MN',
  '265': 'G-E7N20Z7EYP',
  '510': 'G-9X4JKPC34G',
  '297': 'G-7XNV28TPNC',
  '128': 'G-TTWPHS8VP0',
  '213': 'G-J4DMMP7SRP',
  '230': 'G-52LKM3GRML',
  '296': 'G-WF77EN3520',
  '5033': 'G-3CXXNR96C7',
  '5065': 'G-Y6CCRNK9TP',
  '5119': 'G-MMGN9GSY0T',
  '272': 'G-LH5RNVCT2F',
  '663': 'G-6CBZPPEGX8',
  '5012': 'G-8ND5HMWFV1',
  '5158': 'G-8849MYREXL',
  '5251': 'G-6ZY45X634W',
  '5252': 'G-41BCNHNG73',
  '5389': 'G-6MNGSYS95N',
  '5390': 'G-W0DFKRDDQ1'
}
