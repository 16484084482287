import { Bind, Throttle } from '@/utils/Decorator'
import { defaultBaseUrl } from '@/config/site.config'
import { fetchUrl } from '@/utils/Tool'
import { timeoutValue, usePingStore } from '@/components/ping/store'

class PingManagement {
  /** 最多检测的线路数量 */
  private readonly maxLines = 20
  /** 当前域名线路 */
  private currentSiteUrl?: string

  /** 初始化所有线路，放入store */
  public initLines(lineUrls: string[] = []) {
    const pingStore = usePingStore()
    this.currentSiteUrl = defaultBaseUrl

    // 系统配置的url，最多20个
    const urls = lineUrls.slice(0, this.maxLines - 1) ?? []

    // 初始化所有线路
    pingStore.lines = urls
      .filter((v, i, me) => me.indexOf(v) === i)
      .map((url, index) => ({
        url,
        pending: false,
        ping: 0,
        lineNumber: index + 1
      }))

    // #141343 移除自动切换最优域名，即保持当前域名，初始默认20
    const def = pingStore.lines[0]
    def.ping = 20
    pingStore.updateCurrentLine(def)
  }

  @Bind()
  @Throttle(15000)
  public async runCheck() {
    await this.checkAllLine()
    this.setBestLine()
  }
  /**
   * 更新最优线路
   */
  private setBestLine() {
    const { updateCurrentLine, lines } = usePingStore()
    updateCurrentLine(lines[0], true)
  }

  /**
   * 默认除以10，且最低取值1ms
   * 延迟为300ms则显示绿色30ms，若延迟9ms则显示1ms
   */
  private reduceSpeedValue(speed = 1) {
    const newSpeed = Math.ceil(speed / 10)
    return newSpeed < 1 ? 1 : newSpeed
  }

  /**
   * URL测速
   * @param url{string}
   * @param mode{png==>测站点到cdn的速率；json==>测站点到源站的速率}
   * @returns ping{number}
   */
  private async speedTest(url: string, mode: 'png' | 'json' = 'png') {
    let startTime = Date.now()
    const testPath = {
      png: '/piaspeed.png',
      json: '/op.json?' + startTime
    }[mode]
    let ping = timeoutValue
    const res = await fetchUrl(url + testPath, timeoutValue).catch(async () => {
      startTime = Date.now()
    })
    if (res?.status === 200) {
      ping = this.reduceSpeedValue(Date.now() - startTime)
    }
    return ping
  }

  /**
   * 检测所有线路ping值并更新到store
   */
  private checkAllLine() {
    return new Promise((resolve) => {
      const { lines, updateLineState, updateLine } = usePingStore()
      let speedTotal = 0
      for (const line of lines) {
        updateLineState(line.url, true)
        this.speedTest(line.url).then((ping: number) => {
          speedTotal += 1
          updateLine({ ...line, ping, pending: false })
          if (speedTotal >= lines.length) resolve(null)
        })
      }
    })
  }
}

export default new PingManagement()
